// Utility function to round a number to 2 decimal places
export const round2 = (num) => {
	return Math.round(num * 100 + Number.EPSILON) / 100;
};

// Function to calculate total price of cart items
export const getSubtotal = (cartItems) => {
	return round2(cartItems.reduce((a, c) => {
		return a + c.price * c.quantity;
	}, 0));
};

// Function to calculate total quantity of items in the cart
export const getTotalItems = (cartItems) => {
	return round2(cartItems.reduce((a, c) => {
		return a + c.quantity;
	}, 0));
};

// Function to calculate the discount amount based on the discount percentage
export const getDiscountAmount = (cartItems, discountPercent) => {
	return round2((getSubtotal(cartItems) * discountPercent) / 100);
};

// Function to calculate the total price after discount
export const getDiscountedTotal = (cartItems, discountPercent) => {
	return round2(getSubtotal(cartItems) - getDiscountAmount(cartItems, discountPercent));
};

export const getTaxAmount = (cartItems, taxPercent) => {
	return round2((getSubtotal(cartItems) * taxPercent) / 100);
};

export const getPaymentMethodCharge = (cartItems, chargePercent, discountPercent = 0) => {
	return round2((getDiscountedTotal(cartItems, discountPercent )*chargePercent) / 100);
};

export const getTotal = (cartItems, chargePercent=0, discountPercent=0) => {
	return (getDiscountedTotal(cartItems, discountPercent) + getPaymentMethodCharge(cartItems,chargePercent,discountPercent));
};


