import React, { useEffect, useState } from 'react';
import FormCreationModal from '../../../components/FormCreationModal/FormCreationModal';
import _wod from '../../../services/wod.service';

export default function AssignWod({ show, onHide, onSubmit, initialState, onSuccess, event = {} }) {
	const [wodFormTemplate, setWodFormTemplate] = useState({
		wods: {
			type: 'typeahead',
			labelKey: 'name',
			label: 'WODs',
			options: []
		}
	});

	const fetch = async () => {
		try {
			const wods = await _wod.getAllWods();
			const templateCopy = { ...wodFormTemplate };
			templateCopy.wods.options = wods;
			setWodFormTemplate(templateCopy);
		} catch (ex) {
			console.error(ex);
		}
	};
	useEffect(() => {
		fetch();
	}, []);

	return <FormCreationModal onSuccess={onSuccess} formTemplate={wodFormTemplate} onSubmit={onSubmit} show={show}
		onHide={onHide} initialState={initialState} title={`${event?.subject} -  Asignar WOD`} />;
}
