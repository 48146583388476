import restClient from '../restClient';
import authHeader from './auth-header';
import { handleErrors } from './common.service';

const FAQ_API_URL = '/faq';

class FAQService {
	async getAllQuestions() {
		try {
			const response = await restClient.get(FAQ_API_URL, {
				headers: authHeader()
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async createQuestion(questionData) {
		try {
			const response = await restClient.post(FAQ_API_URL, questionData, {
				headers: authHeader()
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async deleteQuestion(questionId) {
		try {
			const response = await restClient.delete(`${FAQ_API_URL}/${questionId}`, {
				headers: authHeader()
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}
	async updateQuestion(questionId, questionData) {
		try {
			const response = await restClient.put(
				`${FAQ_API_URL}/${questionId}`,
				questionData,
				{
					headers: authHeader()
				}
			);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}
}

export default new FAQService();
