import React, { useContext } from 'react';
import { createGlobalStyle } from 'styled-components';
import { ColorContext } from './ColorContext';

const GlobalStyles = createGlobalStyle`
  :root {
    --primaryColor: ${(props) => props.colors && props.colors.primaryColor};
    --secondaryColor: ${(props) => props.colors && props.colors.secondaryColor};
    --backgroundColor: ${(props) =>
		props.colors && props.colors.backgroundColor};
    --utilsColor: ${(props) => props.colors && props.colors.utilsColor};
    --navFontColor: ${(props) => props.colors && props.colors.navFontColor};
    --shadowNavColor: ${(props) => props.colors && props.colors.shadowNavColor};
    --iconsColor: ${(props) => props.colors && props.colors.iconsColor};
    --titlesColor: ${(props) => props.colors && props.colors.titlesColor};
    --btnFontColor: ${(props) => props.colors && props.colors.btnFontColor};
  }
`;

const ThemedComponent = () => {
	const { colors } = useContext(ColorContext);

	return (
		<>
			<GlobalStyles colors={colors} />
			{/* Rest of your component */}
		</>
	);
};

export default ThemedComponent;
