import React, { useEffect, useRef, useState } from 'react';
import DynamicTable from '../../components/DynamicTable/DynamicTable';
import useWeeklyTemplates from '../../hooks/useWeeklyTemplates';
import useQueryFilters from '../../hooks/useQueryFilters';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { FaCalendar } from 'react-icons/fa';
import { BsPlusCircle } from 'react-icons/bs';
import './WeeklyTemplatesList.css';
import WeeklyTemplatesEditor from './WeeklyTemplatesEditor/WeeklyTemplatesEditor';
import MessageBox from '../../components/MessageBox';
import _weeklyTemplates from '../../services/weeklyTemplate.service';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';

export default function WeeklyTemplatesList() {
	const { searchParams, fields, setPage } = useQueryFilters({ baseUrl: '/AdminScreen/weeklyTemplates' });
	const { weeklyTemplates, loading, weeklyTemplateCount, error, reloadWeeklyTemplates } = useWeeklyTemplates(searchParams);
	const [editorIsOpen, setEditorIsOpen] = useState(false);
	const [selectedWeeklyTemplate, setSelectedWeeklyTemplate] = useState(null);
	const openEditor = () => setEditorIsOpen(true);
	const closeEditor = () => {
		setEditorIsOpen(false);
		setSelectedWeeklyTemplate(null);
	};
	const firstLoad = useRef(true);

	useEffect(() => {
		if(!firstLoad.current) {
			reloadWeeklyTemplates();
		} else {
			firstLoad.current = false;
		}
	},[fields.page]);

	const editHandler = (weeklyTemplate) => {
		setSelectedWeeklyTemplate(weeklyTemplate);
		openEditor();
	};

	const deleteHandler = async (weeklyTemplate) => {
		try {
			await _weeklyTemplates.delete(weeklyTemplate._id);
			toast.success('Calendario semanal eliminado con éxito.');
			onSuccessCreate();
		} catch (ex) {
			console.error(ex);
			toast.error('Error. No se pudo eliminar el calendario semanal.');
		};
	};

	const onSuccessCreate = () => {
		reloadWeeklyTemplates();
	};

	return (

		<div id='admin-exercises-screen' className='container admin-con'>
			<Helmet>
				<title>Calendario semanal</title>
			</Helmet>
			<WeeklyTemplatesEditor weeklyTemplateParam={selectedWeeklyTemplate}
				show={editorIsOpen} close={closeEditor} success={onSuccessCreate} />
			<Row className='w-100 justify-content-between align-items-center mb-3 px-2'>
				<h1 className='section-title fit-content m-0'>
					<FaCalendar className='me-1' />
					Calendario semanal
				</h1>
				<Button
					className="fit-content m-0"
					value="Crear cuponera"
					onClick={openEditor}
				>
					<BsPlusCircle className='me-1' />
						Crear Calendario
				</Button>
			</Row>
			<Row className='w-100 justify-content-center'>
				{weeklyTemplates.length || loading ? <DynamicTable
					data={weeklyTemplates}
					loading={loading}
					error={error}
					count={weeklyTemplateCount}
					onPageChange={setPage}
					page={parseInt(fields.page)}
					pageSize={parseInt(fields.pageSize)}
					columnInfo={[
						{
							name: {
								title: 'Nombre',
								type: 'text',
								label: 'Nombre',
							}
						},
						{	dateFrom: {
							title: 'Desde',
							type: 'date',
							label: 'Desde',
						}
						},
						{
							dateTo: {
								title: 'Hasta',
								type: 'date',
								label: 'Hasta',
							}
						},
						{	isActive: {
							title: 'Activo?',
							type: 'boolean',
							label: 'Activo?',
						}
						},
					]}
					actionButtons={{
						editHandler,
						deleteHandler
					}}
					dataName='weeklyTemplates'
					showCheckboxColumn={false}
				/> :
					<MessageBox className={'my-2'}>No se encontraron calendarios semanales</MessageBox>
				}
			</Row>
		</div>
	);
}
