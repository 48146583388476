export const generateImage = async (name, type, category, rounds, exercisesList, colorsList, wod) => {
	const canvas = document.createElement('canvas');
	const ctx = canvas.getContext('2d');

	canvas.width = 800;
	canvas.height = 600;

	ctx.fillStyle = getRandomColorFromDB(colorsList);
	ctx.fillRect(0, 0, canvas.width, canvas.height);

	ctx.textAlign = 'center';


	const titleFontSize = 35;
	const subTitleFontSize = 30;
	const bodyFontSize = 24;
	const titleColor = getRandomColorFromDB(colorsList);
	const exerciseColor = getRandomColorFromDB(colorsList);
	const customFont = 'Verdana';

	const drawText = (text, x, y, fontSize, fontColor, fontFamily, fontWeight = 'normal') => {
		ctx.font = `${fontWeight} ${fontSize}px ${fontFamily}`;
		ctx.fillStyle = fontColor;
		ctx.textAlign = 'center';
		ctx.fillText(text, x, y);
	};

	drawText(`${name}`, canvas.width / 2, 50, titleFontSize, titleColor, customFont, 'bold');

	drawText(`${type}/${category}`, canvas.width / 2, 100, subTitleFontSize, titleColor, customFont);
	drawText(`TimeCap: ${wod.timeCap}'   Rounds: ${rounds}`, canvas.width / 2, 140, subTitleFontSize, titleColor, customFont);

	ctx.font = `${bodyFontSize}px ${customFont}`;
	ctx.fillStyle = exerciseColor;
	ctx.textAlign = 'left';

	let yOffset = 200;
	exercisesList.forEach((exercise) => {
		ctx.fillText(`${exercise.exercise[0].name} - Peso: ${exercise.weight}kg - Reps: ${exercise.reps}`, 50, yOffset);
		yOffset += 35;
	});
	const imgData = canvas.toDataURL('image/png');

	return imgData;
};
const getRandomColorFromDB = (colorsList) => {
	const colorValues = Object.values(colorsList);
	return colorValues[Math.floor(Math.random() * colorValues.length)];
};
