import React from 'react';
import './LandingPageScreen.css';
import { sections } from './nonLoggedSections';
import { useNavigate } from 'react-router-dom';

const LandingPageScreen = () => {
	const navigate = useNavigate();
	return (
		<div id='landing-page-screen'>
			{
				Object.values(sections).map((section, i) => {
					return <section className={section.className} id={section.id} style={{ backgroundImage: `url(${section.image})` }}
						key={`${i}${section.id}`}>
						{section.overlay &&
							<>
								<div className="section-top-overlay">

								</div>
								<div className="section-overlay">
								</div>
							</>
						}
						<div className="body">
							{
								section.content.map((content, j) => {
									return <>
										{React.createElement(content.tag,
											{ key: `${ j + 1 }${ content.caption }`, className: content.shortCaption ? 'long-caption' : 'caption' }, content.caption)}
										{React.createElement(content.tag, {
											key: `${j + 2}${content.shortCaption}`,
											className: 'short-caption'
										}, content.shortCaption)}
									</>;
								})
							}
							{section.buttonCaption &&
								<button onClick={() => navigate(section.to)}>
									{section.buttonCaption}
								</button>
							}
							{
								section.linkCaption &&
								<a>
									{section.linkCaption}
								</a>
							}
						</div>
					</section>;
				})
			}
		</div>
	);
};

export default LandingPageScreen;