import React from 'react';
import { DateTime } from 'luxon';
import './Day.css';
import classnames from 'classnames';

import PropTypes from 'prop-types';

DayButton.propTypes = {
	date: PropTypes.instanceOf(DateTime).isRequired,
	onSelect: PropTypes.func.isRequired,
	selected: PropTypes.bool
};
export default function DayButton({ date, onSelect, selected, locale ='en' }) {

	return <div className='day-container'>
		<button className={classnames('day-button', { selected })} onClick={onSelect.bind(this, date)}>
			<div className='date'>
				{date.day}
			</div>
		</button>
		<div className='name'>
			{
				date.setLocale(locale).weekdayShort.toUpperCase()
			}
		</div>


	</div>;
}
