import React, { useContext, useState, useReducer, useEffect } from 'react';
import settingsService from '../../services/settings.service';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, gotasettings: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

const AdminInfo = () => {
	const [{ loading }, dispatch] = useReducer(reducer, {
		gotasettings: {},
		loading: true
	});

	const { dispatch: ctxDispatch } = useContext(Store);
	const [companyName, setCompanyName] = useState();
	const [companysubName, setCompanysubName] = useState();
	const [companyAddress, setCompanyAddress] = useState();
	const [companyPhone, setCompanyPhone] = useState();
	const [companyCity, setCompanyCity] = useState();
	const [image, setImage] = useState();
	const [openingTime, setOpeningTime] = useState();
	const [closingTime, setClosingTime] = useState();

	const fetchData = async () => {
		try {
			dispatch({ type: 'FETCH_REQUEST' });
			const {data} = await settingsService.get();
			setCompanyName(data.companyName);
			setCompanysubName(data.companysubName);
			setCompanyAddress(data.companyAddress);
			setCompanyPhone(data.companyPhone);
			setCompanyCity(data.companyCity);
			setOpeningTime(data.openingTime);
			setClosingTime(data.closingTime);
			console.log('data', data);
			if (data.image) {
				setImage(data.image);
			}
			dispatch({ type: 'FETCH_SUCCESS', payload: data });
		} catch (error) {
			dispatch({ type: 'FETCH_FAIL', payload: error.message });
		}
	};
	useEffect(() => {
		fetchData();
	}, []);

	const setGotasettings = async () => {
		ctxDispatch({type: 'SHOW_LOADING_OVERLAY'});
		if (companyName && companysubName && companyAddress && companyPhone && companyCity && openingTime && closingTime) {
			if (openingTime < closingTime) {
				try {
					dispatch({ type: 'CREATE_REQUEST' });
					const newSettings = {
						companyName,
						companysubName,
						companyAddress,
						companyPhone,
						image,
						companyCity,
						openingTime,
						closingTime
					};
					const data = await settingsService.edit(newSettings);

					dispatch({
						type: 'UPDATE_SUCCESS'
					});
					ctxDispatch({ type: 'UPDATE_SUCCESS', payload: data });
					toast.success('Empresa actualizada Correctamente');
				} catch (error) {
					dispatch({ type: 'FETCH_FAIL', payload: error.message });
					toast.error(error.message);
				}			
			} else {
				toast.error('La hora de apertura debe ser menor que la hora de cierre');
			}

		} else {
			toast.error('Por favor complete todos los campos');
		}
		ctxDispatch({type: 'HIDE_LOADING_OVERLAY'});
	};

	const uploadPicture = async (event) => {
		const file = event.target.files[0];
		const formData = new FormData();
		let r = Math.random().toString(36).substring(7);
		formData.append('file', file, file.name.replaceAll(' ') + r);
		if (image) {
			formData.append('documentId', image._id);
		}
		const response = await settingsService.uploadSettingsImage(formData);
		setImage(response);
		toast.success('imagen agregada correctamente');
	};

	return (
		<div className="Admin-info">
			{loading ? (
				<LoadingBox />
			) : (
				<div className="container  ps-0 mt-2">
					<Form className='row'>
					
						<Form.Group className="mb-3 col-md-6" controlId="companyName">
							<Form.Label>Nombre de Empresa</Form.Label>
							<Form.Control
								onChange={(e) => setCompanyName(e.target.value)}
								value={companyName}
							></Form.Control>
						</Form.Group>
						<Form.Group className="mb-3 col-md-6" controlId="companysubName">
							<Form.Label>Frase/tipo de Empresa</Form.Label>
							<Form.Control
								onChange={(e) => setCompanysubName(e.target.value)}
								value={companysubName}
							></Form.Control>
						</Form.Group>
						<Form.Group className="mb-3 col-md-6" controlId="companyAdress">
							<Form.Label>Direccion de Empresa</Form.Label>
							<Form.Control
								onChange={(e) => setCompanyAddress(e.target.value)}
								value={companyAddress}
							></Form.Control>
						</Form.Group>

						<Form.Group className="mb-3 col-md-6" controlId="companyCity">
							<Form.Label>Ciudad-Pais</Form.Label>
							<Form.Control
								onChange={(e) => setCompanyCity(e.target.value)}
								value={companyCity}
							></Form.Control>
						</Form.Group>

						<Form.Group className="mb-3 col-md-6" controlId="companyPhone">
							<Form.Label>Telefono</Form.Label>
							<Form.Control
								onChange={(e) => setCompanyPhone(e.target.value)}
								value={companyPhone}
							></Form.Control>
						</Form.Group>
						<Form.Group className="mb-3 col-6 col-md-3" controlId="companyPhone">
							<Form.Label>Hora de apertura</Form.Label>
							<Form.Control
								type='time'
								onChange={(e) => setOpeningTime(e.target.value)}
								value={openingTime}
							></Form.Control>
						</Form.Group>
						<Form.Group className="mb-3 col-6 col-md-3" controlId="companyPhone">
							<Form.Label>Hora de cierre</Form.Label>
							<Form.Control
								type='time'
								onChange={(e) => setClosingTime(e.target.value)}
								value={closingTime}
							></Form.Control>
						</Form.Group>
						<Row>
							<Col>
								<Form.Group className="mb-3 col-md-6" controlId="">
									<Form.Label>Subir Logo</Form.Label>
									<Form.Control
										type="file"
										onChange={uploadPicture}
										name="logo"
									></Form.Control>
								</Form.Group>
							</Col>
							<Col>
								{image && image.fileLink ? (
									<img
										alt="logo_photo"
										src={image.fileLink}
										style={{ maxWidth: '100px', maxHeight: '100px' }}
									/>
								) : (
									<img
										alt="logo_photo"
										src="/images/emptyPhoto.png"
										style={{ maxWidth: '100px', maxHeight: '100px' }}
									/>
								)}
							</Col>
						</Row>
						<Row>
							<Col>
								<Button md={2} onClick={setGotasettings}>
									Actualizar
								</Button>
							</Col>
						</Row>
					</Form>
				</div>
			)}
		</div>
	);
};
export default AdminInfo;
