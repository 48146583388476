import React, { useReducer, useState ,useEffect,useContext} from 'react';
import wodService from '../../services/wod.service';

import { Helmet } from 'react-helmet-async';
import {
	Button,
	Modal,
	Form,
	Container,
	Row,
	Col,
	Card,
	Stack
} from 'react-bootstrap';
import { useNavigate,useParams} from 'react-router-dom';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { getError } from '../../utils';
import LoadingBox from '../../components/LoadingBox';
import { FaRegTrashAlt } from 'react-icons/fa';
import resultsService from '../../services/results.service';
import { toast } from 'react-toastify';
import { Store } from '../../Store';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state,
			 WOD: action.payload.wod,
			 resultsUpdated: action.payload.resultsUpdated,
			 loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'CREATE_REQUEST':
		return { ...state, loadingCreate: true };
	case 'CREATE_SUCCESS':
		return {
			...state,
			loadingCreate: false
		};
	case 'CREATE_FAIL':
		return { ...state, loadingCreate: false };

	default:
		return state;
	}
};

function AddUserResultsByEvent() {
	const navigate = useNavigate();
	const { state: contextState } = useContext(Store);
	const { userInfo } = contextState;
	const params = useParams();
	const { id } = params;
	
	const [reducerState, dispatch] = useReducer(reducer, {
		loading: true,
		loadingCreate: false,
		error: '',
		WOD: {},
		resultsUpdated:{},
	});
	

	const { loading, loadingCreate} = reducerState;
	const [exercisesList, setExercisesList] = useState([]);
	const [rounds, setRounds]=useState();
	const [timeCap,setTimeCap]=useState();
	const [maxReps,setMaxReps]=useState();
	const [maxWeight,setMaxWeight]=useState();
	const [wod,setWod]=useState();
	const [resultsUpdated,setResultsUpdated]=useState();
	const [showModal, setShowModal] = useState(false);
	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);


	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await wodService.getWodByEvent(id);
				setExercisesList(result.wod.wodExercises);
				setWod(result.wod);
				setResultsUpdated(result.resultsUpdated);
				const firstResult = result.resultsUpdated?.[0] || {};
				console.log(firstResult);
				setRounds(firstResult.rounds || result.wod.rounds || 0);
				setTimeCap(firstResult.timeCap || result.wod.timeCap || 0);
				setMaxReps(firstResult.maxReps || 0);
				setMaxWeight(firstResult.maxWeight || 0);
				// setExercises(
				// 	result.wodExercises.map((item) => ({ name: item.exercise.name, _id: item.exercise._id})));
				dispatch({ type: 'FETCH_SUCCESS', payload: result });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
		};
		fetchData();
	}, [id]);
	






	//remove exercise to the list in the wod
	const handleRemoveExercise = (index) => {
		const updatedExercisesList = exercisesList.filter((_, i) => i !== index);
		setExercisesList(updatedExercisesList);
	};

	const handleExerciseDetailChange = (index, field, value) => {
		const updatedExercisesList = [...exercisesList];
		updatedExercisesList[index][field] = value;
		setExercisesList(updatedExercisesList);
	};

	const addResultsHandler = async () => {
		if (!id || !exercisesList  || !userInfo) {
			toast.error('Para agregar resultados debe completar la informacion solicitada');
			return;
		}
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			await resultsService.createResults(id,exercisesList,userInfo,rounds,timeCap,maxReps,maxWeight);	
			toast.success('Resultados ingresados');
			navigate(-1);
		} catch (ex) {
			toast.error(getError(ex));
		}
		handleClose();
	};
	

	
	
	return (
		<div>
			<div>
				<Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
					<Modal.Header closeButton>
						<Modal.Title>Agregar Resultados</Modal.Title>
					</Modal.Header>
					<Modal.Body>Seguro desea agregar Resultados?</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							Cancelar
						</Button>
						<Button className='btn btn-dark m-1 fixed-right btn btn-primary' onClick={addResultsHandler}>Confirmar</Button>
					</Modal.Footer>
				</Modal>
			</div>
			<Container className="large-container">
				<Helmet>
					<title>Agregar resultados evento</title>
				</Helmet>
				<Container fluid>
					<Row className="vh-100 d-flex justify-content-center align-items-center">
						<Col md={12} lg={12} xs={12}>
							<Card className="shadow p-1">
								<Card.Body>
									{(loadingCreate || loading)?<LoadingBox></LoadingBox>:('')}
									<div className="mb-3 mt-md-4">
										<h2 className="fw-bold mb-4 text-uppercase text-align-center ">
											{resultsUpdated && resultsUpdated.length === 0? ('Agregar resultados'):('Los resultados ya han sido ingresados')}
										</h2>
										<h3> {wod && wod.category? (wod.category):('')}/{wod && wod.type?(wod.type):('')}{wod && wod.type && wod.type==='AMRAP'?('rounds:',wod.rounds): wod && wod.type && wod.type==='For time'?('TimeCap:',wod.timeCap):('')}</h3>
										<div className="mb-3">
											<Form onSubmit={(e) => e.preventDefault()} className='col'>
												{wod && exercisesList && resultsUpdated && resultsUpdated.length === 0 ? (
													<Card className='m-3 p-4'>
														<h2 className='mb-3'>Por Ejercicio</h2>
														{exercisesList.map((item, index) => (
															<Row key={index} className="mb-3">
																<Form.Group className="col-lg-2">
																	<Form.Label>Ejercicio</Form.Label>
																	<Form.Control
																		type="text"
																		value={item.exercise?.name}
																		readOnly
																	/>
																</Form.Group>
																<Form.Group className="col-lg-2">
																	<Form.Label>Reps</Form.Label>
																	<Form.Control
																		type="number"
																		value={item.reps}
																		onChange={(e) =>
																			handleExerciseDetailChange(index, 'reps', e.target.value)
																		}
																	/>
																</Form.Group>
																<Form.Group className="col-lg-2">
																	<Form.Label>Peso</Form.Label>
																	<Form.Control
																		type="number"
																		value={item.weight}
																		onChange={(e) =>
																			handleExerciseDetailChange(index, 'weight', e.target.value)
																		}
																	/>
																</Form.Group>
																<Form.Group className="col-lg-2">
																	<Form.Label>Times</Form.Label>
																	<Form.Control
																		type="number"
																		value={item.times}
																		onChange={(e) =>
																			handleExerciseDetailChange(index, 'times', e.target.value)
																		}
																	/>
																</Form.Group>
																<Form.Group className="col-lg-2">
																	<Form.Label>Rounds</Form.Label>
																	<Form.Control
																		type="number"
																		value={item.rounds}
																		onChange={(e) =>
																			handleExerciseDetailChange(index, 'rounds', e.target.value)
																		}
																	/>
																</Form.Group>
																<Col lg={2} className="d-flex align-items-end">
																	<Button
																		variant="primary"
																		onClick={() => handleRemoveExercise(index)}
																	>
																		<FaRegTrashAlt/>
																	</Button>
																</Col>
															</Row>
														))}
													</Card>):('')}
												<Card className='m-3 p-4 m-4'>
													<h2 className='mb-3 text-align-center' style={{textAlign:'center'}}>Resultado</h2>
													<Row className='align-items-center justify-content-center'>
														{wod && wod.type && wod.type === 'AMRAP'?
															(<Form.Group className="col-lg-3">
																<Form.Label>Rounds</Form.Label>
																<Form.Control
																	type="number"
																	defaultValue={rounds? Number(rounds) :0}
																	onChange={(e) =>
																		setRounds(e.target.value)
																	}
																	disabled={resultsUpdated.length > 0}
																/>
															</Form.Group>):('')}
														{wod && wod.type && wod.type === 'For Time'?(
															<Form.Group className="col-lg-3">
																<Form.Label>TimeCap</Form.Label>
																<Form.Control
																	type="number"
																	defaultValue={timeCap? Number(timeCap):0}
																	onChange={(e) =>
																		setTimeCap(e.target.value)
																	}
																	disabled={resultsUpdated && resultsUpdated.length > 0}
																/>
															</Form.Group>):('')}
														<Form.Group className="col-lg-3">
															<Form.Label>Max Reps</Form.Label>
															<Form.Control
																type="number"
																defaultValue={maxReps? Number(maxReps):0}
																onChange={(e) =>
																	setMaxReps(e.target.value)
																}
																disabled={resultsUpdated && resultsUpdated.length > 0}
															/>
														</Form.Group>
														<Form.Group className="col-lg-3">
															<Form.Label>Max Weight</Form.Label>
															<Form.Control
																type="number"
																defaultValue={maxWeight? Number(maxWeight):0}
																onChange={(e) =>
																	setMaxWeight(e.target.value)
																}
																disabled={resultsUpdated && resultsUpdated.length > 0}
															/>
														</Form.Group>
													</Row>
												</Card>
												<Stack direction="horizontal" gap={10}>
													<Button id="goBack" onClick={() => navigate(-1)}>
														<BsArrowLeftCircle /> Atras
													</Button>
													{resultsUpdated && resultsUpdated.length === 0?
														(<Button type="submit" className="mx-auto" onClick={handleShow}>
														Agregar Resultados
														</Button>):('')}
												</Stack>
											</Form>
										</div>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</Container>
		</div>
	);
}
export default AddUserResultsByEvent;