import LoadingBox from '../../components/LoadingBox';
import React, { useReducer, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Button, Col, Container, Row, Card, Stack, Form } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { getError } from '../../utils';
import rmService from '../../services/rm.service';
import { useNavigate, useParams } from 'react-router-dom';
import { BsArrowLeftCircle } from 'react-icons/bs';
import useExercises from '../../hooks/useExercises';
import useQueryFilters from '../../hooks/useQueryFilters';
import Modal from 'react-bootstrap/Modal';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, RM: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

function EditRM() {
	const params = useParams();
	const navigate = useNavigate();
	const { searchParams } = useQueryFilters({ baseUrl: '/AdminScreen/rms' });
	const [selectedExercise, setSelectedExercise] = useState([]);
	const [exercisesList] = useState([]);
	const { exercises } = useExercises(searchParams);
	const [ setMaxRep] = useState(1);
	const [setWeight] = useState(1);
	const [showModal, setShowModal] = useState(false);
	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);

	const { id } = params;
	const [{ loading }, dispatch] = useReducer(reducer, {
		RM: [],
		loading: true,
		error: ''
	});

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await rmService.getRmById(id);
				dispatch({ type: 'FETCH_SUCCESS', payload: result });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
		};
		fetchData();
	}, [id]);

	const editRmHandler = async () => {
		// try {
		// 	dispatch({ type: 'CREATE_REQUEST' });
		// 	const updatedData = {
		// 		excercise: excercise,
		// 		maxRep: maxRep,
		// 		weight: weight,
		// 	};
		// 	const data = await userBookingService.updateUserBooking(
		// 		userbooking._id,
		// 		updatedData
		// 	);
		// 	dispatch({
		// 		type: 'UPDATE_SUCCESS'
		// 	});

		// 	toast.success('Cuponera para el usuario actualizada');
		// 	navigate(`/AdminScreen/userbookinglist/${data.user}`);
		// } catch (err) {
		// 	dispatch({ type: 'FETCH_FAIL' });
		// 	toast.error(getError(err));
		// }
	};

	return (
		<div>
			{loading && <LoadingBox></LoadingBox>}
			<Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
				<Modal.Header closeButton>
					<Modal.Title>Guardar Cambios</Modal.Title>
				</Modal.Header>
				<Modal.Body>Seguro desea guardar los cambios?</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
                        Cancelar
					</Button>
					<Button onClick={editRmHandler}>Confirmar</Button>
				</Modal.Footer>
			</Modal>
			<Container className="large-container">
				<Helmet>
					<title>RM guardado</title>
				</Helmet>
				<Container fluid>
					<Row className="vh-100 d-flex justify-content-center align-items-center">
						<Col md={8} lg={6} xs={12}>
							<div className="border border-3 border-primary"></div>
							<Card className="shadow">
								<Card.Body>
									<div className="mb-3 mt-md-4">
										<h2 className="fw-bold mb-4 text-uppercase text-align-center ">
                                            editar rm
										</h2>
										<div className="mb-3">
											<Container>
												<Row>
													<Form.Group className="col-lg-6">
														<Form.Label>Buscar Ejercicio</Form.Label>
														<Typeahead
															id="exercise-search"
															options={[
																...exercises,
																{ _id: 'add-new', name: 'Agregar nuevo ejercicio', emptyOption: true }
															]}
															placeholder="Seleccione un ejercicio"
															labelKey="name"
															selected={selectedExercise}
															onChange={(selected) => {
																if (selected[0] && selected[0]._id === 'add-new') {
																	navigate('/AdminScreen/exercises');
																} else {
																	setSelectedExercise(selected);
																}
															}}
														/>
													</Form.Group>
												</Row>
												{exercisesList.map((item, index) => (
													<Row key={index} className="mb-3">
														<Form.Group className="col-lg-6">
															<Form.Label>Ejercicio</Form.Label>
															<Form.Control
																type="text"
																value={item.exercise.name}
																readOnly
															/>
														</Form.Group>
													</Row>
												))}
												<Row>
													<Form.Group className="mb-3 col-lg-6" controlId="timeCap">
														<Form.Label>Maximo Repeticiones</Form.Label>
														<Form.Control
															type="number"
															min={0}
															onChange={(e) => setMaxRep(e.target.value)}
														></Form.Control>
													</Form.Group>
												</Row>
												<Row>
													<Form.Group className="mb-3 col-lg-6" controlId="weight">
														<Form.Label>Peso</Form.Label>
														<Form.Control
															type="number"
															min={0}
															onChange={(e) => setWeight(e.target.value)}
														/>
													</Form.Group>
												</Row>
											</Container>

											<Stack direction="horizontal" gap={10}>
												<Button id="goBack" onClick={() => navigate(-1)}>
													<BsArrowLeftCircle /> Atras
												</Button>
												<Button type="submit" className="mx-auto" onClick={handleShow}>
                                                    Guardar Cambios
												</Button>
											</Stack>
										</div>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</Container>
		</div>
	);
}
export default EditRM;
