import React from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from '../../hooks/usePagination';
import './ParamPagination.css';
import { addAlphaToHex } from '../../utils';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';

const ParamPagination = (props) => {

	const secondaryColorDimmed = addAlphaToHex(getComputedStyle(document.documentElement)
		.getPropertyValue('--secondaryColor').replace('#', ''),0.4);
	const {
		onPageChange,
		totalCount,
		siblingCount = props.siblingCount || 1,
		className,
		setPageSize,
		currentPage
	} = props;
	const changePage = (pageNumber) => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
		onPageChange(pageNumber);
	};
	const pageSize = parseInt(props.pageSize);

	const paginationRange = usePagination({
		currentPage,
		totalCount,
		siblingCount,
		pageSize,
	});
	if (currentPage === 0 || paginationRange.length < 1) {
		return null;
	}

	const onNext = () => {
		onPageChange(currentPage + 1);
	};

	const onPrevious = () => {
		onPageChange(currentPage - 1);
	};

	let lastPage = paginationRange[paginationRange.length - 1];
	
	const pageSizes = [16, 24, 48];
	

	return (
		<ul
			className={classnames('pagination-container param', { [className]: className })}
		>
			<li>
				<DropdownButton className='pagination-dropdown' title={pageSize}>
					{pageSizes.map((size) =>
						(<Dropdown.Item onClick = {() => setPageSize(size)}
							key={`page${size}`} as={'button'}>{size}</Dropdown.Item>)
					)}
				</DropdownButton>
			</li>
			<li
				className={classnames('pagination-item', {
					disabled: currentPage === 1,
				})}
				onClick={onPrevious}
			>
				<div className="arrow left" />
			</li>
			{paginationRange.map((pageNumber, i) => {
				if (pageNumber === DOTS) {
					return (
						<li key={i} className="pagination-item dots">
              &#8230;
						</li>
					);
				} 
				return (
					<li
						key={i}
						className={classnames('pagination-item', {
							selected: pageNumber === parseInt(currentPage),
							tens: pageNumber > 9 && pageNumber <= 17,
							twenties: pageNumber > 17 && pageNumber <= 29,
							'thirties-to-sixties': pageNumber > 29 && pageNumber <= 59,
							sixties: pageNumber > 59 && pageNumber <= 69,
							seventies: pageNumber > 69 && pageNumber <= 79,
							eighties: pageNumber > 79 && pageNumber <= 89,
							nineties: pageNumber > 89 && pageNumber <= 99,
							'three-figure': pageNumber > 99,
							// "two-figure": pageNumber > 17 && pageNumber <= 99,
							// "three-figure": pageNumber > 99,
						})}
						onClick={() => changePage(pageNumber)}
					>
						{pageNumber}
					</li>
				);
			})}
			<li
				className={classnames('pagination-item', {
					disabled: currentPage === lastPage,
				})}
				onClick={onNext}
			>
				<div className="arrow right" />
			</li>
			<style>
				{
					`
        .pagination-container > * {
          background-color: ${secondaryColorDimmed}
        }
        `
				}
			</style>
		</ul>
	);
};

export default ParamPagination;
