import restClient from '../restClient';
import authHeader from './auth-header';
import { handleErrors } from './common.service';

const API_URL = '/membershipTemplates';

class MembershipTemplateService {
	async getAllMembershipTemplates() {
		try {
			const response = await restClient.get(API_URL, {
				headers: authHeader()
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async getActiveMembershipTemplates() {
		try {
			const response = await restClient.get(API_URL + '/getactive', {
				headers: authHeader()
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async createMembershipTemplate(bookingTemplate) {
		try {
			const response = await restClient.post(API_URL, bookingTemplate, {
				headers: authHeader()
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async getMembershipTemplateById(id) {
		try {
			const response = await restClient.get(`${API_URL}/${id}`, {
				headers: authHeader()
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async updateMembershipTemplate(id, bookingTemplate) {
		try {
			const response = await restClient.put(`${API_URL}/${id}`, bookingTemplate, {
				headers: authHeader()
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async toggleActiveStatus(id) {
		try {
			const response = await restClient.put(
				`${API_URL}/toggleactive/${id}`,
				null,
				{
					headers: authHeader()
				}
			);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async deleteMembershipTemplate(id) {
		try {
			const response = await restClient.delete(`${API_URL}/${id}`, {
				headers: authHeader()
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}
}

export default new MembershipTemplateService();
