import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Row,
	Button,
	Table,
	Dropdown,
	DropdownButton,
	Card
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';
import { getError, maxitemsPerPage } from '../../utils';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import Pagination from '../../components/Pagination/Pagination';
import { BsPlusCircle, BsTrash, BsFileEarmarkRichtext } from 'react-icons/bs';
import { BsFillFileEarmarkFill } from 'react-icons/bs';
import wodService from '../../services/wod.service';
import DeleteConfirmationModal from '../../components/DeleteConfirmationModal/DeleteConfirmationModal';
import WodEditor from './WodEditor/WodEditor';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return {
			...state,
			WODs: action.payload,
			loading: false,
			itemQuantity: action.payload.length
		};
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

function WODList() {
	const [{ loading, error, WODs, successDelete, itemQuantity }, dispatch] =
		useReducer(reducer, {
			WODs: [],
			loading: true,
			itemQuantity: 0,
			error: ''
		});

	const { state } = useContext(Store);
	const { userInfo } = state;
	const [showWodEditor, setShowWodEditor] = useState(false);
	const navigate = useNavigate();

	//pagination PENDING
	const [currentPage, setCurrentPage] = useState(1);
	const indexOfLastPost = currentPage * maxitemsPerPage;
	const indexOfFirstPost = indexOfLastPost - maxitemsPerPage;
	let currentPosts = WODs.slice(indexOfFirstPost, indexOfLastPost);

	//change page PENDNG
	const onPageChange = (pageNumber) => {
		setCurrentPage(pageNumber);

		currentPosts = WODs.slice(indexOfFirstPost, indexOfLastPost);
	};

	//modal

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [selectedWOD, setSelectedWOD] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const data = await wodService.getAllWods();
				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
			}
		};
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		} else {
			fetchData();
		}
	}, [successDelete, userInfo.token]);

	async function deleteWODHandler() {
		try {
			dispatch({ type: 'DELETE_REQUEST' });
			await wodService.deleteWod(selectedWOD._id);
			handleCloseModal();
			dispatch({ type: 'DELETE_SUCCESS' });
			toast.success('WOD eliminado');
		} catch (err) {
			toast.error(getError(err));
			dispatch({ type: 'DELETE_FAIL' });
		}
	}
	async function detailsWODHandler(WOD) {
		navigate(`/AdminScreen/editWOD/${WOD._id}`);
	}
	function newWODHandler() {
		setShowWodEditor(true);
	}

	const deleteHandler = (WOD) => {
		setSelectedWOD(WOD);
		setShowDeleteModal(true);
	};

	const handleCloseModal = () => {
		setSelectedWOD(null);
		setShowDeleteModal(false);
	};

	const onHide = () => {
		dispatch({ type: 'DELETE_RESET'});
		setShowWodEditor(false);
	}; 

	return loading ? (
		<LoadingBox></LoadingBox>
	) : error ? (
		<MessageBox variant="danger">{error}</MessageBox>
	) : (
		<div>
			<Helmet>
				<title>WODs</title>
			</Helmet>
			<WodEditor show={showWodEditor} onHide={onHide}/>
			<DeleteConfirmationModal
				show={showDeleteModal}
				handleClose={handleCloseModal}
				handleDelete={deleteWODHandler}
				bodyText="Seguro desea eliminar este WOD?"
				itemType="WOD"
				itemData={
					<>
						<td className="col-md-2">{selectedWOD?.name}</td>
						<td className="col-md-2">{selectedWOD?.date}</td>
						{selectedWOD?.image ? (
							<td className="col-md-2">{selectedWOD.image.s3_key}</td>
						) : (
							<td className="col-md-2"></td>
						)}
												
					</>
				} 
			/>

			<div className="container admin-con">
				<div className="tableResponsive">
					<Row className='w-100 justify-content-between align-items-center mb-3 px-2'>
						<h1 className="section-title fit-content m-0">
							<BsFillFileEarmarkFill></BsFillFileEarmarkFill>
								Lista de WODs
						</h1>

						<Button
							className="fit-content m-0"
							value="Crear cuponera"
							onClick={() => newWODHandler()}
						>
							<span>
								<BsPlusCircle />
									Crear WOD
							</span>
						</Button>
					</Row>
					{loading ? (
						<LoadingBox></LoadingBox>
					) : error ? (
						<MessageBox variant="danger">{error}</MessageBox>
					) : (
						<Card>
							<Table bordered hover responsive size="sm">
								<thead>
									<tr>
										<th>
											<span>Nombre</span>
										</th>
										<th className="col-1">Opciones</th>
									</tr>
								</thead>
								<tbody>
									{WODs
										? currentPosts.map((WOD) => (
											<tr
												className="align-items-center table-order"
												id="data"
												key={WOD._id}
											>
												<td className="col-md-2">{WOD.name}</td>
												<td>
													<DropdownButton id="optionsLists" drop="start" title="">
														<Dropdown.Item
															eventKey="0"
															onClick={() => detailsWODHandler(WOD)}
														>
															<BsFileEarmarkRichtext /> Ver Detalles
														</Dropdown.Item>
														<Dropdown.Item
															eventKey="2"
															onClick={() => deleteHandler(WOD)}
														>
															<BsTrash /> Eliminar
														</Dropdown.Item>
													</DropdownButton>
												</td>
											</tr>
										))
										: ''}
								</tbody>
							</Table>
						</Card>
					)}
					<Pagination
						className="pagination-bar"
						totalCount={itemQuantity}
						onPageChange={onPageChange}
						currentPage={currentPage}
						pageSize={maxitemsPerPage}
					></Pagination>
				</div>
			</div>
		</div>
	);
}

export default WODList;
