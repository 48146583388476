import React, { useContext } from 'react';
import LoginPrompt from '../LoginPrompt/LoginPrompt';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import './CheckoutLoginPrompt.css';

export default function CheckoutLoginPrompt({ storeContext, userService, authService, getError}) {
	const { state, dispatch: ctxDispatch } = useContext(storeContext); 
	const navigate = useNavigate();

	const checkoutHandler = () => {
		const redirect = state.usesShipping ? 'shipping' : 'payment';
		navigate(`/checkout/${redirect}`);
	};

	const handleGuestCheckout = async (email) => {
		try {
			const { data } = await userService.createGuest(email);
			submitHandler(data.email, data.password);
		} catch (ex) {
			console.error(ex);
			toast.error(getError(ex));
		}
	};
	const submitHandler = async (email, password) => {
		try {
			const { data } = await authService.login(email, password);
			ctxDispatch({ type: 'USER_SIGNIN', payload: data });
			localStorage.setItem('userInfo', JSON.stringify(data));
			localStorage.setItem('refreshToken', data.refreshToken);
			checkoutHandler();
		} catch (err) {
			toast.error(getError(err));
		}
	};
	return <div className="login-container bg-white rounded">
		<LoginPrompt className="bg-transparent w-100"
			showPassword submitHandler={submitHandler}
			buttonCaption="Ingresar"
			caption="Inicia sesión" />
		<div className="separator">O</div>
		<LoginPrompt className="bg-transparent w-100"
			submitHandler={handleGuestCheckout}
			buttonCaption="Continuar"
			caption="Continuar como invitado" />
	</div>;
}
