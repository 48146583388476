export class WOD {
	constructor(name, description, image, timeCap, rounds, category, type, wodExercises, restBetweenRounds) {
		this.name = name;
		this.description = description;
		this.image = image;
		this.timeCap = timeCap;
		this.rounds = rounds;
		this.category = category;
		this.type = type;
		this.wodExercises = wodExercises;
		this.restBetweenRounds = restBetweenRounds;
	}
	static create(data) {
		const { name, description, image, timeCap, rounds, category, type, wodExercises, restBetweenRounds } = data;
		return new WOD(name, description, image, timeCap, rounds, category, type, wodExercises, restBetweenRounds);
	}
	static createEmptyWOD() {
		return new WOD('', '', '', 0, 0, '', '', [], 0);
	}

	// Add any methods if needed
	validateBasicData() {
		const { name, timeCap, rounds, category, type, restBetweenRounds } = this;
		return name.trim() !== '' && Number(timeCap) > -1 && rounds != null && category && type && Number(restBetweenRounds) > -1;
	}
}
