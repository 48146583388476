import React from 'react';
import './MembershipTemplateCard.css'; 

function MembershipTemplateCard({ membershipTemplate, addToCart }) {
	return (
      	<div className="membershipTemplate-card"> 
			<div className="card-content">
				<div className="membershipTemplate-classes-number">
					<strong>{membershipTemplate.totalBookings}</strong>
					<span> CLASES</span>
				</div>
				<div className="membershipTemplate-info">
					<p>Disponible durante {membershipTemplate.daysLast} días para cualquier clase del box.</p>
					<p style={{fontSize: '12px'}}>${membershipTemplate.price}</p>
					<div className="template-button-container">
						<button className="add-to-cart-button" onClick={addToCart}>Agregar al Carrito</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default MembershipTemplateCard;