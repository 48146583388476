import React, { useState } from 'react';
import './LoginPrompt.css';
import classnames from 'classnames';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { MdOutlineLock, MdOutlineLockOpen } from 'react-icons/md';
import { FaRegUser } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { MdMailOutline } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
export default function LoginPrompt({
	className,
	buttonCaption = 'Ingresar',
	caption = 'Iniciar Sesión',
	showPassword,
	showForgotPassword,
	submitHandler,
	showSignUp,
	showRepeatPassword,
	showNameInput,
	showAlreadyHasUser,
	showReturn
}) {

	const [passwordInputType, setPasswordInputType] = useState(!showSignUp ? 'text' : 'password');
	const navigate = useNavigate();
	const submitData = (e) => {
		e.preventDefault();
		const { email, password, repeatPassword, name } = e.target;
		submitHandler(email?.value, password?.value, repeatPassword?.value, name?.value);
	};
	const togglePasswordVisibility = () =>
		setPasswordInputType((type) => type === 'password' ? 'text' : 'password');

	return (
		<Form
			className={classnames('login-prompt', { [className]: className })}
			onSubmit={submitData}
		>
			<div className="header">
				<h2 className="text-center">{caption}</h2>
			</div>
			<div className="body justify-content-center align-items-center d-flex flex-wrap">
				<InputGroup className="my-2 mt-3">
					<InputGroup.Text>
						{' '}
						<MdMailOutline />
					</InputGroup.Text>
					<Form.Control
						name="email"
						type="email"
						placeholder="Correo electrónico"
					/>
				</InputGroup>
				{showNameInput && (
					<InputGroup className="my-2">
						<InputGroup.Text >
							<button
								type="button"
								className="bg-transparent p-0 m-0"
								onClick={showSignUp ? togglePasswordVisibility : null}
							>
								<FaRegUser />
							</button>
						</InputGroup.Text>
						<Form.Control
							name="name"
							type="text"
							placeholder="Nombre completo"
						/>
					</InputGroup>
				)}
				{showPassword && (
					<InputGroup className="my-2">
						<InputGroup.Text >
							<button
								type="button"
								className="bg-transparent p-0 m-0"
								onClick={togglePasswordVisibility}
							>
								{passwordInputType === 'password' ? (
									<MdOutlineLock />
								) : (
									<MdOutlineLockOpen />
								)}
							</button>
						</InputGroup.Text>
						<Form.Control
							name="password"
							type={passwordInputType}
							placeholder="Contraseña"
						/>
					</InputGroup>
				)}

				{showRepeatPassword && (
					<InputGroup className="my-2">
						<InputGroup.Text >
							<button
								type="button"
								className="bg-transparent p-0 m-0"
								onClick={togglePasswordVisibility}
							>
								{passwordInputType === 'password' ? (
									<MdOutlineLock />
								) : (
									<MdOutlineLockOpen />
								)}
							</button>
						</InputGroup.Text>
						<Form.Control
							name="repeatPassword"
							type={passwordInputType}
							placeholder="Repite la contraseña"
						/>
					</InputGroup>
				)}
			</div>
			<div className="footer">
				{showReturn && (
					<Link className="w-100" onClick={() => navigate(-1)}>
						Volver
					</Link>
				)}
				{showForgotPassword && (
					<Link className="w-100 forgot-password text-right" to="/reset-password">
						Olvide mi contraseña
					</Link>
				)}
				{showAlreadyHasUser && (
					<span className="w-100 mt-2 text-center">
						¿Ya tienes una cuenta?
						<Link className="ms-1" to="/signin">
							Inicia sesión
						</Link>
					</span>
				)}
				<button
					type="submit"
					className="mt-3"
				>
					{buttonCaption}
				</button>
				{showSignUp && (
					<span className="w-100 mt-3 text-center">
						¿Nuevo cliente?
						<Link className="ms-1" to="/signup">
							Crea una cuenta
						</Link>
					</span>
				)}
			</div>
		</Form>
	);
}
