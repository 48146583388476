
import React, { useContext, useEffect, useState } from 'react';
import { Store } from '../../Store';
import { useNavigate } from 'react-router-dom';
import { IoChevronBack } from 'react-icons/io5';



function UserResultsList() {
	const { state } = useContext(Store);
	const { userInfo } = state;


	const navigate = useNavigate();
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 576); 
		};

		handleResize();
		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, [userInfo]);
	

	//get list of clases of user

	//for each class render component

  


	// async function unsuscribeEventHandler(event) {
	// 	if (window.confirm('Seguro desea desinscribirse a este evento?')) {
	// 		try {
	// 			dispatch({ type: 'DELETE_REQUEST' });
	// 			await eventAttendeeService.unsubscribeEventAttendee(
	// 				userInfo._id,
	// 				event._id
	// 			);
	// 			dispatch({ type: 'DELETE_SUCCESS' });
	// 			toast.success(`Te desinscribiste a el evento ${event.subject}`);
	// 		} catch (err) {
	// 			toast.error('No lograste desinscribiste al evento. Intentelo mas tarde.');
	// 			dispatch({ type: 'DELETE_FAIL' });
	// 		}
	// 	}
	// }


	return (
	 <div id='user-details-screen' className='admin-con perfil-con' style={{marginLeft: '5px', width: '100%'}}>
			{isMobile ? 
				<div className="screen-locator" style={{ display: 'flex', alignItems: 'center' }}>
					<span onClick={() => navigate(-1)} style={{ cursor: 'pointer'}}>
						<IoChevronBack />
					</span>
					<span>Cargar Resultados</span>
				</div> : null
			}
			
			{/* FOR EACH CLASS OF THE USER, CLASSDISPLAY COMPPONENT */}

		</div>

	);
}
export default UserResultsList;
