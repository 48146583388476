import axios from '../restClient';
import { handleErrors } from './common.service';
import Exercise from '../classes/Exercise';

const BASE_URI = '/exercises';

class ExerciseService {
	async list(searchParams,nonPaginated = false) {
		const query = Object.fromEntries(searchParams.entries());
		if(nonPaginated) {
			query.nonPaginated = true;
		}
		try {
			const response = await axios.get(BASE_URI,{
				params: query
			});
			return response;
		} catch (error) {
			handleErrors(error);
		}
	}
	async create(name,isRm, videoUrl, image) {
		if(Exercise.validate(name,isRm, videoUrl, image)) {
			try {
				const response = await axios.post(BASE_URI,{
					name,isRm, videoUrl, image
				});
				return response;
			} catch (error) {
				handleErrors(error);
			}
		} else {
			throw new Error('Error. Los datos ingresados no son válidos.');
		}

	}
	async edit(id, name,isRm, videoUrl, image) {
		if(Exercise.validate(name,isRm, videoUrl, image)) {
			try {
				const response = await axios.put(BASE_URI,{
					name,isRm, videoUrl, image
				},{
					params: { id }
				});
				return response;
			} catch (error) {
				handleErrors(error);
			}
		} else {
			throw new Error('Error. Los datos ingresados no son válidos.');
		}

	}
	async delete(id) {
		try {
			const response = await axios.delete(BASE_URI,{
				params: { id }
			});
			return response;
		} catch (error) {
			handleErrors(error);
		}
	}
	async uploadImage(formData) {
		try {
			const res = await axios.post(
				BASE_URI + '/image',
				formData,
				{headers : {
					'Content-Type': 'multipart/form-data',
				}}
			);
			return res;
		} catch(ex) {
			handleErrors(ex);
		}

	}
}

export default new ExerciseService();
