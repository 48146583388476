// Function to convert hex color to RGB
function hexToRgb(hex) {
	let bigint = parseInt(hex.slice(1), 16);
	let r = (bigint >> 16) & 255;
	let g = (bigint >> 8) & 255;
	let b = bigint & 255;
	return [r, g, b];
}

// Function to calculate brightness
function calculateBrightness(r, g, b) {
	return (r * 299 + g * 587 + b * 114) / 1000;
}

export function isColorDark(hex) {
	const rgb = hexToRgb(hex);
	// Calculate brightness
	const brightness = calculateBrightness(rgb[0], rgb[1], rgb[2]);
	// Set button color based on brightness (choose black for bright colors, white for dark colors)
	return brightness < 128;
}
