import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Button from 'react-bootstrap/Button';
import './FormCreationModal.css';
import { Typeahead } from 'react-bootstrap-typeahead';
import GridLoader from 'react-spinners/GridLoader';

const FormCreationModal = ({ show, onHide, onSubmit, onSuccess, formTemplate, initialState, title }) => {
	const [formState, setFormState] = useState(new Map());
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (initialState && typeof initialState === 'object') {
			setLoading(true);
			setFormState(new Map(Object.entries(initialState)));
			const timeout = setTimeout(() => {
				setLoading(false);
			}, 500);
			return () => clearTimeout(timeout);
		}
	}, [initialState]);


	const handleFormChange = (event) => {
		const { name, value } = event.target;
		const mapCopy = new Map(formState);
		mapCopy.set(name, value);
		setFormState(mapCopy);
	};
	const handleChangeTypeahead = (data, key) => {
		const mapCopy = new Map(formState);
		mapCopy.set(key, data);
		setFormState(mapCopy);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		setLoading(true);
		await onSubmit(formState);
		setLoading(false);
		closeHandler();
		onSuccess();
	};

	const closeHandler = () => {
		onHide();
	};

	return (
		<Modal animation={false} show={show} onHide={closeHandler} dialogClassName='form-creation'>
			<Modal.Header closeButton>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{formState.size > 0 && formTemplate &&
					(!loading ?
						<Form onSubmit={handleSubmit}>
							{Object.keys(formTemplate).map((key) => {
								const { type, label, options, labelKey } = formTemplate[key];
								return formState.get(key) && (type !== 'typeahead' ?
									<FloatingLabel key={key}
										controlId={key}
										label={label}
										className="mb-3"
									>
										{type === 'select' ?
											<Form.Select value={formState.get(key)} onChange={handleFormChange}>
												{
													options.map(opt => {
														return <option key={opt.value} value={opt.value}>{opt.label}</option>;
													})
												}
											</Form.Select>
											: <Form.Control type={type} placeholder={label} value={formState.get(key)} onChange={handleFormChange} />
										}
									</FloatingLabel> :
									<>
										<Form.Group className="w-100">
											<Typeahead
												multiple
												id={`typeahead${key}`}
												options={options.filter(opt => !formState.get(key)
													.some(v => v[labelKey] === opt[labelKey] && v._id === v._id))}
												placeholder={label}
												defaultSelected={formState.get(key)}
												labelKey={labelKey}
												onChange={(data) => handleChangeTypeahead(data, key)}
											/>
										</Form.Group>
									</>
								);
							}
							)}
						</Form>
						:
						<div className="d-flex justify-content-center">
							<GridLoader />
						</div>)
				}
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={closeHandler}>
					Cancelar
				</Button>
				<Button variant="primary" onClick={handleSubmit}>
					Crear
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default FormCreationModal;
