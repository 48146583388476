// restClient.js

import axios from 'axios';
import { refreshToken, getToken, logoutHandler } from './utils';

const byPassError = [400, 403, 404, 409];

// Create an axios instance
const axiosInstance = axios.create({
	baseURL: '/api',
	headers: {
		'Content-Type': 'application/json',
	},
});

// Function to set up interceptors with context dispatch
export const setupAxiosInterceptors = (ctxDispatch) => {
	axiosInstance.interceptors.request.use((config) => {
		const token = getToken();
		config.headers['Authorization'] = `Bearer ${token}`;
		return config;
	});

	let refreshTokenInProgress = false;
	let axiosQueue = [];

	const updateQueueTokens = (access_token) => {
		axiosQueue = axiosQueue.filter(callback => callback(access_token));
	};

	const addSubscriber = (callback) => {
		axiosQueue.push(callback);
	};

	axiosInstance.interceptors.response.use((response) => {
		return response;
	}, async (error) => {
		const originalRequest = error.config;
		if (originalRequest?.url && originalRequest?.url !== '/auth/refreshToken') {
			if (!byPassError.includes(error.response.status)) {
				if (error.response.status === 401 && !originalRequest._retry) {
					const retryOriginalRequest = new Promise((resolve) => {
						addSubscriber(access_token => {
							originalRequest.headers['Authorization'] = 'Bearer ' + access_token;
							resolve(axiosInstance(originalRequest));
						});
					});
					if (!refreshTokenInProgress) {
						originalRequest._retry = true;
						refreshTokenInProgress = true;
						const access_token = await refreshToken();
						refreshTokenInProgress = false;
						updateQueueTokens(access_token);
						axiosQueue = [];
					}
					return retryOriginalRequest;
				}
				await logoutHandler(ctxDispatch);
				return Promise.reject(error);
			}
			return Promise.reject(error);
		} else if (!originalRequest?.url  || (error.response.status === 401 || error.response.status === 500)) {
			await logoutHandler(ctxDispatch);
			return Promise.reject(error);
		}
	});
};

export default axiosInstance;
