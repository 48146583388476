import React from 'react';
import { FaPen } from 'react-icons/fa';
import './TextFloatingLabel.css';

export default function TextFloatingLabel({ label, data, className, edit }) {
	return (
		<div className={'text-floating-label p-0 px-2 ' + className}>
			<div className="text-floating-label-content d-flex flex-column">
				<div
					className={
						!data
							? 'd-flex justify-content-between float-label mb-4'
							: 'd-flex justify-content-between float-label'
					}
				>
					<span>{label}</span>
					{edit && edit instanceof Function && (
						<button className="pen-edit-method" onClick={edit}>
							<FaPen></FaPen>
						</button>
					)}
				</div>
				<div className="h-75 float-text">{data}</div>
			</div>
		</div>
	);
}
