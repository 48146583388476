import React from 'react';
import './EventCard.css';
import ProgressBar from 'react-bootstrap/ProgressBar';
import classnames from 'classnames';
import moment from 'moment';
import { IoCheckmarkSharp } from 'react-icons/io5';


export default function EventCard({ event, onSelect, selected, bookHandler, booked, cancelHandler, resultsHandler }) {
	const today = moment();
	const finished = today.isAfter(moment(event.eventStartDateTime));


	const AttendButton = ({ className = '' }) => {
		const eventHasCapacity = (Number(event.capacity) - Number(event.attendees) > 0);

		return <button disabled={!eventHasCapacity } className={classnames('attend-button', { [className]: className })}
			onClick={booked && !finished? cancelHandler : (!booked && !finished)? bookHandler:(booked && finished)? resultsHandler:''}>
			{(booked && !finished)? 'Cancelar' : (!booked && !finished)? 'Asistir':(booked && finished)? 'Cargar Resultados':''}
		</button>;
	};
	return (
		<div onClick={onSelect.bind(this, event)} className={classnames('class-card', { selected })}>
			<div className="class-data">

				<div className="time">
					<span>
						{event.eventStartTime} - {event.eventEndTime}
					</span>
				</div>
				<div className="name">
					<button onClick={onSelect.bind(this, event)}>
						{event.subject}
					</button>
				</div>
				<div className="coach d-md-none">
					{event.coach}
				</div>
				<div className='availability'>
					<div className='available-capacity'>
						{
							event.attendees === event.capacity ? 'No hay cupos disponibles' :
								`Cupos disponibles: ${event.capacity - event.attendees}`
						}
					</div>
					<div className="w-50 d-none d-md-block mx-auto">
						<ProgressBar now={100 - (event.attendees / event.capacity) * 100} label={`${event.attendees}/${event.capacity}`} visuallyHidden />
					</div>
					{finished?(<span className='text-center d-block d-md-none align-text-bottom'>Finalizado <IoCheckmarkSharp fixedWidth size="18px"/></span>):('')}
					<AttendButton className='d-block d-md-none' />
				</div>
			</div>
			<div className="class-actions d-none d-md-flex">
				{
					
				}
				{finished?(
					<span className='text-center d-inline-flex align-items-center justify-content-center'>
						Finalizado <IoCheckmarkSharp className='ms-1' fixedWidth size="18px"/>
					</span>):
					<AttendButton />
				}
				{
					event.coach && 
					<>
						<span className='coach mt-1'>Dictada por:  </span>
						<span> {event.coach}</span>
					</>
				}
			</div>
			<div className="class-image d-flex d-md-none">
				<div className="img">
					{
						event.wods &&
						<img src={event?.wods[0]?.image?.fileLink}
							alt="dummy-image" />
					}
				</div>
			</div>
		</div>
	);
}
