import userService from '../../services/user.service';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import React, { useReducer } from 'react';
import { toast } from 'react-toastify';
import { getError } from '../../utils';
import AuthLandingPage from './AuthLandingPage/AuthLandingPage';
import Col from 'react-bootstrap/Col';
import LoginPrompt from '../../components/LoginPrompt/LoginPrompt';

export default function ResetPassword() {
	const reducer = (state, action) => {
		switch (action.type) {
		case 'RESET_REQUEST':
			return { ...state, loading: true };
		case 'RESET_SUCCESS':
			return { ...state, product: action.payload, loading: false };
		case 'RESET_FAIL':
			return { ...state, loading: false, error: action.payload };
		default:
			return state;
		}
	};
	useReducer(reducer, {
		loading: true,
		error: ''
	});
	const navigate = useNavigate();

	async function resertPasswordHandler(email) {
		try {
			// dispatch({ type: "RESET_REQUEST" })
			await userService.forgotPassword(email);
			// dispatch({ type: "RESET_SUCCESS", payload: data });
			toast.success('Email Enviado');
			navigate('/');
		} catch (err) {
			toast.error(getError(err));
			// dispatch({ type: "RESET_FAIL" });
		}
	}

	return (
		<>
			<Helmet>
				<title>Resetear Contraseña</title>
			</Helmet>
			<AuthLandingPage >
				<Col xs={10} md={6} className='mt-4'>
					<LoginPrompt className="bg-transparent w-100"
						submitHandler={resertPasswordHandler}
						buttonCaption="Enviar"
						showReturn
						caption="Reiniciar contraseña" />
				</Col>
			</AuthLandingPage>
		</>
	);
}
