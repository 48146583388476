

export const sections = {
	home: {
		id: 'acceder',
		title: 'Acceder',
		hash: '',
		overlay: true,
		to: '/signin',
		image: 'https://gotabooking-qa.s3.sa-east-1.amazonaws.com/seccion1.jpeg',
		content: [
			{
				caption: 'entrena',
				tag: 'h1',
			},
			{
				caption: 'con',
				tag: 'h2',
			},
			{
				caption: 'nosotros',
				tag: 'h2',
			},
			{
				caption: 'llega a tu mejor versión',
				tag: 'h3',
			},
		],
		buttonCaption: 'acceder'
	},
	about: {
		id: 'crossfit',
		hash: '#crossfit',
		title: 'Crossfit',
		overlay: true,
		image: 'https://gotabooking-qa.s3.sa-east-1.amazonaws.com/seccion2.jpeg',
		content: [
			{
				caption: 'crossfit',
				tag: 'h1',
			},
			{
				caption: `CrossFit es una técnica de entrenamiento que conecta movimientos de diferentes disciplinas, tales como la halterofilia, el entrenamiento metabólico o el gimnástico.
                Consiste en acometer un programa de ejercicios (flexiones, tracción, etc), en un tiempo determinado y con un número definido de veces.
                Esta noción tiene como principio mantener intacta la motivación de los deportistas a largo plazo. Se puede realizar de manera individual o en grupo, y las sesiones suelen ser cortas, variadas y adaptables en función del nivel de cada participante.`,
				shortCaption: 'CrossFit es una técnica de entrenamiento que conecta movimientos de diferentes disciplinas, tales como la halterofilia, el entrenamiento metabólico o el gimnástico...',
				tag: 'p',
			}
		],
		linkCaption: 'ver más'

	},
	location: {
		id: 'instalaciones',
		hash: '#instalaciones',
		title : 'Instalaciones',
		overlay: true,
		image: 'https://gotabooking-qa.s3.sa-east-1.amazonaws.com/seccion3.jpeg',
		content: [
			{
				caption: 'box',
				tag: 'h1',
			},
			{
				caption: 'Un BOX, es el lugar acondicionado para entrenar Crossfit®. Los puedes encontrar en diferentes tamaños, disposiciones y diseños.',
				tag: 'p',
			}
		],
		linkCaption: 'ver más'
	},
	schedules: {
		id: 'horarios',
		hash: '#horarios',
		title: 'Horarios',
		overlay: true,
		image: 'https://gotabooking-qa.s3.sa-east-1.amazonaws.com/seccion4.jpeg',
		content: [
			{
				caption: 'horarios',
				tag: 'h1'
			},
			{
				caption: 'lorem ipsum',
				tag: 'p'
			}
		],
		linkCaption: 'ver más'
	}
}; 