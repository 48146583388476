import React from 'react';
import GridLoader from 'react-spinners/GridLoader';
import '../LoadingBox.css'; // import the CSS file

function LoadingTableBox({ loading }) {
	return (
		<div className='loading-table-box-container'>
			<GridLoader color='#764176' loading={loading} />
		</div>
	);
}

export default LoadingTableBox;
