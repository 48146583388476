import axios from '../../../restClient';


const BASE_URI = '/config';

class SettingsService {
	async get() {
		const res = await axios.get(`${BASE_URI}`);
		return res;
	}
	async checkShippingUsage() {
		const res = await axios.get(`${BASE_URI}/usesShipping`);
		return res;
	}
	//TODO: revisar endpoints, creo que hay algunos redundantes
	async getInitData() {
		const res = await axios.get(`${BASE_URI}/initData`);
		return res;
	}
	async getAdminEmails() {
		const res = await axios.get(`${BASE_URI}/adminEmails`);
		return res;
	}
	async createAdminEmail(email) {
		const res = await axios.post(`${BASE_URI}/adminEmails`, { email });
		return res;
	}
	async deleteAdminEmail(id) {
		const res = await axios.delete(`${BASE_URI}/adminEmails/${id}`);
		return res;
	}
	async getPublicData() {
		const res = await axios.get(`${BASE_URI}/public`);
		return res;
	}
	async edit(data) {
		const res = await axios.put(`${BASE_URI}`, data);
		return res;
	}
	async uploadLogo(formData) {
		const res = await axios.post(
			`${BASE_URI}/uploadSettingsImg`,
			formData,
			{headers : {
				'Content-Type': 'multipart/form-data',
			}}
		);
		return res;
	}
	async uploadIcon(formData) {
		const res = await axios.post(
			`${BASE_URI}/uploadSettingsIcon`,
			formData,
			{headers : {
				'Content-Type': 'multipart/form-data',
			}}
		);
		return res;
	}
	async verifyAdminEmail(token) {
		const res = await axios.put(`${BASE_URI}/verify-admin-email/${token}`);
		return res;
	}
}

export default new SettingsService();
