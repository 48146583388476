import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

function SearchBox({ value, onChange, submitHandler }) {
	const submit = (e) => {
		e.preventDefault();
		submitHandler();
	};

	const [isActiveSearch, setActive] = useState(false);

	const handleToggle = () => {
		setActive(!isActiveSearch);
	};

	return (
		<Form className="input-group searchBox" onSubmit={submit}>
			<div className="form-outline">
				<input
					placeholder="Buscar"
					className={
						isActiveSearch
							? 'd-lg-inline d-none  form-control search-input'
							: ' form-control search-input'
					}
					type="text"
					name="q"
					id="q"
					value = {value}
					onChange={(e) => onChange(e.target.value.replace(/[-/\\^$*+?.()|[\]{}]/g, ''))}
				></input>
			</div>
			<Button
				type="submit"
				onClick={handleToggle}
				className=" btn btnbtn-primary-search"
			>
				<i className="fas fa-search"></i>
			</Button>
		</Form>
	);
}

export default SearchBox;
