import moment from 'moment';

export const getDatesByFrequency = (repeatOption, event, toast, availableWeekDays, selectedDays) => {
	let dates = [];
	switch (repeatOption) {
	case 'none':
		dates.push(event.eventStartDateTime);
		break;
	case 'daily':
		dates = getDatesByWeekdays(event.eventStartDateTime, event.eventEndDateTime, availableWeekDays);
		break;
	case 'weekday':
		dates = getDatesByWeekdays(event.eventStartDateTime, event.eventEndDateTime, ['L', 'M', 'X', 'J', 'V']);
		break;
	case 'weekly':
		dates = getDatesByWeekdays(event.eventStartDateTime, event.eventEndDateTime, selectedDays);
		break;
	default:
		toast.error('Opción de repetición no válida');
	}
	return dates;
};
//Funcion para obtener los dias entre fechas
function getDatesByWeekdays(startDate, endDate, weekdays) {
	const weekdaysMap = {
		L: 1,
		M: 2,
		X: 3,
		J: 4,
		V: 5,
		S: 6,
		D: 0
	};
	const selectedWeekdays = weekdays.map(
		(weekday) => weekdaysMap[weekday.toUpperCase()]
	);
	const datesInRange = [];

	let currentDate = moment(startDate).startOf('day');
	const lastDate = moment(endDate).startOf('day');

	while (currentDate.isSameOrBefore(lastDate)) {
		if (selectedWeekdays.includes(currentDate.day())) {
			const dateToAdd = moment(currentDate)
				.hour(moment(startDate).hour())
				.minute(moment(startDate).minute())
				.toDate();
			datesInRange.push(dateToAdd);
		}
		currentDate.add(1, 'day');
	}

	return datesInRange;
}