import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useContext, useEffect } from 'react';
import { Store } from '../../../Store';
import { toast } from 'react-toastify';
import { getError } from '../../../utils';
import _auth from '../../../services/auth.service';
import Col from 'react-bootstrap/Col';
import LoginPrompt from '../../../components/LoginPrompt/LoginPrompt';
import './SignInScreen.css';
import AuthLandingPage from '../AuthLandingPage/AuthLandingPage';

export default function SignInScreen() {
	const navigate = useNavigate();
	const { search } = useLocation();
	const redirectInUrl = new URLSearchParams(search).get('redirect');
	const redirect = redirectInUrl ? redirectInUrl : '/';


	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;

	const isEmpty = (obj) => {
		return !obj || Object.keys(obj).length === 0;
	};

	const submitHandler = async (email, password) => {
		try {
			ctxDispatch({ type: 'SHOW_LOADING_OVERLAY' });
			const { data } = await _auth.login(email, password);
			ctxDispatch({ type: 'USER_SIGNIN', payload: data });
			localStorage.setItem('userInfo', JSON.stringify(data));
			localStorage.setItem('refreshToken', data.refreshToken);
			data.isAdmin ? navigate('/AdminScreen/stats') : navigate('/home');
		} catch (err) {
			console.error(err);
			toast.error(getError(err));
		} finally {
			ctxDispatch({ type: 'HIDE_LOADING_OVERLAY' });
		} 

	};

	useEffect(() => {
		if (userInfo) {
			navigate(redirect);
		}
		if (isEmpty(userInfo) || !userInfo) {
			ctxDispatch({ type: 'USER_SIGNOUT' });
		}
	}, [navigate, redirect, userInfo]);

	return <>
		<Helmet>
			<title>Iniciar sesión</title>
		</Helmet>
		<AuthLandingPage className='sign-in-screen'>
			<Col xs={10} md={6}>
				<LoginPrompt className="bg-transparent w-100"
					showPassword submitHandler={submitHandler}
					showForgotPassword
					showSignUp
					buttonCaption="Ingresar"
					caption="Ingresar" />
			</Col>
		</AuthLandingPage>
	</>;
}
