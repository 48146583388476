import React from 'react';
import { FaUsers, FaCog, FaChartBar } from 'react-icons/fa';
import { RiCoupon2Fill } from 'react-icons/ri';
import { BsFillFileEarmarkFill, BsFillCalendarEventFill } from 'react-icons/bs';
import { Link, useLocation } from 'react-router-dom';
import './AdminNavbarOptions.css';
import { FaDumbbell,FaCalendarWeek } from 'react-icons/fa';
import classnames from 'classnames';

export default function AdminNavbarOptions({ imageURL, companyName, handleCollapse }) {

	const { pathname } = useLocation();

	return (
		<div id="mobile-menu-admin">
			
			<div className="admin-company-logo d-flex justify-content-center w-100">
				<Link to="/AdminScreen/stats" className='d-inline-block h-100'>
					<img className="img-fluid h-100" src={imageURL} alt={`${companyName || ''} Logo`} />
				</Link>
			</div>
			<div className="nav-option">
				<Link to="/AdminScreen/stats" className={classnames('', { 'focused-option': pathname.includes('AdminScreen/stats') })}  onClick={handleCollapse}>
					<FaChartBar />
					<span>Estadísticas</span>
				</Link>
			</div>
			<div className="nav-option" onClick={handleCollapse}>
				<Link to="/AdminScreen/users" className={classnames('', { 'focused-option': pathname.includes('AdminScreen/users') })} >
					<FaUsers />
					<span>Usuarios</span>
				</Link>
			</div>

			<div className="nav-option">
				<Link to="/AdminScreen/membership-templates" className={classnames('', { 'focused-option': pathname.includes('AdminScreen/membership-templates') })}
				 onClick={handleCollapse}>
					<RiCoupon2Fill />
					<span>Cuponeras</span>
				</Link>
			</div>

			<div className="nav-option">
				<Link to="/AdminScreen/WODs" className={classnames('', { 'focused-option': pathname.includes('AdminScreen/WODs') })} onClick={handleCollapse}>
					<BsFillFileEarmarkFill />
					<span>WODs</span>
				</Link>
			</div>

			<div className="nav-option">
				<Link to="/AdminScreen/exercises" className={classnames('', { 'focused-option': pathname.includes('AdminScreen/exercises') })} onClick={handleCollapse}>
					<FaDumbbell />
					<span>Ejercicios</span>
				</Link>
			</div>
			<div className="nav-option">
				<Link to="/AdminScreen/weeklyTemplates" className={classnames('', { 'focused-option': pathname.includes('AdminScreen/weeklyTemplates') })} onClick={handleCollapse}>
					<FaCalendarWeek />
					<span>Calendario semanal</span>
				</Link>
			</div>
			<div className="nav-option">
				<Link to="/AdminScreen/eventList" className={classnames('', { 'focused-option': pathname.includes('AdminScreen/eventList') })}  onClick={handleCollapse}>
					<BsFillCalendarEventFill />
					<span>Clases</span>
				</Link>
			</div>
			<div className="nav-option">
				<Link to="/AdminScreen/Config" className={classnames('', { 'focused-option': pathname.includes('AdminScreen/Config') })} onClick={handleCollapse} >
					<FaCog />
					<span>Configuracion</span>
				</Link>
			</div>
		</div>
	);
}