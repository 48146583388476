
export default class Event {
	constructor({
		_id,
		eventStartDateTime,
		eventStartDate,
		eventStartTime,
		eventEndDateTime,
		eventEndDate,
		eventEndTime,
		eventLocation,
		subject,
		capacity,
		duration,
		isActive = true,
		attendees = 0,
		wods = [],
		coach
	}) {
		Object.assign(this, {
			_id,
			eventStartDateTime,
			eventStartDate,
			eventStartTime,
			eventEndDateTime,
			eventEndDate,
			eventEndTime,
			eventLocation,
			subject,
			capacity,
			duration,
			isActive,
			attendees,
			wods,
			coach
		});
	}
	isBasicDataValid() { 
		return this.eventLocation && this.subject && Number(this.capacity) > 0 && Number(this.duration) > 0 && this.coach;
	}
	isValid() {
		return (
			this.eventStartDateTime &&
			this.eventStartDate &&
			this.eventStartTime &&
			this.eventEndDateTime &&
			this.eventEndDate &&
			this.eventEndTime &&
			this.eventLocation &&
			this.subject &&
			this.capacity > 0 &&
			this.duration > 0 && 
			this.coach
		);
	}

	static createEmptyEvent() {
		return new Event({
			eventStartDateTime: null,
			eventStartDate: '',
			eventStartTime: '',
			eventEndDateTime: null,
			eventEndDate: '',
			eventEndTime: '',
			eventLocation: '',
			subject: '',
			capacity: 0,
			duration: 0,
			isActive: true,
			attendees: 0,
			coach: '',
		});
	}
    
}