import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './DeleteConfirmationModal.css';

export default function DeleteConfirmationModal({
	show,
	handleClose,
	itemType = '',
	handleDelete,
	bodyText = `Seguro desea eliminar este ${itemType}? Esta acción no se puede deshacer.`,
	itemData = null
}) {
	return (
		<div>
			<Modal
				show={show}
				onHide={handleClose}
				dialogClassName="modal-delete-item"
				aria-labelledby="modal-delete-item"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>Eliminar {itemType}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{bodyText}
					<br />
					<br />
		
					{itemData && <div>{itemData}</div>}

				</Modal.Body>
				<Modal.Footer>
					<div className="button-user-container">
						<Button variant="secondary" onClick={handleClose} className="modal-button ml-1">
            Cancelar
						</Button>
						<Button onClick={handleDelete} className="modal-button delete-button">
            Eliminar
						</Button>
					</div>
				</Modal.Footer>
			</Modal>
		</div>
	);
}
