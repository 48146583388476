import { useReducer, useEffect } from 'react';
import { showErrorToast } from '../utils';
import { useLocation } from 'react-router-dom';

import _exercises from '../services/exercise.service';

export default function useExercises() {
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	const [reducerState, dispatch] =
        useReducer(reducer, {
        	exercises: [],
        	loading: true,
        	exerciseCount: 0,
        	error: ''
        });
	const { exercises, loading, exerciseCount, error } = reducerState;

	//#region REQUESTS
	const fetchExercises = async () => {
		try {
			const {data} = await _exercises.list(searchParams);
			dispatch({ type: actions.FETCH_SUCCESS, payload: data });
		} catch(ex) {
			showErrorToast(ex);
		}
	};
	const reloadExercises = async () => {
		dispatch({ type: actions.FETCH_REQUEST });
	};
	//#endregion
	useEffect(() => {
		if(loading) {
			fetchExercises();
		}
	}, [loading]);

	return {exercises, loading, exerciseCount, error, reloadExercises };
}
const reducer = (state, action) => {
	switch (action.type) {
	case actions.FETCH_REQUEST:
		return { ...state, loading: true };
	case actions.FETCH_SUCCESS:
		return {
			...state,
			exercises: action.payload.exercises,
			exerciseCount: action.payload.exerciseCount,
			loading: false,
		};
	case actions.FETCH_FAIL:
		return { ...state, loading: false, error: action.payload };
	case actions.DELETE_REQUEST:
		return { ...state, loadingDelete: true, successDelete: false };
	case actions.DELETE_SUCCESS:
		return {
			...state,
			loadingDelete: false,
			successDelete: true
		};
	case actions.DELETE_FAIL:
		return { ...state, loadingDelete: false, successDelete: false };

	case actions.DELETE_RESET:
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};
const actions = {
	FETCH_REQUEST: 'FETCH_REQUEST',
	FETCH_SUCCESS: 'FETCH_SUCCESS',
	FETCH_FAIL: 'FETCH_FAIL',
	DELETE_REQUEST: 'DELETE_REQUEST',
	DELETE_SUCCESS: 'DELETE_SUCCESS',
	DELETE_FAIL: 'DELETE_FAIL',
	DELETE_RESET: 'DELETE_RESET'
};