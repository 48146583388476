import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Row, Col, Table, Card, Dropdown, SplitButton, DropdownButton } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { getError, maxitemsPerPage } from '../../utils';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import Pagination from '../../components/Pagination/Pagination';
import classnames from 'classnames';
import eventAttendeeService from '../../services/eventAttendee.service';
import { DateTime } from 'luxon';
import {
	BsTrash,
	BsPencilSquare,
} from 'react-icons/bs';
import './UserBookingList.css';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return {
			...state,
			userBooking: action.payload,
			loading: false,
			itemQuantity: action.payload.length,
		};
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

export default function UserBookingList({ className }) {
	const [{ loading, error, itemQuantity, userBooking }, dispatch] = useReducer(reducer, {
		loading: true,
		error: '',
		itemQuantity: 0,
		userBooking: [],
	});

	// eslint-disable-next-line no-unused-vars
	const { state } = useContext(Store);
	const { id } = useParams();
	const navigate = useNavigate();
	const { search } = useLocation();
	const sp = new URLSearchParams(search);

	const bookingType = sp.get('bookingType') || 'all';
	const types = ['pasadas', 'futuras'];

	const [filterOptions, setFilterOptions] = useState({
		bookingType: bookingType,
	});

	const handleConceptInputChange = (value) => {
		setFilterOptions((prev) => ({
			...prev,
			bookingType: value,
		}));

		navigate(`/AdminScreen/users/details/${id}`);
	};

	// Cambiar página en la paginación
	// eslint-disable-next-line no-unused-vars
	const [currentPage, setCurrentPage] = useState(1);
	const indexOfLastPost = currentPage * maxitemsPerPage;
	const indexOfFirstPost = indexOfLastPost - maxitemsPerPage;
	let currentPosts = userBooking.slice(indexOfFirstPost, indexOfLastPost);

	const onPageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const data = await eventAttendeeService.getAllEventsByUserId(id);

				const today = DateTime.local().startOf('day');
				const filteredData =
					filterOptions.bookingType === 'futuras'
						? data.filter((booking) => DateTime.fromISO(booking.eventStartDate) >= today)
						: filterOptions.bookingType === 'pasadas'
							? data.filter((booking) => DateTime.fromISO(booking.eventStartDate) < today)
							: data;

				dispatch({ type: 'FETCH_SUCCESS', payload: filteredData });
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
			}
		};

		fetchData();
	}, [id, filterOptions.bookingType]);


	const myTitle = (
		<h1 className="mb-1 btn-sm custom text-align-center">
			{filterOptions.bookingType === 'all'
				? 'Todas las Clases'
				: filterOptions.bookingType[0]?.toUpperCase() + filterOptions.bookingType.slice(1)}
		</h1>
	);

	return loading ? (
		<LoadingBox></LoadingBox>
	) : error ? (
		<MessageBox variant="danger">{error}</MessageBox>
	) : (
		<>
			<Helmet>
				<title>Lista de Inscripciones</title>
			</Helmet>

			<div className={classnames('user-booking-list tableResponsive', { [className]: className })}>
				<Row>
					<Col>
						<div>
							<SplitButton
								className='btn title-dropdown text-align-start dropwdown-menu p-0 split-custom'
								style={{ width: 'fit-content' }}
								align="start"
								title={myTitle}
								drop='down'
							>
								<Dropdown.Item className="text-align-center" onClick={() => handleConceptInputChange('all')} eventKey="all">
									Todas
								</Dropdown.Item>
								{types.map((type, index) => (
									<Dropdown.Item
										className="text-align-center"
										key={index}
										onClick={() => handleConceptInputChange(type)}
										eventKey={type}
									>
										{type[0].toUpperCase() + type.slice(1)}
									</Dropdown.Item>
								))}
							</SplitButton>
						</div>
					</Col>
				</Row>
				<Card>
					<Table bordered hover responsive size="sm">
						<thead>
							<tr>
								<th className="col-md-2">Día evento</th>
								<th className="col-md-2">Horario</th>
								<th>Nombre</th>
								<th className="col-md-1">Profesor</th>
								<th className="col-1">Opciones</th>
							</tr>
						</thead>
						{currentPosts.map((booking) => (
							<tbody key={booking._id}>
								<tr className="align-items-center">
									<td>{booking?.event?.eventStartDate}</td>
									<td>
										{booking?.event?.eventStartTime} - {booking?.event?.eventEndTime}
									</td>
									<td>{booking?.event?.subject}</td>
									<td>{booking?.event?.location}</td>
									<td>
										<DropdownButton id="optionsLists" drop="start" title="">
											<Dropdown.Item
												eventKey="1"
											//onClick={() => editUserBookingHandler(booking)}
											>
												<BsPencilSquare />
												Editar
											</Dropdown.Item>
											<Dropdown.Item
												eventKey="2"
											//onClick={() => toggleUserBookingHandler(booking)}
											>
												<BsTrash />
												Eliminar
											</Dropdown.Item>
										</DropdownButton>
									</td>
								</tr>
							</tbody>
						))}
					</Table>
				</Card>
				<Pagination
					className="pagination-bar"
					totalCount={itemQuantity}
					onPageChange={onPageChange}
					currentPage={currentPage}
					pageSize={maxitemsPerPage}
				/>
			</div>
		</>
	);
}
