import React, { useState } from 'react';
import { Modal, Button, Form, InputGroup, Row, Col } from 'react-bootstrap';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';

const EditUserModal = ({ showModal, handleClose, user, onSave }) => {
	const [name, setName] = useState(user?.name || '');
	const [email, setEmail] = useState(user?.email || '');
	const [password, setPassword] = useState('');
	const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber || '');
	const [passwordType, setPasswordType] = useState('password');

	const togglePassword = () => {
		setPasswordType(passwordType === 'password' ? 'text' : 'password');
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		onSave({ name, email, password });
		handleClose();
	};

	return (
		<Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
			<Modal.Header closeButton>
				<Modal.Title>Editar Usuario</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={handleSubmit}>
					<Form.Group className="mb-3" controlId="name">
						<Form.Label>Nombre:</Form.Label>
						<Form.Control
							type="text"
							required
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
					</Form.Group>
					<Row className="mb-3">
						<Col md={6}>
							<Form.Group controlId="email">
								<Form.Label>E-mail</Form.Label>
								<Form.Control
									type="email"
									required
									value={email}
									onChange={(e) => setEmail(e.target.value.toLowerCase())}
								/>
							</Form.Group>
						</Col>
						<Col md={6}>
							<Form.Group controlId="phoneNumber">
								<Form.Label>Número de Teléfono</Form.Label>
								<Form.Control
									type="text"
									value={phoneNumber}
									onChange={(e) => setPhoneNumber(e.target.value)}
								/>
							</Form.Group>
						</Col>
					</Row>
					<Form.Label>Contraseña</Form.Label>
					<InputGroup className="mb-3">
						<Form.Control
							type={passwordType}
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
						<Button
							variant="btn btn--secondary"
							onClick={togglePassword}
						>
							{passwordType === 'password' ? (
								<BsFillEyeSlashFill />
							) : (
								<BsFillEyeFill />
							)}
						</Button>
					</InputGroup>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
                    Cancelar
						</Button>
						<Button type="submit" variant="primary">
                    Guardar Cambios
						</Button>
					</Modal.Footer>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default EditUserModal;