import React, { useContext, useEffect, useState }  from 'react';
import SlidingTabs from '../../../../components/SlidingTabs/SlidingTabs';
import './UserDetails.css';
import UserMembershipsList from '../../../UserMemberships/UserMembershipList/UserMembershipList';
import UserBookingList from '../../../UserBookingList/UserBookingList';
import UserDebtList from '../../../AdminDebts/UserDebtsList';
import UserBenchmarksList from '../../../AdminBenchmarks/UserBenchmarksList';
import UserBasicData from '../UserBasicData/UserBasicData';
import {  useNavigate, useParams } from 'react-router-dom';
import _user from '../../../../services/user.service';
import { toast } from 'react-toastify';
import { Store } from '../../../../Store';
import UserRms from '../../../../components/UserRMs/UserRms';

const options = [
	{ option: 'cuponeras', cmp: UserMembershipsList},
	{ option: 'inscripciones', cmp : UserBookingList},
	{ option: 'deudas', cmp: UserDebtList },
	{option: 'marcas', cmp: UserRms},
	{option: 'Benchmarks', cmp: UserBenchmarksList},
];


export default function UserDetails() {
	const {id} = useParams();
	const navigate = useNavigate();
	const [user, setUser] = useState();
	const {state, dispatch} = useContext(Store);

	const fetchUserData = async () => {
		try {
			dispatch({ type: 'SHOW_LOADING_OVERLAY' });
			const u = await _user.getUserById(id);
			setUser(u);
		} catch(ex) {
			console.error(ex);
			toast.error('Error al obtener los datos del usuario');
			navigate('/AdminScreen/stats');
		} finally {
			dispatch({type: 'HIDE_LOADING_OVERLAY'});
		}
	};


	useEffect(() => {
		if(id) {
			fetchUserData();
		}
	},[id]);

	return <div id='user-details-screen' className='admin-con'>
		<UserBasicData loading = {state.loadingOverlay} user = {user}/>
		<SlidingTabs options={options} />
	</div>;
}
