import React from 'react';
import { Form, FloatingLabel, Row, Col } from 'react-bootstrap';
import { FaRegTrashAlt } from 'react-icons/fa';
import { Typeahead } from 'react-bootstrap-typeahead';
import { wodsCategories, wodsTypes } from '../../../../utils/lists';
import './WodDataForm.css';
import { GoPlus } from 'react-icons/go';
import './WodDataForm.css';
import classnames from 'classnames';

export default function WodDataForm({ wod, setWodValue, exercisesList, handleExerciseDetailChange, handleRemoveExercise, exercises,
	handleAddExercise, validated }) {

	return <div className='wod-data-form d-flex flex-wrap'>
		<Form validated={validated} noValidate={true} onSubmit={(e) => e.preventDefault()} className='basic-data-form row'>
			<FloatingLabel className='col-md-6' controlId="nombre"
				label="Nombres">
				<Form.Control value={wod.name}
					onChange={(e) => setWodValue('name', e.target.value)} required />
			</FloatingLabel>
			<FloatingLabel controlId="timeCap" label="Tiempo" className='col-md-6'>
				<Form.Control type="number" min={0} value={wod.timeCap}
					onChange={(e) => setWodValue('timeCap', e.target.value)} required />
			</FloatingLabel>
			<FloatingLabel controlId="rondas" label="Rondas" className='col-md-6'>
				<Form.Control type="number" min={0}
					value={wod.rounds}
					onChange={(e) => setWodValue('rounds', e.target.value)} required />
			</FloatingLabel>
			<FloatingLabel label="Tipo de WOD" className='col-md-6'>
				<label >Tipo de WOD</label>
				<Typeahead
					id="type-typeahead"
					onChange={(selected) => setWodValue('type', selected)}
					options={wodsTypes}
					selected={wod.type}
					required
				/>
			</FloatingLabel>
			{wod.type[0]?.toLowerCase() === 'tabata' && <FloatingLabel controlId="rondas" label="Tiempo entre rondas" className='col-md-6'>
				<Form.Control type="number" min={0}
					value={wod.restBetweenRounds}
					onChange={(e) => setWodValue('restBetweenRounds', e.target.value)} required />
			</FloatingLabel>
			}

			<FloatingLabel label="Categoria" className="mb-3 col-lg-6" controlId="category">
				<label>Categoria</label>
				<Typeahead
					id="category-typeahead"
					onChange={(selected) => setWodValue('category', selected)}
					options={wodsCategories}
					selected={wod.category}
					required
				/>
			</FloatingLabel>
			<FloatingLabel controlId="descripcion" label="Descripcion">
				<Form.Control value={wod.description} as="textarea" rows={2}
					onChange={(e) => setWodValue('description', e.target.value)} required />
			</FloatingLabel>
		</Form>
		<div className='w-100 -3'>
			<hr />
			{exercisesList?.length > 0 &&
				<Row className="mb-2 labels w-100">
					<Col className='text-center h-fit-content'>
						Ejercicio
					</Col>
					<Col className='text-center h-fit-content'>
						Reps
					</Col>
					<Col xs={4} className='text-center h-fit-content'>
						Peso
					</Col>
					<button disabled className='text-danger w-fit-content invisible'>
						<FaRegTrashAlt />
					</button>
				</Row>}
			{
				exercisesList.map((item, index) => (
					<Form key={index} noValidate validated={validated} className='w-100'>
						<Row className="exercise align-items-center w-100">
							<Form.Group className="col">
								<Typeahead
									isValid={item.validationErrors.exercise === ''}
									id="exercise-search"
									options={exercises}

									placeholder="Seleccione un ejercicio"
									labelKey="name"
									onChange={(selected) => handleExerciseDetailChange(index, 'exercise', selected)}
								/>

								<div className={classnames('invalid-feedback', { visible: validated && !item.exercise })}>
									El ejercicio es obligatorio
								</div>

							</Form.Group>
							<Form.Group className="col">
								<Form.Control
									type="number"
									value={item.reps}
									onChange={(e) =>
										handleExerciseDetailChange(index, 'reps', e.target.value)
									}
									required
								/>

								<div className={classnames('invalid-feedback', { visible: validated && Number(item.reps) < 0 })}>
									Se requiere un número no negativo
								</div>

							</Form.Group>
							<Form.Group className="col">
								<Form.Control
									type="number"
									value={item.weight}
									onChange={(e) =>
										handleExerciseDetailChange(index, 'weight', e.target.value)
									}
									required
								/>

								<div className={classnames('invalid-feedback', { visible: validated && Number(item.weight) < 0 })}>
									Se requiere un número no negativo
								</div>

							</Form.Group>
							<button onClick={() => handleRemoveExercise(index)} className='text-danger pb-4 w-fit-content'>
								<FaRegTrashAlt />
							</button>
						</Row>
					</Form>
				))
			}
			<div className='d-flex mt-2'>
				<button className='add-ex-btn h-fit-content p-2' onClick={handleAddExercise}>
					Agregar ejercicio
					<GoPlus />
				</button>
			</div>
		</div>

	</div>;

}
