import React, { useContext, useEffect, useRef, useState } from 'react';
import { Modal, Button ,ProgressBar } from 'react-bootstrap';
import WeeklyTemplate from '../../../classes/WeeklyTemplate';
import _weeklyTemplates from '../../../services/weeklyTemplate.service';
import _classes from '../../../services/class.service';
import { toast } from 'react-toastify';
import { getError } from '../../../utils';
import moment from 'moment';
import useClassesByWeeklyTemplate from '../../../hooks/useClassesByWeeklyTemplate';
import { WeeklyCalendar } from '../../../components/WeeklyTemplateCalendar/WeeklyTemplateCalendar';
import ClassData from '../../../components/ClassData/ClassData';
import WeeklyTemplateBasicData from './WeeklyTemplateBasicData';
import classnames from 'classnames';
import { Store } from '../../../Store';

export default function WeeklyTemplatesEditor({ show, close, success, weeklyTemplateParam = {} }) {
	const [weeklyTemplate, setWeeklyTemplate] = useState(weeklyTemplateParam ? WeeklyTemplate.fromObject(weeklyTemplateParam) : WeeklyTemplate.createEmpty());	
	const {dispatch, state} = useContext(Store);
	const {companyData} = state;
	const [classes, setClasses] = useState([]);
	const basicDataRef = useRef(null);

	const [validated, setValidated] = useState(false);
	useEffect(() => {
		if (weeklyTemplateParam) {
			setWeeklyTemplate(WeeklyTemplate.fromObject(weeklyTemplateParam));
		}
	}, [weeklyTemplateParam]);

	useEffect(() => {
		setValidated(false);
	}, [weeklyTemplate]);

	const { classes: initialClasses } = useClassesByWeeklyTemplate(weeklyTemplateParam);

	useEffect(() => {
		if(initialClasses?.length) {
			setClasses(initialClasses.map(c => ({ ...c, validationErrors: {}})));
		}
	}, [initialClasses, weeklyTemplateParam]);

	const [currentStep, setCurrentStep] = useState(1);
	const totalSteps = 3;

	const daysOfWeek = [
		{ label: 'L', value: 'L' },
		{ label: 'M', value: 'M' },
		{ label: 'X', value: 'X' },
		{ label: 'J', value: 'J' },
		{ label: 'V', value: 'V' },
		{ label: 'S', value: 'S' },
		{ label: 'D', value: 'D' },
	];


	const addClass = () => {
		setClasses([
			...classes,
			{
				name: '',
				initTime: '',
				endTime: '',
				duration: 0,
				weekDays: [],
				_id: '',
				validationErrors: {}
			},
		]);
	};

	const removeClass = (index) => {
		const newClasses = [...classes];
		newClasses.splice(index, 1);
		setClasses(newClasses);
	};

	/**
	 * 
	 * @param {string} timeString 
	 * @returns {moment.Moment}
	 */
	const timeStringToDateTime = (timeString) => {
		return moment(`${moment().format('YYYY-MM-DD')} ${timeString}`, 'YYYY-MM-DD HH:mm');
	};

	const handleClassChange = (index, field, value) => {
		const updatedClasses = [...classes];
		updatedClasses[index] = { ...updatedClasses[index], [field]: value };
		setClasses(updatedClasses);
	};
	
	

	const toggleDay = (index, day) => {
		const updatedClasses = [...classes];
		const weekDays = updatedClasses[index].weekDays;
		if (weekDays.includes(day)) {
			updatedClasses[index].weekDays = weekDays.filter(d => d !== day);
		} else {
			updatedClasses[index].weekDays.push(day);
		}
		setClasses(updatedClasses);
	};

	const onClose = () => {
		setWeeklyTemplate(WeeklyTemplate.createEmpty());
		setClasses([]);
		setCurrentStep(1);
		close();
	};
	const checkScheduleConflicts = (classes) => {
		for (let i = 0; i < classes.length; i++) {
			const { weekDays: newClassDays, initTime: newInitTime, endTime: newEndTime, name: newClassName } = classes[i];
			for (let j = i + 1; j < classes.length; j++) {
				const { weekDays: existingClassDays, initTime: existingInitTime, endTime: existingEndTime, name: existingClassName } = classes[j];

				const sameDays = newClassDays.some(day => existingClassDays.includes(day));

				if (sameDays) {
					const newStartTime = moment(newInitTime, 'HH:mm');
					const newEndingTime = moment(newEndTime, 'HH:mm');
					const existingStartTime = moment(existingInitTime, 'HH:mm');
					const existingEndingTime = moment(existingEndTime, 'HH:mm');

					if (newStartTime.isBefore(existingEndingTime) && newEndingTime.isAfter(existingStartTime)) {
						return {
							valid: false,
							message: `Error. Existe un conflicto con los horarios y/o días de ${newClassName} con ${existingClassName}.`
						};
					}
				}
			}
		}
		return { valid: true };
	};
	  

	const handleCreate = async () => {
		try {
			dispatch({ type: 'SHOW_LOADING_OVERLAY' });
			const validationErrors = checkScheduleConflicts(classes); 
			if (!validationErrors.valid) {
				toast.error(validationErrors.message);
				return; 
			  }
			await _weeklyTemplates.create(weeklyTemplate.name, weeklyTemplate.dateFrom, weeklyTemplate.dateTo, weeklyTemplate.isActive, classes );
			toast.success('Calendario y clases creadas con éxito.');
			onSuccess();
		} catch (ex) {
			console.error(ex);
			console.error(getError(ex));
			toast.error(getError(ex));
		} finally {
			dispatch({ type: 'HIDE_LOADING_OVERLAY' });
		}
	};
	const handleEdit = async () => {
		try {
			dispatch({type: 'SHOW_LOADING_OVERLAY'});
			const validation = checkScheduleConflicts(classes);
			if (!validation.valid) {
				toast.error(validation.message);
				return;
			  }
			const updatedWeeklyTemplate = await _weeklyTemplates.edit(weeklyTemplate._id, weeklyTemplate.name,
				 weeklyTemplate.dateFrom, weeklyTemplate.dateTo, weeklyTemplate.isActive, classes);
			await _classes.edit(classes, updatedWeeklyTemplate.data);
			toast.success('Cambios guardados con éxito.');
			onSuccess();
		} catch (ex) {
			console.error(ex);
			toast.error(getError(ex));
		} finally {
			dispatch({ type: 'HIDE_LOADING_OVERLAY' });
		}
	};
	const handleNext = () => {
		if (currentStep < totalSteps) {
			if (currentStep === 1) {
				setValidated(true);
				if(basicDataRef.current.checkValidity()) {
					setCurrentStep(currentStep + 1);
				}
			}
			if (currentStep === 2) {
				const validation = checkScheduleConflicts(classes);
				const validatedClasses = getClassesFormErrors();
				const classesHaveFormErrors = validatedClasses.some(cls => Object.keys(cls.validationErrors).length);

				setClasses(validatedClasses);
				if (classes.length && validation.valid && !classesHaveFormErrors) {
					setCurrentStep(currentStep + 1);
				} else if (!validation.valid) {
					toast.error(validation.message);
				} else if(!classes.length) {
					toast.error('Debe añadir al menos una clase.');
				}
			}
		} else {
			if (weeklyTemplateParam?._id) {
				handleEdit();
			} else {
				handleCreate();
			}
		}
	};
	const getClassesFormErrors = () => {
		const updatedClasses = [...classes];
		const newClasses = updatedClasses.map(cls => {
			const { name, initTime, endTime,  weekDays, duration } = cls;
			const validationErrors = {};
			if (!name) {
				validationErrors.name = 'El nombre de la clase es requerido.';
			}
			if (!initTime) {
				validationErrors.initTime = 'La hora de inicio es requerida.';
			}
			if (!parseInt(duration)) {
				validationErrors.duration = 'La duración es requerida.';
			}
			if (timeStringToDateTime(initTime).isBefore(timeStringToDateTime(companyData.openingTime))) {
				validationErrors.initTime = 'La hora de inicio no puede ser antes de la hora de apertura.';
			}
			if (timeStringToDateTime(initTime).isAfter(timeStringToDateTime(companyData.closingTime))) {
				validationErrors.initTime = 'La hora de inicio no puede ser después de la hora de cierre.';
			}
			if (timeStringToDateTime(initTime).isAfter(timeStringToDateTime(endTime))) {
				validationErrors.endTime = 'La hora de fin debe ser después de la hora de inicio.';
			}
			if(parseInt(duration) <= 0) {
				validationErrors.duration = 'La duración debe ser mayor a 0.';
			}
			if (!weekDays.length) {
				validationErrors.weekDays = 'Debe seleccionar al menos un día de la semana.';
			}
			return { ...cls, validationErrors };
		});
		return newClasses;
	};

	const handleBack = () => {
		if (currentStep > 1) {
			setCurrentStep(currentStep - 1);
		}
	};
	

	const setWeeklyTemplateProperty = (field, value) => {
		setWeeklyTemplate(WeeklyTemplate.fromObject({ ...weeklyTemplate, [field]: value }));
	};


	const onSuccess = () => {
		success();
		onClose();
	};

	return (
		<Modal show={show} onHide={onClose} animation={false} dialogClassName='exercise-editor' size="xl">
			<Modal.Header closeButton>
				<Modal.Title>{weeklyTemplateParam?.name ? 'Editar' : 'Crear'} template</Modal.Title>
			</Modal.Header>
			<Modal.Body className='justify-content-center'>
				<ProgressBar
					now={(currentStep / totalSteps) * 100}
					label={`Paso ${currentStep} de ${totalSteps}`}
					className="mb-4"
				/>
				{currentStep === 1 && (
					<WeeklyTemplateBasicData validated = {validated} setValidated = {setValidated} weeklyTemplate = {weeklyTemplate}
					 setWeeklyTemplateProperty= {setWeeklyTemplateProperty}
						ref={basicDataRef} />
				)}
				{/* Step 2: Define Classes */}
				{currentStep === 2 && (
					<>
						<h4>Defina Horarios</h4>
						{classes.map((cls, index) => {
							return <ClassData openingTime = {companyData.openingTime} closingTime={companyData.closingTime} index = {index}
							 	key={index} handleClassChange={handleClassChange}
								removeClass={removeClass} cls={cls} daysOfWeek={daysOfWeek} toggleDay={toggleDay}
								dateParser={timeStringToDateTime}/>;
						})}
						<Button variant='success' onClick={addClass}>
                                Añadir Clase
						</Button>
					</>
				)}
				{currentStep === 3 && (
					<>
						<h1>Confirmar calendario</h1>
						<WeeklyCalendar openingTime = {companyData.openingTime} closingTime = {companyData.closingTime} classes={classes}/>
					</>
				)}
			</Modal.Body>

			<Modal.Footer className={classnames('d-flex', { 'justify-content-between': currentStep > 1,  'justify-content-end': currentStep === 1 })}>
				{currentStep > 1 && (
					<button className='admin-button dark' onClick={handleBack}>
                            Atrás
					</button>
				)}
				<button
					className='m-0 admin-button'
					type='button'
					onClick={handleNext}
				>
					{currentStep < totalSteps ? 'Siguiente' : 'Guardar'}
				</button>
			</Modal.Footer>
		</Modal>
	);
};