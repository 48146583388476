import React from 'react';
import './AuthLandingPage.css';
import Row from 'react-bootstrap/Row';
import classnames from 'classnames';

const EMPTY_FUNCTION = <> </>;

export default function AuthLandingPage({ children = EMPTY_FUNCTION, className = '' }) {
	return <>

		<div className={classnames('auth-landing-page', { [className]: className })}>
			<Row className="justify-content-center align-items-center w-100">
				{
					children
				}
			</Row>
			<footer id="admin-footer">
				<p className="copyrightFooter m-0">©Copyright {new Date().getFullYear()} Gotabooking <br /></p>
				<div className="logo-container">
					Powered by
					<a className=''
						href="https://gotasoft.io/"
						title="Powered by Gotasoft"
					>
						<img src="/logoFooter.svg" className="img-fluid  m-0" alt="Gotasoft logo" />
					</a>
				</div>
			</footer>
		</div >
		<figure className="landing-page-picture" >
			<img src="landing_page_pic.jpeg" alt="Landing page picture" />
		</figure>

		<div className="background-gradient">

		</div>

	</>;
}
