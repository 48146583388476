import LoadingBox from '../../components/LoadingBox';
import React, { useReducer, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {  Col, Container, Row, Card, Stack } from 'react-bootstrap';
import { getError } from '../../utils';
import wodService from '../../services/wod.service';
import { useNavigate, useParams } from 'react-router-dom';
import { BsArrowLeftCircle } from 'react-icons/bs';
import Image from 'react-bootstrap/Image';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, WOD: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

function EditWOD() {
	const params = useParams();
	const navigate = useNavigate();

	const { id } = params;
	const [{ loading, WOD }, dispatch] = useReducer(reducer, {
		WOD: {},
		loading: true,
		error: ''
	});
	const [image, setImage] = useState('');

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await wodService.getWodById(id);
				dispatch({ type: 'FETCH_SUCCESS', payload: result });
				setImage(result.image.fileLink);
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
		};
		fetchData();
	}, [id]);

	return (
		<div>
			{loading && <LoadingBox></LoadingBox>}
			<Container className="large-container">
				<Helmet>
					<title>WOD guardado</title>
				</Helmet>
				<Container fluid>
					<Row className="vh-100 d-flex justify-content-center align-items-center">
						<Col md={8} lg={6} xs={12}>
							<Card className="shadow">
								<Card.Body>
									<div className="mb-3 mt-md-4">
										<h2 className="fw-bold mb-4 text-uppercase text-align-center ">
											WOD guardado
										</h2>
										<div className="mb-3">
											<Container>
												<Row>
													<Col>Nombre:</Col>
													<Col>{WOD.name}</Col>
												</Row>
												<Row>
													<Col>Descripcion:</Col>
													<Col>{WOD.description}</Col>
												</Row>
											</Container>
											<Row>
												<Image className="mb-3" src={image} />
											</Row>

											<Stack direction="horizontal" gap={10}>
												<button className='admin-button  fit-content' onClick={() => navigate(-1)}>
													<BsArrowLeftCircle /> Atras
												</button>
											</Stack>
										</div>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</Container>
		</div>
	);
}
export default EditWOD;
