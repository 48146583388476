import React, { useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './NavbarOptions.css';
import NavbarToggler from '../../NavbarToggler/NavbarToggler';
import classnames from 'classnames';
import { sections } from './loggedSections';
import { Store } from '../../../Store';
import { logoutHandler } from '../../../utils';

export default function NavbarOptions({ handleCollapse, expand }) {
	const location = useLocation();
	const navigate = useNavigate();
	const { dispatch } = useContext(Store);
	
	const logout = async () => {
		dispatch({ type: 'SHOW_LOADING_OVERLAY' });
		await logoutHandler(dispatch);
		navigate('/');
		dispatch({ type: 'HIDE_LOADING_OVERLAY' });
		dispatch({ type: 'CLOSE_NAV' });

	};

	return <div className="client-navbar-options">
		<div className="nav-options">
			{
				sections.map(opt => {
					return <div className="nav-option" key={opt.title}>
						<Link to={opt.pathname} className={classnames({ 'focused': location.pathname === opt.pathname })}
							onClick={handleCollapse}>
							<span>{opt.title}</span>
						</Link>
					</div>;
				})
			}
			<div className="nav-option">
				<a onClick={logout}>
					<span>salir</span>
				</a>
			</div>
		</div>
		{expand &&
			<div>
				<NavbarToggler onClick={expand} />
			</div>
		}
	</div >;
}
