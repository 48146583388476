
import { useNavigate, useParams } from 'react-router-dom';
import React, { useContext, useEffect } from 'react';
import { Store } from '../../Store';
import { toast } from 'react-toastify';
import { getError } from '../../utils';
import _user from '../../services/user.service';

export default function VerifyUser() {
	const navigate = useNavigate();
	const params = useParams();
	const { token } = params;
	const {state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;

	useEffect(() => {
		if(!userInfo) {
			verifyUser();
		} else {
			navigate('/');
		}
	}, []);

	const verifyUser = async () => {
		try {
			const { data } = await _user.verify(token);
			ctxDispatch({ type: 'USER_SIGNIN', payload: data });
			navigate('/');
			toast.success('Usuario verificado correctamente');
		} catch (err) {
			toast.error(getError(err));
		}
	};

	return <div></div>;
}
