import LoadingBox from '../../components/LoadingBox';
import React, { useContext, useReducer, useEffect } from 'react';
import { Store } from '../../Store';
import { Helmet } from 'react-helmet-async';
import { Button, Col, Container, Row, Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getError } from '../../utils';
import eventAttendeeService from '../../services/eventAttendee.service';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, event: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

function userEventDetail() {
	const params = useParams();
	const navigate = useNavigate();

	const { id } = params;
	const [{ loading, loadingAttendee, event }, dispatch] = useReducer(reducer, {
		event: {},
		users: {},
		userAttende: {},
		loading: true,
		loadingAttendee: true,
		error: ''
	});
	const { state } = useContext(Store);
	const { userInfo } = state;
	function disponibility() {
		if (event.capacity === event.users) {
			return 'bg-warning';
		} else if (event.capacity > event.users) {
			return 'bg-success';
		} else {
			return 'bg-danger';
		}
	}

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const {data} = await eventAttendeeService.previewUserEvent(
					id,
					userInfo._id
				);
				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (error) {
				console.error(error);
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
				toast.error('Error al cargar el evento. Intente mas tarde.');
				navigate('/agenda');
			}
		};
		fetchData();
	}, [id]);

	async function suscribeEventHandler() {
		if (window.confirm('Seguro desea inscribirse a este evento?')) {
			try {
				const eventAttendeeData = {
					user: userInfo._id,
					event: id
				};
				await eventAttendeeService.bookEvent(eventAttendeeData);
				toast.success(`Te inscribiste a el evento ${event.subject}`);
				navigate('/agenda');
			} catch (err) {
				toast.error('No lograste inscribirte al evento. Intentelo mas tarde.');
			}
		}
	}

	async function unsuscribeEventHandler() {
		if (window.confirm('Seguro desea desinscribirse a este evento?')) {
			try {
				await eventAttendeeService.cancelBooking(
					userInfo._id,
					event._id
				);
				toast.success(`Te desinscribiste a el evento ${event.subject}`);
				navigate('/agenda');
			} catch (err) {
				toast.error('No lograste desinscribiste al evento. Intentelo mas tarde.');
			}
		}
	}
	return (
		<div>
			{loading && loadingAttendee && <LoadingBox></LoadingBox>}
			{/* CONFIRM MODAL / BAR LOADER / ERRORS*/}
			<Container className="large-container">
				<Helmet>
					<title>Detalles {`${event.subject}`}</title>
				</Helmet>
				<Container fluid>
					<Row className="vh-100 d-flex justify-content-center align-items-center">
						<Col md={8} lg={6} xs={12}>
							<Card className="shadow">
								<Card.Body>
									<div className="mb-3 mt-md-4">
										<h2 className="fw-bold mb-4 text-uppercase text-align-center">
											{event.subject}
										</h2>
										<div className="mb-3">
											<div className="mb-3">
												<Container>
													<Row>
														<Col>Locacion: </Col>
														<Col>{event.eventLocation}</Col>
													</Row>
													<Row>
														<Col>Fecha de inicio: </Col>
														<Col>
															{moment(event.eventStartDateTime).format('DD/MM/YYYY HH:mm')}
														</Col>
													</Row>
													<Row>
														<Col>Fecha de fin: </Col>
														<Col>
															{moment(event.eventEndDateTime).format('DD/MM/YYYY HH:mm')}
														</Col>
													</Row>
													<Row>
														<Col
															className={`text-white rounded-3 ${event.isActive ? 'bg-success' : 'bg-danger'}`}
														>
															{event.isActive
																? 'El evento se encuentra activo para anotarse'
																: 'El evento no se encuentra activo para anotarse'}
														</Col>
													</Row>
													<p></p>
													<Row>
														<Container className={`text-white rounded-3 ${disponibility()}`}>
															<Row>
																<Col>
																	<h1 className="fw-bold mb-4 text-uppercase text-align-center">
																		Cupos
																	</h1>
																</Col>
															</Row>
															<Row>
																<Col>
																	<Row>
																		<h2
																			className={`fw-bold mb-4 text-uppercase text-align-center ${disponibility()}`}
																		>
																			Capacidad disponible: {event.users}/{event.capacity}
																		</h2>
																		<h2>lista de inscriptos: {event.users}</h2>
																	</Row>
																</Col>
															</Row>
														</Container>
													</Row>
													<Row>
														{event.isActive &&
															event.users < event.capacity &&
															!event.registered && (
															<Button
																type="submit"
																className="mx-auto"
																onClick={suscribeEventHandler}
															>
																	Inscribirse
															</Button>
														)}
														{event.registered && (
															<Button
																type="submit"
																className="mx-auto"
																onClick={unsuscribeEventHandler}
															>
																Desinscribirse
															</Button>
														)}
													</Row>
												</Container>
											</div>
										</div>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</Container>
		</div>
	);
}
export default userEventDetail;
