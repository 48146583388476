import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { StoreProvider } from './Store';
import { ColorProvider } from './components/ColorContext';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
	<React.StrictMode>
		<StoreProvider>
			<HelmetProvider>
				<ColorProvider>
					<App />
				</ColorProvider>
			</HelmetProvider>
		</StoreProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.info))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
