/* eslint-disable no-unused-vars */

import eventsService from '../../services/eventAttendee.service';
import React, { useEffect, useReducer, useState, useContext } from 'react';
import { Store } from '../../Store';
import { useNavigate } from 'react-router-dom';
import { IoChevronBack } from 'react-icons/io5';
import { getError } from '../../utils';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './ProfileMembershipList.css';
import useQueryFilters from '../../hooks/useQueryFilters';
import { DateTime } from 'luxon';
import { MdCalendarToday } from 'react-icons/md'; 
import ProfileEventCard from '../../components/EventCard/ProfileEventCard';
import _eventAttendee from '../../services/eventAttendee.service';
import { toast } from 'react-toastify';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return {
			...state,
			events: action.payload,
			loading: false,
			itemQuantity: action.payload.length
		};
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};


//TODO: make dynamic
const classCategoriesDummy = [
	{
		key: 'all',
		label: 'Todas'
	},
	{
		key: 'open-box',
		label: 'Open Box'
	},
	{
		key: 'open-outdoor',
		label: 'Open Outdoor'
	}
];


function ProfileEventsList() {
	const { state } = useContext(Store);
	const {userInfo} = state;
	const initFields = {
		sort: JSON.stringify({ eventStartDateTime: 1 }),
		eventStartDateTime: DateTime.now().toISODate().toString(),
	};

	const [
		{ events, successDelete, loading },
		dispatch
	] = useReducer(reducer, {
		events: [],
		loading: true,
		itemQuantity: 0,
		error: ''
	});
	const navigate = useNavigate();


	const [filteredEvents, setFilteredEvents] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState(classCategoriesDummy[0]);
	const [activeTab, setActiveTab] = useState('upcoming');
	const [selectedEvent,setSelectedEvent]=useState();

	const [selectedDate, setSelectedDate] = useState(DateTime.now());
	const [selectedMonth, setSelectedMonth] = useState(DateTime.now().toFormat('MMMM'));
	const [selectedYear, setSelectedYear] = useState(DateTime.now().year);

	const { searchParams, search, submitFilters, fields } = useQueryFilters(
		{ baseUrl: '/profile/my-classes', resizeWidth: 768, paginate: false, initFields }
	);


	const fetchData = async (idUser) => {
		try {
			dispatch({ type: 'FETCH_REQUEST' });			
			const data = await eventsService.getAllEventsByUserId(idUser);
			dispatch({ type: 'FETCH_SUCCESS', payload: data });
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
		}
	};
	
	useEffect(() => {
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		} else {
			fetchData(userInfo._id);
		}
	}
	, [successDelete, userInfo]);

	const [showCancelBookingModal, setShowCancelBookingModal] = useState(false);


	const openCancelReservationModal = (selectedEvent) => {
		setShowCancelBookingModal(true);
		setSelectedEvent(selectedEvent);
	};

	const goToResultsForm = (eventId) => {
		navigate(`/results/${eventId}`);
	};

	const handleCancelReservation = async (selectedEvent) => {
		try {
			await _eventAttendee.cancelBooking(userInfo._id,selectedEvent);
			toast.success('Reserva cancelada con éxito');
			await fetchData(userInfo._id);
		}
		catch (ex) {
			toast.error(getError(ex));
			console.error(ex);
		}
		finally {
			setShowCancelBookingModal(false);
		}
	};

	const renderEvents = () => {
		const now = DateTime.local(); 

		if (activeTab === 'upcoming') {
			const upcomingEvents = events.filter(event => {
				const eventStartDateTime = DateTime.fromISO(event.event.eventStartDateTime);
				return eventStartDateTime >= now;
			});


			upcomingEvents.sort((a, b) => {
				const dateA = DateTime.fromISO(a.event.eventStartDateTime);
				const dateB = DateTime.fromISO(b.event.eventStartDateTime);
				if (dateA.hasSame(now, 'day')) {
					return -1; 
				} else if (dateB.hasSame(now, 'day')) {
					return 1;
				} else {
					return dateA - dateB;
				}
			});

			const eventsByMonthYear = upcomingEvents.reduce((acc, event) => {
				const eventEndDateTime = DateTime.fromISO(event.event.eventEndDateTime);
				const monthYearKey = eventEndDateTime.toFormat('MMMM yyyy');

				if (!acc[monthYearKey]) {
					acc[monthYearKey] = [];
				}
				acc[monthYearKey].push(event);
				return acc;
			}, {});

			return Object.keys(eventsByMonthYear).map(monthYear => (
				<div key={monthYear}>
					<p className='upcoming-month-label'>{monthYear}</p>
					{eventsByMonthYear[monthYear].map((event, index) => (
						<ProfileEventCard
							key={index}
							event={event.event}
							cancelHandler={() => openCancelReservationModal(event.event._id)}
							handleResults={() => goToResultsForm(event.event._id)} 
							booked={false}
						/>
					))}
				</div>
			));
		} else {
			const pastEvents = events.filter(event => {
				const eventEndDateTime = DateTime.fromISO(event.event.eventEndDateTime);
				return eventEndDateTime < now;
			});

			return pastEvents.map((event, index) => (
				<ProfileEventCard
					key={index}
					event={event.event}
					cancelHandler={() => openCancelReservationModal(event.event._id)}
					handleResults={() => goToResultsForm(event.event._id)} 
					booked={false}
				/>
			));
		}
	};


	useEffect(() => {
		if (events.length > 0) {
			const today = DateTime.local();
			const filtered = events.filter(event => {
				const eventEndDate = DateTime.fromFormat(event.event.eventEndDate, 'dd/M/yyyy');
				if (!eventEndDate.isValid) {
					console.error('Invalid Date:', event.event.eventEndDate);
					return false; 
				}

				 const isInSelectedMonthAndYear = (eventEndDate.month === DateTime.fromFormat(selectedMonth, 'MMMM').month) &&
				 (eventEndDate.year === selectedYear);

				  return activeTab === 'upcoming' 
					? (eventEndDate >= today && isInSelectedMonthAndYear) 
					: (eventEndDate < today && isInSelectedMonthAndYear); 
			});

			setFilteredEvents(filtered);
		}
	}, [events, activeTab, selectedMonth, selectedYear]);
	
	const handleTabClick = (tab) => {
		setActiveTab(tab);
	};

	const onSelectDate = (date) => {
		if (date) {
			setSelectedDate(DateTime.fromJSDate(date)); 
			setSelectedMonth(DateTime.fromJSDate(date).toFormat('MMMM')); 
			setSelectedYear(DateTime.fromJSDate(date).year); 
			setShowDatePicker(false);
		} else {
			console.error('Invalid date selected');
		}
	};
 
	const [showDatePicker, setShowDatePicker] = useState(false); 

	const handleCalendarClick = () => {
		setShowDatePicker(!showDatePicker);
	};

	return (

		<div id="user-details-screen" className="profile-events-list">

			<div className="profile-events-header">
				<span onClick={() => navigate(-1)} className="back-button">
					<IoChevronBack />
				</span>
				<span>Mis Clases</span>
			</div>
			<ConfirmationModal
				show={showCancelBookingModal}
				handleClose={() => setShowCancelBookingModal(false)}
				title='Cancelar reserva'
				description='¿Estás seguro de que deseas cancelar esta clase?'
				handleConfirm={() => handleCancelReservation(selectedEvent)}
			/>
			{/* Tabs */}
			<div>
				<div className='profile-events-tab-container'>
					<div className="profile-events-tabs">
						<button 
							className={`profile-events-tab ${activeTab === 'upcoming' ? 'active' : ''}`} 
							onClick={() => handleTabClick('upcoming')}
						>
					Proximas Clases
						</button>
						<button 
							className={`profile-events-tab ${activeTab === 'history' ? 'active' : ''}`} 
							onClick={() => handleTabClick('history')}
						>
					Historial
						</button>
					</div>
					<div className="profile-events-tabs">
						{classCategoriesDummy.map((category, index) => {
							const isSelected = selectedCategory.key === category.key;			
							return <button key={index}
								className={`profile-events-tab  event-selected ${isSelected ? 'active' : ''}`}
								onClick={() => setSelectedCategory(category)} >
								{category.label}
							</button>;
						})}

					</div>

				</div>
			</div>
			<div className="line"></div>

			{activeTab === 'history' ? (
				<div className="month-picker-container" >
					<>
						<div className="month-label" onClick={handleCalendarClick}>{selectedMonth}	
						</div>
						<MdCalendarToday className='calendar-icon' size={20} onClick={handleCalendarClick}/>
					</>
					{showDatePicker && (
						<div className="date-picker-container">
							<DatePicker 
								selected={selectedDate.toJSDate()} 
								onChange={(date) => {
									if (date) {
										onSelectDate(date); 
									} else {
										console.error('Invalid date selected');
									}
								}} 
								inline 
								showMonthYearPicker 
								dateFormat="MMMM yyyy" 
							/>
						</div>
					)}
		
				</div>
			):null
			}
		
		
			{renderEvents()}
		
		</div>
	);
}
	
export default ProfileEventsList;
