import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Store } from '../Store';
import {
	Row,
	Col} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ProfileMembershipsList from './UserMemberships/UserMembershipList/UserMembershipList';
import UserBookingList from './UserBookingList/UserBookingList';
import UserRms from '../components/UserRMs/UserRms';
import UserBasicData from './Admin/Users/UserBasicData/UserBasicData';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import CardTabs from '../components/CardTab/CardTab';
import './ProfileScreen.css';


const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, events: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, error: action.payload };
	case 'FETCH_REQUEST_COUNT':
		return { ...state };
	case 'FETCH_SUCCESS_COUNT':
		return { ...state, bookingsLeft: action.payload };
	case 'FETCH_FAIL_COUNT':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

const options = [
	{option: 'MIS CLASES',
		image: '../images/misClases.png',
		cmp: UserBookingList, navTag: 'my-classes'},
	{ option: 'CUPONERAS', image: '../images/cuponeras.png', cmp: ProfileMembershipsList,  navTag: 'my-cuponeras'},
	{ option: 'DATOS', image: '../images/datos.png', cmp: UserRms,  navTag: 'my-results'}
];

// const sections = [
// 	{ title: 'CUPONERA', content: '3 clases disponibles\nvto 22/11' },
// 	{ title: 'HOY', content: 'WOD / 18:00 A 19:00\nDictada por: Fernando Fernandez' },
// 	{ title: 'PENDIENTES', content: 'Cargar resultados de WOD 19/10/24' },
// ];



function ProfileScreen() {
	const { state } = useContext(Store);
	const { userInfo } = state;

	const [{loading, error }] = useReducer(
		reducer,
		{
			loading: false,
			error: '',
			events: [],
		}
	);

	const navigate = useNavigate();
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 576); 
		};

		handleResize();
		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);
	
	useEffect(() => {
		if(!userInfo) {
			navigate('/');
		}
	},[userInfo]);




	return (
	 <div id='user-details-screen' className='admin-con perfil-con'>
			{isMobile ? 
				<div className="screen-locator">
					<span>Mi Perfil</span>
				</div> :null
			}
			<Row className='content'>
				<Col className="text-center p-4">
					<Row className='user-det-con'>
						<UserBasicData style={{ height: '4rem' }} loading = {state.loadingOverlay} user = {userInfo} clientView={true}/>
					</Row>
					<div className="line"></div>
					{isMobile ? <div>
						<h4> HOLA {userInfo.name}! </h4>
					</div> : null}
					<Row className='mt-1'>
						<Col md={12} className='col-cardtab'>
							<section style={{width: '100%'}}>
								{loading ? (
									<LoadingBox />
								) : error ? (
									<MessageBox variant="danger">{error}</MessageBox>
								) : (

									<CardTabs options={options} />
				
								)}
							</section>
						</Col>

					</Row>
				</Col>
				{/* {!isMobile ? (
			 <Col md={3} className="bg-light p-0">
						<ProfileNews sections={sections} />
					</Col>
				) : null} */}
			</Row>
		</div>

	);
}
export default ProfileScreen;
