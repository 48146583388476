import React, { useEffect, useReducer, useState } from 'react';
import bookingTemplateService from '../../../services/membershipTemplate.service';
import userBookingService from '../../../services/membership.service';
import {
	Modal,
	Form,
	InputGroup
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { getError } from '../../../utils';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './UserMembershipAssigner.css';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST_DATA':
		return { ...state, loadingData: true };
	case 'FETCH_SUCCESS_DATA':
		return { ...state, bookingtemplates: action.payload, loadingData: false };
	case 'FETCH_FAIL_DATA':
		return { ...state, loading: false, error: action.payload };
	case 'FETCH_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_FAIL':
		return { ...state, loadingFetch: false, errorFetch: action.payload };
	case 'CREATE_REQUEST':
		return { ...state, loadingCreate: true };
	case 'CREATE_SUCCESS':
		return {
			...state,
			loadingCreate: false
		};
	case 'CREATE_FAIL':
		return { ...state, loadingCreate: false };
	default:
		return state;
	}
};
function UserMembershipAssigner({ show, handleClose, onSuccess }) {
	const params = useParams();
	const { id } = params;
	const [loading, setLoading] = useState(false);
	const [bookingtemplate, setBookingTemplate] = useState('');
	const [startingDate, setStartingDate] = useState(null);
	const [paymentConfirmed, setPaymentConfirmed] = useState(false);

	const [
		{ bookingtemplates },
		dispatch
	] = useReducer(reducer, {
		bookingtemplates: [],
		loadingFetch: true,
		loadingCreate: false,
		loadingData: true,
		error: ''
	});
	
	const fetchData = async () => {
		setLoading(true);
		try {
			dispatch({ type: 'FETCH_REQUEST_DATA' });
			const data = await bookingTemplateService.getActiveMembershipTemplates();
			dispatch({ type: 'FETCH_SUCCESS_DATA', payload: data });
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL_DATA', payload: getError(err) });
		} finally {
			setLoading(false);
		}
	};
	
	useEffect(() => {
		if(show) {
			fetchData();
		} else {
			setBookingTemplate('');
			setStartingDate(null);
			setPaymentConfirmed(false);
		}
	}, [show]);



	const assignMembership = async () => {
		setLoading(true);
		if (bookingtemplate === '' && bookingtemplates.length > 0) {
			setBookingTemplate(bookingtemplates[0]._id);
		}
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			const data = {
				user: id,
				template:
					bookingtemplate === '' ? bookingtemplates[0]._id : bookingtemplate,
				startingDate: startingDate,
				paymentConfirmed: paymentConfirmed // Agregar el estado del pago confirmado
			};
			await userBookingService.create(data);
			toast.success('Cuponera asignada');
			onSuccess();
		} catch (ex) {
			toast.error(getError(ex));
		} finally {
			setLoading(false);
		}
	};
	return (
		<Modal show={show} onHide={handleClose} dialogClassName='user-membership-assigner'>
			<Modal.Header closeButton> <Modal.Title>Asignar Cuponera</Modal.Title> </Modal.Header>
			<Modal.Body>
				<Form onSubmit={(e) => e.preventDefault()}>
					<Form.Group className="mb-3" controlid="bookingtemplate">
						<Form.Label>Cuponera:</Form.Label>
						<Form.Select
							required
							onChange={(e) => setBookingTemplate(e.target.value)}
						>
							{bookingtemplates.map((booking) => (
								<option value={booking._id} key={booking._id}>
									{booking.name} | ${booking.price}
									{' | '}
									{booking.totalBookings} clases por {booking.daysLast} dias
								</option>
							))}
						</Form.Select>
					</Form.Group>
					<Form.Group>
						<Form.Label>Fecha de inicio para cuponera</Form.Label>
						<InputGroup
							className="d-flex justify-content-start align-items-center"
							controlid="startingDate"
						>
							<DatePicker
								containerClassName="form-control"
								className="form-control"
								onChange={setStartingDate}
								selected={startingDate}
								value={startingDate}
								clearIcon={null}
								minDate={new Date()}
								required={true}
								dateFormat="dd-MM-yyyy"
							/>
						</InputGroup>
						<br />
					</Form.Group>
					<Form.Group>
						<Form.Label>Marcar como pago</Form.Label>	
						<Form.Check
							required
							type='switch'
							checked={paymentConfirmed}
							onChange={(e) => setPaymentConfirmed(e.target.checked)}	
						/>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer className='justify-content-around'>
				{!loading && <>
					<button className='fit-content admin-button dark' onClick={handleClose}>Cerrar</button>
					<button type='submit' className='fit-content admin-button' onClick={assignMembership}>
						Asignar cuponera
					</button>
				</>
				}
			</Modal.Footer>
		</Modal>
	);
}
export default UserMembershipAssigner;
