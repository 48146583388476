import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import DayButton from './Day/Day';
import './DatePicker.css';
import classnames from 'classnames';


DatePicker.propTypes = {
	firstDay: PropTypes.instanceOf(DateTime),
	selectedDate: PropTypes.instanceOf(DateTime),
	onSelectDate: PropTypes.func.isRequired,
	numberOfDays: PropTypes.number,
	className: PropTypes.string
};
export function DatePicker({ startingDate = DateTime.local({locale:'es'}), selectedDate = DateTime.now(), onSelectDate, numberOfDays = 7, className }) {

	const SHOW_START_OF_WEEK = true; //hacer parametrizable esto eventualmente

	const firstDay = SHOW_START_OF_WEEK ? selectedDate.startOf('week') : startingDate;

	const dateArray = Array.from({ length: numberOfDays }, (_, i) => firstDay.plus({ days: i }));
    
	return <div className={classnames('datepicker',{[className]: className})}>
		<h2>
			{DateTime.now().toFormat('LLLL', {locale: 'es'})}
		</h2>
		<div className="datepicker-days">
			{dateArray.map((date, index) => {
				return <DayButton locale = "es" selected = {selectedDate.day === date.day} key={index} date={date} onSelect={onSelectDate} />;
			})}
		</div>
	</div>;

}



