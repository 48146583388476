import axios from '../restClient';

const BASE_URI = '/auth';

class AuthService {
	async login(email, password) {
		const uri = BASE_URI + '/login';
		const res = await axios.post(uri, { email, password });
		return res;
	}
	async logout(refreshToken, id) {
		const uri = BASE_URI + '/logout';
		const res = await axios.post(uri, { refreshToken, id });
		return res;
	}

	// async login(username, password) {
	// 	const response = await restClient.post('/authenticate', {
	// 		username,
	// 		password
	// 	});
	// 	if (response.data && response.data.token) {
	// 		localStorage.setItem(
	// 			'user',
	// 			JSON.stringify({
	// 				user: response.data.user,
	// 				tabsForProfile: response.data.tabsForProfile
	// 			})
	// 		);
	// 		localStorage.setItem('token', JSON.stringify(response.data.token));
	// 		localStorage.setItem(
	// 			'refreshToken',
	// 			JSON.stringify(response.data.refreshToken)
	// 		);
	// 	}
	// 	return response.data;
	// }

	// logout() {
	// 	localStorage.removeItem('user');
	// 	localStorage.removeItem('token');
	// 	localStorage.removeItem('refreshToken');
	// }

	// async register(email, password, firstName, lastName, role, profile) {
	// 	const response = await axios.post('/register', {
	// 		email,
	// 		password,
	// 		firstName,
	// 		lastName,
	// 		role,
	// 		profile
	// 	});
	// 	return response;
	// }

	// async refreshToken(oldToken) {
	// 	const response = await axios.post('/refreshToken', { oldToken });
	// 	localStorage.removeItem('token');
	// 	localStorage.removeItem('refreshToken');
	// 	const { token, newRefreshToken } = response.data;
	// 	localStorage.setItem('token', JSON.stringify(token));
	// 	localStorage.setItem('refreshToken', JSON.stringify(newRefreshToken));
	// 	return token;
	// }
}
export default new AuthService();
