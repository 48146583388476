
export const sections = [
	{
		id: 'perfil',
		title: 'Mi perfil',
		pathname: '/profile',
	},
	{
		id: 'agenda',
		title: 'Agenda',
		pathname: '/events',
	},
	{
		id: 'cuponeras',
		title: 'Cuponeras',
		pathname: '/cart/cartHomeScreen',
	}
];
