import restClient from '../restClient';
import authHeader from './auth-header';
import { handleErrors } from './common.service';

const MEMBERSHIP_API_URL = '/memberships';

class MembershipService {

	async list() {
		try {
			const response = await restClient.get(MEMBERSHIP_API_URL, {
				headers: authHeader()
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async create(membershipData) {
		try {
			const response = await restClient.post(
				MEMBERSHIP_API_URL,
				membershipData,
				{
					headers: authHeader()
				}
			);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async createMemberships(order) {
		const userId = order.user;
		const isPaid = order.isPaid;

		try {
			const memberships = order.orderItems.map(item => ({
				user: userId,
				template: item._id, 
				paymentConfirmed: isPaid,
				paymentMethodChargePercent: order.paymentMethod?.chargePercent || 0,
			}));

			const responses = await Promise.all(memberships.map(membershipData => 
				restClient.post(
					MEMBERSHIP_API_URL,
					membershipData,
					{
						headers: authHeader()
					}
				)));

			return responses.map(response => response.data); 
		} catch (error) {
			handleErrors(error);
		}
	}
	


	async edit(membershipId, updatedData) {
		try {
			const response = await restClient.put(
				`${MEMBERSHIP_API_URL}/${membershipId}`,
				updatedData,
				{
					headers: authHeader()
				}
			);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async findById(membershipId) {
		try {
			const response = await restClient.get(
				`${MEMBERSHIP_API_URL}/${membershipId}`,
				{
					headers: authHeader()
				}
			);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async findByUserId(userId) {
		try {
			const response = await restClient.get(
				`${MEMBERSHIP_API_URL}/user/${userId}`,
				{
					headers: authHeader()
				}
			);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async delete(userBookingId) {
		try {
			const response = await restClient.delete(
				`${MEMBERSHIP_API_URL}/${userBookingId}`,
				{
					headers: authHeader()
				}
			);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async toggleMembershipStatus(userBookingId) {
		try {
			const response = await restClient.put(
				`${MEMBERSHIP_API_URL}/toggleactive/${userBookingId}`,
				{},
				{
					headers: authHeader()
				}
			);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async getTotalAvailableBookings(userId) {
		try {
			const response = await restClient.get(
				`${MEMBERSHIP_API_URL}/user/bookingsleft/${userId}`,
				{
					headers: authHeader()
				}
			);
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}
}

export default new MembershipService();
