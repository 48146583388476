import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';
import MessageBox from '../../components/MessageBox/MessageBox.jsx';
import { Store } from '../../../../Store.js';
import './checkoutLoginScreen.css';
import CartSummary from './CartSummary/CartSummary.jsx';
import CheckoutLoginPrompt from '../../components/CheckoutLoginPrompt/CheckoutLoginPrompt.jsx';
import Modal from 'react-bootstrap/Modal';
import CheckoutNavigator from '../../components/CheckoutNavigator/CheckoutNavigator.jsx';

function CheckoutLoginScreen() {
	const navigate = useNavigate();

	const { state} = useContext(Store);
	const {
		cart: { cartItems },
	} = state;
	const [isResolutionLgOrLess, setIsResolutionLgOrLess] = useState(false);
	const [showLoginModal, setShowLoginModal] = useState(false);

	const handleResize = () => {
		setIsResolutionLgOrLess(window.innerWidth <= 992);
	};
	const toggleLoginModal = () => setShowLoginModal(show => !show);

	useEffect(() => {
		if(!cartItems.length) {
			navigate('/');
		}
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return (
		<>
			<div id="cart-screen" className='package-screen'>
				<Helmet>
					<title>Resumen del Pedido</title>
				</Helmet>
				{cartItems.length === 0 ? (
					<MessageBox cols={12}>
            Tu carrito esta vacio. <Link to="/">A comprar!</Link>
					</MessageBox>
				) : (
					<>
						<Modal dialogClassName="login-container-dialog" onHide={toggleLoginModal} show={showLoginModal}>
							<Modal.Header closeButton></Modal.Header>
							<CheckoutLoginPrompt />
						</Modal>
						{!isResolutionLgOrLess && <CheckoutLoginPrompt />}
						<CartSummary canDeleteItems submitHandler={isResolutionLgOrLess ? toggleLoginModal : null} canChangeQuantity />
						<CheckoutNavigator activeStep={1} />
					</>
				)}
			</div>
		</>
	);
}
export default CheckoutLoginScreen;
