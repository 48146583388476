import React from 'react';
import './LoadingOverlay.css';
import Spinner from 'react-bootstrap/Spinner';
import {createPortal} from 'react-dom';

export default function LoadingOverlay() {
	return (<>
		{
			createPortal(
				<div className="loading-overlay">
					<Spinner animation="border" />
				</div>,
				document.body
			)
		}
	</>
	);
}
