import paymentService from '../../services/payment.service';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Row,
	Col,
	Button,
	Table,
	Dropdown,
	DropdownButton,
	Card
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';
import { getError, maxitemsPerPage } from '../../utils';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import Pagination from '../../components/Pagination/Pagination';
import { BsPlusCircle, BsTrash, BsFillBackspaceFill } from 'react-icons/bs';
import { FaFileInvoiceDollar } from 'react-icons/fa';
import moment from 'moment';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return {
			...state,
			payments: action.payload,
			loading: false,
			itemQuantity: action.payload.length
		};
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

function PaymentsList() {
	const [{ loading, error, payments, successDelete, itemQuantity }, dispatch] =
		useReducer(reducer, {
			payments: [],
			loading: true,
			error: '',
			itemQuantity: 0
		});

	const { state } = useContext(Store);
	const { userInfo } = state;
	const navigate = useNavigate();

	//pagination
	//pagination
	const [currentPage, setCurrentPage] = useState(1);
	const indexOfLastPost = currentPage * maxitemsPerPage;
	const indexOfFirstPost = indexOfLastPost - maxitemsPerPage;
	let currentPosts = payments.slice(indexOfFirstPost, indexOfLastPost);
	//change page
	const onPageChange = (pageNumber) => {
		setCurrentPage(pageNumber);

		currentPosts = payments.slice(indexOfFirstPost, indexOfLastPost);
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const data = await paymentService.getAllPayments();
				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
			}
		};
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		} else {
			fetchData();
		}
	}, [successDelete, userInfo.token]);

	async function deletePaymentHandler(payment) {
		if (
			window.confirm(
				'Seguro desea eliminar el Pago? si lo hace se actualizara la deuda a pendiente'
			)
		) {
			try {
				dispatch({ type: 'DELETE_REQUEST' });
				await paymentService.deletePayment(payment._id);
				dispatch({ type: 'DELETE_SUCCESS' });
				toast.success('Pago eliminado');
			} catch (err) {
				toast.error(getError(err));
				dispatch({ type: 'DELETE_FAIL' });
			}
		}
	}

	async function newPaymentHandler() {
		navigate('/AdminScreen/debts/newpayment');
	}

	return loading ? (
		<LoadingBox></LoadingBox>
	) : error ? (
		<MessageBox variant="danger">{error}</MessageBox>
	) : (
		<div>
			<Helmet>
				<title>Lista de Pagos</title>
			</Helmet>

			<div className="container admin-con">
				<div className="tableResponsive">
					<Row style={{ margin: '5% 0 2.5%' }}>
						<Col>
							<h1 className="text-right">
								<FaFileInvoiceDollar></FaFileInvoiceDollar>Pagos
							</h1>
						</Col>
						<Col
							style={{
								display: 'flex',
								justifyContent: 'flex-end'
							}}
						>
							<Button
								className="btn btn-dark m-1 fixed-right"
								value="Crear Pago"
								onClick={() => newPaymentHandler()}
							>
								<span>
									<BsPlusCircle /> Registrar Pago
								</span>
							</Button>

							<Button
								className="btn btn-dark m-1 fixed-left"
								value="Volver"
								onClick={() => navigate(-1)}
							>
								<span>
									<BsFillBackspaceFill /> Volver
								</span>
							</Button>
						</Col>
					</Row>
					{loading ? (
						<LoadingBox></LoadingBox>
					) : error ? (
						<MessageBox variant="danger">{error}</MessageBox>
					) : (
						<Card>
							<Table bordered hover responsive size="sm">
								<thead>
									<tr>
										<th className="col-md-2">
											<span>Monto</span>
										</th>
										<th className="col-md-2">Fecha</th>
										<th>Concepto de Deuda</th>
										<th className="col-1">Opciones</th>
									</tr>
								</thead>
								{payments
									? currentPosts.map((payment) => (
										<tbody key={payment._id}>
											<tr
												className="align-items-center table-order"
												id="data"
												key={payment._id}
											>
												<td className="col-md-2">${payment.amount}</td>
												<td className="col-md-1">
													{moment(payment.date).format('DD-MM-YYYY hh:mm a')}
												</td>
												{payment.debt && payment.debt.userbooking ? (
													<td className="col-md-2">
														{payment.debt.userbooking.template.name}
													</td>
												) : (
													<td></td>
												)}
												<td>
													<DropdownButton id="optionsLists" drop="start" title="">
														{/*                 <Dropdown.Item eventKey="0" onClick={() => detailsUserHandler(user)}><BsFileEarmarkRichtext />Ver Detalles</Dropdown.Item>
                        <Dropdown.Item eventKey="1" onClick={() => editUserHandler(user)}><BsPencilSquare />Editar</Dropdown.Item>
                        <Dropdown.Item eventKey="2" onClick={() => userAttendeesHandler(user)}><BsFillCalendarEventFill />Ver Inscripciones</Dropdown.Item> */}
														<Dropdown.Item
															eventKey="3"
															onClick={() => deletePaymentHandler(payment)}
														>
															<BsTrash />
																Eliminar
														</Dropdown.Item>
													</DropdownButton>
												</td>
											</tr>
										</tbody>
									))
									: ''}
							</Table>
						</Card>
					)}
					<Pagination
						className="pagination-bar"
						totalCount={itemQuantity}
						onPageChange={onPageChange}
						currentPage={currentPage}
						pageSize={maxitemsPerPage}
					></Pagination>
				</div>
			</div>
		</div>
	);
}

export default PaymentsList;
