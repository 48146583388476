import React from 'react';
import { Form, Row} from 'react-bootstrap';
import AvatarImageCropper from 'react-avatar-image-cropper';
import './WodImage.css';

export default function WodImage({ imageOption, setImageOption, fileUrl, imageData, processWODImage}) {
	return (
		<div className='wod-image'>
			<Form.Group className='mb-3 w-100 d-flex align-items-center' controlId='imageOption'>
				<Form.Label className='m-0'>Imagen autogenerada</Form.Label>
				<Form.Check
					name='imageOption'
					type='radio'
					className='mx-2'
					id='generate'
					checked={imageOption === 'generate'}
					onChange={() => setImageOption('generate')}
				/>
			</Form.Group>
			{
				imageOption === 'generate' &&
				<Row>
					<img src={imageData} alt="Generated" className='img-display' />
				</Row>
			}
			<Form.Group className='mb-3 w-100 d-flex align-items-center' controlId='imageOption'>
				<Form.Label className='m-0'>Subir imagen</Form.Label>
				<Form.Check
					className='mx-2'
					name='imageOption'
					type='radio'
					id='upload'
					checked={imageOption === 'upload'}
					onChange={() => setImageOption('upload')}
				/>
			</Form.Group>

			{imageOption === 'upload' && 
			<Row>
				<Form.Group className="mb-3" controlid="image">
					<div className="row align-items-centers">
						<div className='img-display'
							style={{ backgroundImage:
									fileUrl
										? 'url(' + fileUrl + ')'
										: 'url(/images/emptyPhoto.png)',
							backgroundRepeat: 'no-repeat',
							backgroundSize: '100% auto',
							backgroundPosition: 'center top'
							}}
						>
							<AvatarImageCropper
								apply={processWODImage}
								text="Adjuntar la imagen"
							/>
						</div>
					</div>
				</Form.Group>
			</Row>
			}
		</div>);
}