// CartRoutes.js in cart-package
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import CartHomeScreen from './screens/Home/CartHomeScreen.jsx';
import CheckoutLoginScreen from './screens/Cart/CheckoutLoginScreen.jsx';
// import ShippingAddressScreen from './screens/ShippingAddress/ShippingAddressScreen.jsx';
import PaymentMethodScreen from './screens/PaymentMethod/PaymentMethodScreen.jsx';
import PlaceOrderScreen from './screens/PlaceOrder/PlaceOrderScreen.jsx';
import OrderScreen from './screens/Order/OrderScreen.jsx';
import './CartPackage.css';


function CartRoutes({ productType }) {
	return (
		<Routes>
			<Route path="cartHomeScreen" element={<CartHomeScreen productType={productType} />} />
			<Route path="/checkout/login" element={<CheckoutLoginScreen />}/>
			{/* <Route path="/checkout/shipping" element={<ShippingAddressScreen />}/> */}
			<Route path="/checkout/payment" element={<PaymentMethodScreen />}/>
			<Route path="/order/:id" element={<OrderScreen />}/>
			<Route
				path="/checkout/placeorder"
				element={<PlaceOrderScreen />}
			/>
		</Routes>
	);
}

export default CartRoutes;
