import restClient from '../restClient';
import authHeader from './auth-header';
import { handleErrors } from './common.service';

const API_URL = '/analytics'; // Define la URL base para tus endpoints de análisis

class AnalyticsService {
	async getGeneralAnalytics() {
		try {
			const response = await restClient.get(API_URL + '/generalAnalytics', {
				headers: authHeader()
			});
			return response;
		} catch (error) {
			handleErrors(error);
		}
	}
}

export default new AnalyticsService();
