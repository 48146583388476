import React, { useEffect, useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import Exercise from '../../../classes/Exercise';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import _exercises from '../../../services/exercise.service';
import { toast } from 'react-toastify';
import { getError } from '../../../utils';

export default function ExerciseEditor({ show, close, success, exerciseParam = {} }) {
	const [exercise, setExercise] = useState(exerciseParam ? Exercise.fromObject(exerciseParam) : Exercise.createEmpty());
	useEffect(() => {
		if (exerciseParam) {
			setExercise(Exercise.fromObject(exerciseParam));
		}
	}, [exerciseParam]);

	const validateFields = () => {
		if (!exercise || !exercise.name|| !exercise.name.trim()) {
			toast.error('El nombre del ejercicio es requerido.');
			return false;
		}
		if (!exercise || !exercise.videoUrl || !exercise.videoUrl.trim()) {
			toast.error('La URL del video está vacía.');
			return false;
		}
		return true;
	};

	const handleCreate = async (event) => {
		event.preventDefault();
		if (validateFields()) {
			try {
				await _exercises.create(exercise.name,exercise.isRm, exercise.videoUrl, exercise.image._id);
				toast.success('Ejercicio creado con éxito.');
				onSuccess();
			} catch (ex) {
				toast.error(getError(ex));
			}
		}
	};
	
	const handleEdit = async (event) => {
		event.preventDefault();
		if (validateFields()) {
			try {
				await _exercises.edit(exercise._id, exercise.name,exercise.isRm, exercise.videoUrl, exercise.image._id);
				toast.success('Cambios guardados con éxito.');
				onSuccess();
			} catch (ex) {
				toast.error(getError(ex));
			}
		}
	};


	const setExerciseProperty = (field, value) => {
		setExercise(Exercise.fromObject({ ...exercise, [field]: value }));
	};
	const uploadImage = async (event) => {
		const file = event.target.files[0];
		if (file) {
			const formData = new FormData();
			let r = Math.random().toString(36).substring(7);
			formData.append('file', file, file.name.replaceAll(' ') + r);
			const { data: image } = await _exercises.uploadImage(formData);
			setExerciseProperty('image', image);
		}
	};
	const onClose = () => {
		setExercise(Exercise.createEmpty());
		close();
	};

	const onSuccess = () => {
		success();
		onClose();
	};

	return (
		<Modal show={show} onHide={onClose} animation={false} dialogClassName='exercise-editor'>
			<Modal.Header closeButton>
				<Modal.Title>{exerciseParam?.name ? 'Editar' : 'Crear'} ejercicio</Modal.Title>
			</Modal.Header>
			<Form onSubmit={exerciseParam?._id ? handleEdit : handleCreate}>
				<Modal.Body className='justify-content-center'>
					<FloatingLabel
						className='mb-3'
						controlId='exerciseName'
						label='Nombre del ejercicio'
					>
						<Form.Control type='text' value={exercise.name}
							onChange={(e) => setExerciseProperty('name', e.target.value)}
							placeholder='Nombre del ejercicio' />
					</FloatingLabel>
					<FloatingLabel
						className='mb-2'	
						controlId='exercisevideoUrl'
						label='URL de video'
					>
						<Form.Control type='text' value={exercise.videoUrl}
							onChange={(e) => setExerciseProperty('videoUrl', e.target.value)}
							placeholder='URL de video' />
					</FloatingLabel>
					<FloatingLabel 
						className='mb-2'	
						controlId='isRm'
					>
						<Form.Check
							defaultChecked={exerciseParam?.isRm}
							label={exerciseParam?.isRm?('Desactivar RM'):('Activar RM')}
							type='switch'
							name='isRm'
							onChange={(e) => setExerciseProperty('isRm', e.target.checked)}
						></Form.Check>
					</FloatingLabel>
					<Form.Group className='mb-3' controlId='exerciseImage'>
						<Form.Label>Subir imagen</Form.Label>
						<Form.Control
							onChange={uploadImage}
							type='file'
							name='exerciseImage'
						></Form.Control>
					</Form.Group>
					<div className='col-12 d-flex justify-content-center'>
						<img src={exercise?.image?.fileLink || '/images/emptyPhoto.png'}
							alt='exercise_photo'
							className='img-preview mw-100' />
					</div>
				</Modal.Body>
				<Modal.Footer className='d-flex justify-content-between'>
					<button className='admin-button dark fit-content' onClick={onClose}>
						Atras
					</button>

					<button className="admin-button fit-content" type='submit'>
						Guardar
					</button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};