import React, { forwardRef } from 'react';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import moment from 'moment';

const WeeklyTemplateBasicData = forwardRef(function form(props, ref) {
	const { weeklyTemplate, setWeeklyTemplateProperty, validated } = props;

	return <>
		<h2>Información del calendario</h2>
		<Form noValidate={true} validated={validated} ref={ref} onSubmit={(e) => e.preventDefault()}>
			<FloatingLabel
				controlId='templateName'
				label='Nombre'
			>
				<Form.Control type='text' required value={weeklyTemplate.name}
					onChange={(e) => setWeeklyTemplateProperty('name', e.target.value)}
					placeholder='Nombre del calendario' />
				<Form.Control.Feedback type="invalid">
                El nombre es requerido
				</Form.Control.Feedback>
			</FloatingLabel>
			<FloatingLabel
				controlId='templateDateFrom'
				label='Desde'
			>
				<Form.Control type='date' required value={moment(weeklyTemplate.dateFrom).format('YYYY-MM-DD')}
					onChange={(e) => setWeeklyTemplateProperty('dateFrom', moment.utc(e.target.value))} />
				<Form.Control.Feedback type="invalid">
                    La fecha de inicio es requerida
				</Form.Control.Feedback>
			</FloatingLabel>
			<FloatingLabel
				controlId='templateDateTo'
				label='Hasta'
			>
				<Form.Control type='date' required value={moment(weeklyTemplate.dateTo).format('YYYY-MM-DD')}
					onChange={(e) => setWeeklyTemplateProperty('dateTo', moment.utc(e.target.value))} />
				<Form.Control.Feedback type="invalid">
                    La fecha de fin es requerida
				</Form.Control.Feedback>
			</FloatingLabel>

		</Form>
	</>;
});
export default WeeklyTemplateBasicData;