import React, { useEffect, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { getError, maxitemsPerPage } from '../../utils';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import Pagination from '../../components/Pagination/Pagination';
import resultsService from '../../services/results.service';
import classnames from 'classnames';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return {
			...state,
			benchmarks: action.payload,
			loading: false,
			itemQuantity: action.payload.length
		};
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

function UserBenchmarksList({ className }) {
	const [{ loading, error, benchmarks, itemQuantity }, dispatch] =
		useReducer(reducer, {
			benchmarks: [],
			loading: true,
			error: '',
			itemQuantity: 0
		});

	const params = useParams();
	const { id } = params;

	//pagination
	const [currentPage, setCurrentPage] = useState(1);
	const indexOfLastPost = currentPage * maxitemsPerPage;
	const indexOfFirstPost = indexOfLastPost - maxitemsPerPage;
	let currentPosts = benchmarks.slice(indexOfFirstPost, indexOfLastPost);
	//change page
	const onPageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
		currentPosts = benchmarks.slice(indexOfFirstPost, indexOfLastPost);
	};

	const fetchBenchmarks = async () => {
		try {
			dispatch({ type: 'FETCH_REQUEST' });
			const data = await resultsService.getBenchmarksByUserId(id);
			dispatch({ type: 'FETCH_SUCCESS', payload: data });
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
		}
	};

	useEffect(() => {
		fetchBenchmarks();
	}, []);



	return loading ? (
		<LoadingBox></LoadingBox>
	) : error ? (
		<MessageBox variant="danger">{error}</MessageBox>
	) : (
		<>
			<div className={classnames('', { [className]: className })}>
				{loading ? (
					<LoadingBox></LoadingBox>
				) : error ? (
					<MessageBox variant="danger">{error}</MessageBox>
				) : (
					<Table bordered hover responsive size="sm">
						<thead>
							<tr>
								<th className="col-md-2">
									<span>Fecha</span>
								</th>
								<th>Categoria</th>
								<th>Tipo</th>
								<th>Resultado</th>
							</tr>
						</thead>
						<tbody>
							{currentPosts.map((benchmark) => (
								<tr key={benchmark._id} 	className={'align-items-center table-order'}id="data">
									<td className="col-md-2">
										{benchmark.eventWod.event.eventStartDate}
									</td>
									<td className="col-md-1">{benchmark.eventWod.wod.category}</td>
									<td className="col-md-1">
										{benchmark.eventWod.wod.type}
									</td>
									<td>{benchmark.eventWod.wod.type === 'AMRAP'?(benchmark.rounds):benchmark.type==='For time'?(benchmark.timeCap):(benchmark.maxReps)}</td>
								</tr>
							))}
						</tbody>
					</Table>
				)}
				<Pagination
					className="pagination-bar"
					totalCount={itemQuantity}
					onPageChange={onPageChange}
					currentPage={currentPage}
					pageSize={maxitemsPerPage}
				></Pagination>
			</div>
		</>
	);
}

export default UserBenchmarksList;