import LoadingBox from '../../components/LoadingBox';
import React, { useReducer, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
	Form,
	Button,
	Modal,
	Col,
	Container,
	Row,
	Card,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getError } from '../../utils';
import userBookingService from '../../services/membership.service';
import { useNavigate, useParams } from 'react-router-dom';
import { BsArrowLeftCircle } from 'react-icons/bs';
import moment from 'moment';
const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, userbooking: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

function EditUserBooking() {
	const params = useParams();
	const navigate = useNavigate();

	const { id } = params;
	const [{ loading, userbooking }, dispatch] = useReducer(reducer, {
		userbooking: {},
		loading: true,
		error: ''
	});
	const [bookingsLeft, setBookingsLeft] = useState('');
	const [dueDate, setDueDate] = useState('');
	const [showModal, setShowModal] = useState(false);
	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await userBookingService.findById(id);
				dispatch({ type: 'FETCH_SUCCESS', payload: result });
				setBookingsLeft(result.bookingsLeft);
				setDueDate(result.dueDate);
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
		};
		fetchData();
	}, [id]);

	const editUserHandler = async () => {
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			const updatedData = {
				bookingsLeft: bookingsLeft,
				dueDate: dueDate
			};
			const data = await userBookingService.edit(
				userbooking._id,
				updatedData
			);
			dispatch({
				type: 'UPDATE_SUCCESS'
			});

			toast.success('Cuponera para el usuario actualizada');
			navigate(`/AdminScreen/users/details/${data.user}`);
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL' });
			toast.error(getError(err));
		}
	};

	return (
		<div>
			{loading && <LoadingBox></LoadingBox>}
			{/* CONFIRM MODAL / BAR LOADER / ERRORS*/}
			<Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
				<Modal.Header closeButton>
					<Modal.Title>Guardar Cambios</Modal.Title>
				</Modal.Header>
				<Modal.Body>Seguro desea guardar los cambios?</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Cancelar
					</Button>
					<Button onClick={editUserHandler}>Confirmar</Button>
				</Modal.Footer>
			</Modal>
			<Container className="large-container">
				<Helmet>
					<title>Editar cuponera</title>
				</Helmet>
				<Container fluid>
					<Row className="vh-100 d-flex justify-content-center align-items-center">
						<Col md={8} lg={6} xs={12}>
							<Card className="shadow">
								<Card.Body>
									<div className="mb-3 mt-md-4">
										<h2 className="fw-bold mb-4 text-uppercase text-align-center ">
											Editar cuponera
										</h2>
										<div className="mb-3">
											<Form onSubmit={(e) => e.preventDefault()}>
												<Form.Group className="mb-3" controlid="name">
													<Form.Label>Cantidad de clases:</Form.Label>
													<Form.Control
														type="text"
														required
														defaultValue={userbooking.bookingsLeft}
														onChange={(e) => setBookingsLeft(e.target.value)}
													></Form.Control>
												</Form.Group>
												<Form.Group className="mb-3" controlid="duedate">
													<Form.Label>Fecha de vencimiento:</Form.Label>
													<Form.Control
														type="date"
														required
														value={moment(dueDate).format('YYYY-MM-DD')}
														onChange={(e) => setDueDate(moment(e.target.value))}
													></Form.Control>
												</Form.Group>

												<Row className='justify-content-around'>
													<button className='admin-button dark fit-content' onClick={() => navigate(-1)}>
														<BsArrowLeftCircle /> Atras
													</button>

													<button className="admin-button fit-content" onClick={handleShow}>
														Guardar Cambios
													</button>
												</Row>
											</Form>
										</div>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</Container>
		</div>
	);
}
export default EditUserBooking;
