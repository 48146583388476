import { useReducer, useEffect } from 'react';
import { showErrorToast } from '../utils';
import { useLocation } from 'react-router-dom';
import _weeklyTemplates from '../services/weeklyTemplate.service';

export default function useWeeklyTemplates() {
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	const [reducerState, dispatch] =
        useReducer(reducer, {
        	weeklyTemplates: [],
        	loading: true,
        	weeklyTemplateCount: 0,
        	error: ''
        });
	const { weeklyTemplates, loading, weeklyTemplateCount, error } = reducerState;

	//#region REQUESTS
	const fetchWeeklyTemplates = async () => {
		try {
			const {data} = await _weeklyTemplates.list(searchParams);
			dispatch({ type: actions.FETCH_SUCCESS, payload: data });
		} catch(ex) {
			showErrorToast(ex);
		}
	};
	const reloadWeeklyTemplates = async () => {
		dispatch({ type: actions.FETCH_REQUEST });
	};
	//#endregion
	useEffect(() => {
		if(loading) {
			fetchWeeklyTemplates();
		}
	}, [loading]);

	return {weeklyTemplates, loading, weeklyTemplateCount, error, reloadWeeklyTemplates };
}
const reducer = (state, action) => {
	switch (action.type) {
	case actions.FETCH_REQUEST:
		return { ...state, loading: true };
	case actions.FETCH_SUCCESS:
		return {
			...state,
			weeklyTemplates: action.payload.weeklyTemplates,
			weeklyTemplateCount: action.payload.weeklyTemplateCount,
			loading: false,
		};
	case actions.FETCH_FAIL:
		return { ...state, loading: false, error: action.payload };
	case actions.DELETE_REQUEST:
		return { ...state, loadingDelete: true, successDelete: false };
	case actions.DELETE_SUCCESS:
		return {
			...state,
			loadingDelete: false,
			successDelete: true
		};
	case actions.DELETE_FAIL:
		return { ...state, loadingDelete: false, successDelete: false };

	case actions.DELETE_RESET:
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};
const actions = {
	FETCH_REQUEST: 'FETCH_REQUEST',
	FETCH_SUCCESS: 'FETCH_SUCCESS',
	FETCH_FAIL: 'FETCH_FAIL',
	DELETE_REQUEST: 'DELETE_REQUEST',
	DELETE_SUCCESS: 'DELETE_SUCCESS',
	DELETE_FAIL: 'DELETE_FAIL',
	DELETE_RESET: 'DELETE_RESET'
};