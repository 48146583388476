import LoadingBox from '../../components/LoadingBox';
import React, { useReducer, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
	Form,
	Col,
	Container,
	Row,
	Card,
	Stack,
} from 'react-bootstrap';
import { getError } from '../../utils';
import _memberships from '../../services/membership.service';
import { useNavigate, useParams } from 'react-router-dom';
import { BsFillBackspaceFill } from 'react-icons/bs';
import moment from 'moment';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, booking: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'FETCH_DEBT_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_DEBT_SUCCESS':
		return { ...state, debt: action.payload, loading: false };
	case 'FETCH_DEBT_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

function UserBookingDetails() {
	const params = useParams();
	const navigate = useNavigate();

	const { id } = params;
	const [{ loading, booking }, dispatch] = useReducer(reducer, {
		booking: {},
		debt: {},
		loading: true,
		error: ''
	});
	const [dueDate, setDueDate] = useState('');
	const [startingDate, setStartingDate] = useState();


	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await _memberships.findById(id);
				dispatch({ type: 'FETCH_SUCCESS', payload: result });
				setDueDate(result.dueDate);
				setStartingDate(result.startingDate);
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
		};

		fetchData();
	}, [id]);


	return (
		<div>
			{loading && <LoadingBox></LoadingBox>}
			{/* CONFIRM MODAL / BAR LOADER / ERRORS*/}

			<Container className="large-container">
				<Helmet>
					<title>Ver cuponera</title>
				</Helmet>
				<Container fluid>
					<Row className="vh-100 d-flex justify-content-center align-items-center">
						<Col md={8} lg={6} xs={12}>
							<Card className="shadow">
								<Card.Body>
									<div className="mb-3 mt-md-4">
										<h2 className="fw-bold mb-4 text-uppercase text-align-center ">
											Detalles de Cuponera
										</h2>
										<div className="mb-3">
											<Form onSubmit={(e) => e.preventDefault()}>
												<Form.Group className="mb-3" controlid="name">
													<Form.Label>Clases restantes:</Form.Label>
													<Form.Control
														type="text"
														required
														disabled
														defaultValue={booking.bookingsLeft}
													></Form.Control>
												</Form.Group>
												<Row>
													<Form.Group className="mb-3 col-6" controlid="duedate">
														<Form.Label>Fecha de Inicio:</Form.Label>
														<Form.Control
															type="date"
															required
															disabled
															value={moment(startingDate).format('YYYY-MM-DD')}
														></Form.Control>
													</Form.Group>
													<Form.Group className="mb-3 col-6" controlid="duedate">
														<Form.Label>Fecha de vencimiento:</Form.Label>
														<Form.Control
															type="date"
															required
															disabled
															value={moment(dueDate).format('YYYY-MM-DD')}
														></Form.Control>
													</Form.Group>
												</Row>

												<Stack direction="horizontal" gap={10}>
													<button
														className="admin-button fit-content mt-1"
														value="Volver"
														onClick={() => navigate(-1)}
													>
														<span>
															<BsFillBackspaceFill /> Volver
														</span>
													</button>
												</Stack>
											</Form>
										</div>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</Container>
		</div>
	);
}
export default UserBookingDetails;
