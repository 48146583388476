export default class Exercise {
	constructor(name,isRm, videoUrl, image, id = '') {
		this.name = name;
		this.isRm = isRm;
		this.videoUrl = videoUrl;
		this.image = image;
		this._id = id;
	}
	/**
  *
  * @param {string} name
  * @param {boolean} isRm
  * @param {string} videoUrl
  * @param {*} image
  * @returns {boolean}
  */

	static validate(name, isRm, videoUrl, image) {
		return validator(name,isRm, videoUrl, image);
	}
	isValid(name,isRm, videoUrl, image) {
		return validator(name,isRm, videoUrl, image);
	}
	/**
  *
  * @param {string} name
  * @param {boolean} isRm
  * @param {string} videoUrl
  * @param {*} image
  * @returns {Exercise}
  */
	static fromObject({ name, isRm, videoUrl, image, _id = ''}) {
		return new Exercise(name,isRm, videoUrl, image, _id);
	}
	/**
  *
  * @returns {Exercise}
  */
	static createEmpty() {
		return new Exercise('', true, '','');
	}
}
const validator = (name,isRm, videoUrl, image) => {
	return (
		name?.trim() !== '' &&
  videoUrl?.trim() !== '' &&
  typeof isRm === 'boolean' &&
  (typeof image === 'object' || image?.trim() !== '')
	);
};
