import { useReducer, useEffect } from 'react';
import { showErrorToast } from '../utils';

import _classes from '../services/class.service';

export default function useClassesByWeeklyTemplate(weeklyTemplateParam) {
	const [reducerState, dispatch] =
        useReducer(reducer, {
        	classes: [],
        	loading: true,
        	classesCount: 0,
        	error: ''
        });
	const { classes, loading, classesCount, error } = reducerState;

	const fetchClasses = async () => {
		try {
			if (weeklyTemplateParam === null) {
				dispatch({ type: actions.FETCH_SUCCESS, payload: { classes: [], classesCount: 0 } });
			} else {
				const { data } = await _classes.getByWeeklyTemplate(weeklyTemplateParam);
				dispatch({ type: actions.FETCH_SUCCESS, payload: data });
			}
		} catch (ex) {
			showErrorToast(ex);
			dispatch({ type: actions.FETCH_FAIL, payload: ex.message });
		}
	};
	const reloadClasses = async () => {
		dispatch({ type: actions.FETCH_REQUEST });
	};
	//#endregion
	useEffect(() => {
		if (loading || weeklyTemplateParam) {
			fetchClasses();
		}

	}, [loading, weeklyTemplateParam]);

	return {classes, loading, classesCount, error, reloadClasses };
}
const reducer = (state, action) => {
	switch (action.type) {
	case actions.FETCH_REQUEST:
		return { ...state, loading: true };
	case actions.FETCH_SUCCESS:
		return {
			...state,
			classes: action.payload.classes,
			classesCount: action.payload.classesCount,
			loading: false,
		};
	case actions.FETCH_FAIL:
		return { ...state, loading: false, error: action.payload };
	case actions.DELETE_REQUEST:
		return { ...state, loadingDelete: true, successDelete: false };
	case actions.DELETE_SUCCESS:
		return {
			...state,
			loadingDelete: false,
			successDelete: true
		};
	case actions.DELETE_FAIL:
		return { ...state, loadingDelete: false, successDelete: false };

	case actions.DELETE_RESET:
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};
const actions = {
	FETCH_REQUEST: 'FETCH_REQUEST',
	FETCH_SUCCESS: 'FETCH_SUCCESS',
	FETCH_FAIL: 'FETCH_FAIL',
	DELETE_REQUEST: 'DELETE_REQUEST',
	DELETE_SUCCESS: 'DELETE_SUCCESS',
	DELETE_FAIL: 'DELETE_FAIL',
	DELETE_RESET: 'DELETE_RESET'
};