import React from 'react';
import './TabLoadingOverlay.css';
import { GridLoader } from 'react-spinners';
import classnames from 'classnames';

export const TabLoadingOverlay = ({ className = '' }) => {
	return (
		<div className={classnames('tab-loading-overlay', {[className] : className})}>
			<GridLoader/>
		</div>
	);
};

export default TabLoadingOverlay;
