import axios from '../restClient';
import { handleErrors } from './common.service';

const BASE_URI = '/classes';

class ClassService {

	async list(searchParams, nonPaginated = false) {
		const query = Object.fromEntries(searchParams.entries());;
		if (nonPaginated) {
			query.nonPaginated = true;
		}
		try {
			const response = await axios.get(BASE_URI, {
				params: query
			});
			return response;
		} catch (error) {
			handleErrors(error);
		}
	}

	async listUserClasses(searchParams) {
		try {
			const response = await axios.get(`${BASE_URI}/user`, {
				params: Object.fromEntries(searchParams.entries())
			});
			return response;
		} catch (error) {
			handleErrors(error);
		}
	}
	async getByWeeklyTemplate(weeklyTemplateParam) {
		try {
			const response = await axios.get(`${BASE_URI}/byWeeklyTemplate`, {
				params: { weeklyTemplateParam }
			});
			return response;
		} catch (error) {
			handleErrors(error);
		}
	}
	async create(classes, weeklyTemplate) {
		try {
			const response = await axios.post(BASE_URI,{
				classes, weeklyTemplate
			});
			return response;
		} catch (error) {
			handleErrors(error);
		}


	}
	async edit(classes, weeklyTemplate) {
		try {
		  const response = await axios.put(BASE_URI, {
				classes, 
				weeklyTemplate
		  });
		  return response;
		} catch (error) {
		  handleErrors(error);
		}
	  }
	  
	async delete(id) {
		try {
			const response = await axios.delete(BASE_URI,{
				params: { id }
			});
			return response;
		} catch (error) {
			handleErrors(error);
		}
	}
}

export default new ClassService();