import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import React, {useEffect, useState } from 'react';
import './MercadoPagoConfig.css';
import { toast } from 'react-toastify';
import FloatingLabel from 'react-bootstrap/esm/FloatingLabel';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';
import classnames from 'classnames';
import _mercadopago from '../../services/mercadopago.service';

export default function MercadoPagoConfig() {
	const [accessToken, setAccessToken] = useState('');
	const [inputType, setInputType] = useState('password');
	const [inputFocus, setInputFocus] = useState(false);

	useEffect(() => {
		fetchAccessToken();
	}, []);

	async function fetchAccessToken() {
		try {
			const res = await _mercadopago.getAccessToken();
			setAccessToken(res.data);
		} catch (ex) {
			console.error(ex);
			toast.error('Error. No se pudo obtener el access token');
		}
	}
	const uploadAppKey = async () => {
		try {
			const { data } = await _mercadopago.setAccesstoken(accessToken);
			toast.success(data.message);
		} catch (ex) {
			console.error(ex);
			toast.error('Error.');
		}
	};

	const togglePassword = () => {
		if (inputType === 'password') {
			setInputType('text');
		} else {
			setInputType('password');
		}
	};
	const handleUploadAppKey = () => {
		if (accessToken) {
			uploadAppKey();
		} else {
			toast.error('Error. Campo vacío.');
		}
	};


	return (
		<div id="mercadopagoconfig">
			<div className="d-flex mercadopagoconfig-container justify-content-start align-items-center w-100 flex-wrap">
				<FloatingLabel
					className="w-75"
					controlId="mp-access-token"
					label="MercadoPago Access Token"
				>
					<Form.Control
						onFocus={() => setInputFocus(true)}
						onBlur={() => setInputFocus(false)}
						placeholder="MercadoPago Access Token"
						onChange={(e) => setAccessToken(e.target.value)}
						value={accessToken}
						type={inputType}
						required
					></Form.Control>
				</FloatingLabel>
				<button
					className={classnames('toggle-password-button', { 'input-focused': inputFocus })}
					onClick={togglePassword}
				>
					{inputType === 'password' ? (
						<BsFillEyeSlashFill />
					) : (
						<BsFillEyeFill />
					)}
				</button>
				<Button
					variant="outline-secondary"
					disabled={!accessToken}
					onClick={handleUploadAppKey}
				>
          Actualizar
				</Button>
			</div>
		</div>
	);
}
