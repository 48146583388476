/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import './CartItem.css';
import { useNavigate } from 'react-router-dom';
import _products from '../../../services/product.service.js';
import _productVariants from '../../../services/productVariant.service.js';
import { toast } from 'react-toastify';
import { Store } from '../../../../../Store.js';
import moment from 'moment';

export default function CartItem({ item, removeItemHandler }) {
	const navigate = useNavigate();
	const [quantity, setQuantity] = useState(parseInt(item.quantity));
	const [loadingChangeQuantity, setLoadingChangequantity] = useState(false);
	const { state, dispatch } = useContext(Store);
	const { useStock } = state; // Get useStock from the Store context


	const closeCart = () => {
		dispatch({ type: 'CLOSE_CART' });
	};

	const changeProductQuantity = async () => {
		const productRequest = item.product ? _productVariants.get : _products.get;
		try {
			const { data: prod } = await productRequest(item._id, 'membershipTemplate' );

			// Only check stock if useStock is true
			if (useStock && prod.countInStock > quantity) {
				const product = { ...prod, quantity };
				dispatch({ type: 'CART_ADD_ITEM', payload: product });
				dispatch({ type: 'OPEN_CART' });
			} else if (useStock) {
				toast.warning('El stock no es suficiente.');
			} else {
				// If useStock is false, proceed without stock checks
				const product = { ...prod, quantity };
				dispatch({ type: 'CART_ADD_ITEM', payload: product });
				dispatch({ type: 'OPEN_CART' });
			}
		} catch (ex) {
			console.error(ex);
		} finally {
			setLoadingChangequantity(false);
		}
	};

	useEffect(() => {
		if (loadingChangeQuantity) {
			changeProductQuantity();
		}
	}, [loadingChangeQuantity]);



	return (
		<div className='d-flex w-100 cart-item'>
			<div className='col-md-4' style={{alignSelf: 'center', justifySelf: 'center'}}>
				<strong style={{paddingLeft: '1rem', fontSize: '24px'}}>
				X{item.quantity}
				</strong>
					
			</div>
			<div className='col-md-4 mx-4'>
				<div className='row'>
					<span  style={{fontSize: '18px', fontWeight: '500'}}>
						{item.name}
					</span>
				</div>
				<div className='row'>
					<span style={{fontSize: '12px'}}>
        Vigencia {moment(item.startDate).format('DD/MM/YYYY')} al {moment().add(item.daysLast, 'days').format('DD/MM/YYYY')}
					</span>
				</div>

			</div>
			{/* <a onClick={goToProduct.bind(this, item.slug)} className='cart-item-preview col-3 '>
				{showQuantityBadge && <Badge>
					{item.quantity}
				</Badge>}

				{item.image && typeof item.image === 'object' ? (
					<img
						className='img-fluid'
						src={item.image.fileLink}
						alt={item.name}
					/>
				) : (
					<img
						className='empty img-fluid'
						src="/images/emptyPhoto.png"
						alt="product_photo"
					/>
				)}

			</a> */}
			
			{/* <div className="col-6 px-2">
				{item.product ?
					<a className='item-name' onClick={goToProduct.bind(this, item.product.slug)}>{item.product.name}</a> :
					<a className='item-name' onClick={goToProduct.bind(this, item.slug)}>{item.name}</a>
				}
				<div className="item-data">
					<div> ${item.price}</div>
					{item.options && item.product && item.options.map((opt) => (
						<span key={`${opt.option.name}: ${opt.value.value}`} className='d-inline-block pe-1 item-option'>{`${opt.option.name}: ${opt.value.value}`}</span>
					))}
				</div>
			</div>
			<div className="col-3 d-flex flex-column justify-content-between align-items-center">
				{canChangeQuantity && <QuantityPicker quantity={item.quantity} onDecrement={removeOne} onIncrement={addOne} />}
				<button className={classnames('delete-button', { invisible: !removeItemHandler || !canDeleteItem })}
					onClick={() => canDeleteItem && removeItemHandler(item)}>
					<BsTrash3 />
				</button>
			</div> */}
		</div>
	);
}
