import React, { useEffect, useReducer, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
	Row,
	Col,
	Button,
	Table,
	Dropdown,
	DropdownButton,
	Card,
	InputGroup
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';
import { getError } from '../../../utils';
import LoadingBox from '../../../components/LoadingBox';
import MessageBox from '../../../components/MessageBox';
import SearchBox from '../../../components/SearchBox/SearchBox';
import eventService from '../../../services/event.service';

import {
	BsPlusCircle,
	BsTrash,
	BsPencilSquare,
	BsFileEarmarkRichtext
} from 'react-icons/bs';
import { BsFillCalendarEventFill } from 'react-icons/bs';
import { DateTime } from 'luxon';
import './AdminEventList.css';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {
	HiBarsArrowDown,
	HiBarsArrowUp,
	HiOutlineFunnel,
	HiOutlineXMark
} from 'react-icons/hi2';
import useQueryFilters from '../../../hooks/useQueryFilters';
import ParamPagination from '../../../components/ParamPagination/ParamPagination';
import EventEditor from '../EventEditor/EventEditor';
import AssignWod from '../AssignWod/AssignWod';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_SUCCESS':
		return {
			...state,
			events: action.payload.events,
			loadingFetch: false,
			itemQuantity: action.payload.count
		};
	case 'WOD_ASSIGNED':
		return {
			...state,
			events: action.payload
		};
	case 'FETCH_FAIL':
		return { ...state, loadingFetch: false, error: action.payload };
	case 'SUCCESS_EDIT':
		return { ...state, successEdit: true };
	case 'EDIT_RESET':
		return { ...state, successEdit: false };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

// Fecha actuales (luxon)
const monday = DateTime.now().startOf('week'); // isoWeek is equivalent to 'week' in luxon
const sunday = DateTime.now().endOf('week');

function AdminEventList() {
	const initFields = {
		sort: JSON.stringify({ eventStartDateTime: 1 }),
		eventStartDateTime: monday.toISODate().toString(),
		eventEndDateTime: sunday.toISODate().toString(),
		subjectQuery: ''
	};
	const { searchParams, search, setFields, submitFilters, fields, setPage,
		setPageSize } = useQueryFilters(
		{ baseUrl: '/AdminScreen/eventList', resizeWidth: 768, initFields }
	);;

	const [
		{ loadingFetch, error, events, successDelete, successEdit, itemQuantity },
		dispatch
	] = useReducer(reducer, {
		events: [],
		error: '',
		itemQuantity: 0,
		loadingFetch: true,
		successDelete: false
	});

	const navigate = useNavigate();

	//set date-range-picker initial dates
	const [dateRange, setDateRange] = useState([
		{
			startDate: new Date(monday.toJSDate()),
			endDate: new Date(sunday.toJSDate()),
			key: 'selection'
		}
	]);

	const [dateSort, setDateSort] = useState(1);
	const [showDropdown, setShowDropdown] = useState(false);

	const handleDropdownToggle = (isOpen) => {
		setShowDropdown(isOpen);
	};

	//filter button functionality
	async function handleSelect() {
		setShowDropdown(false);
		submitFilters();
	}
	const fetchData = async () => {
		try {
			dispatch({ type: 'FETCH_REQUEST' });
			const data = await eventService.getFilteredEvents(searchParams);
			dispatch({ type: 'FETCH_SUCCESS', payload: data });
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
		}
	};

	useEffect(() => {
		if (search) {
			fetchData();
		} else {
			submitFilters(initFields);
		}
	}, [search]);

	useEffect(() => {
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		}
		if (successEdit) {
			dispatch({ type: 'EDIT_RESET' });
			fetchData();
		}
	}, [successDelete, successEdit]);


	async function toggleEventHandler(event) {
		if (
			window.confirm(
				`Seguro desea ${event.isActive ? 'desactivar' : 'activar'} este evento?`
			)
		) {
			try {
				dispatch({ type: 'DELETE_REQUEST' });
				await eventService.toggleEventActiveStatus(event._id);
				dispatch({ type: 'DELETE_SUCCESS' });
				toast.success(
					`Evento  ${event.subject} ${event.isActive ? 'desactivado' : 'activado'}`
				);
			} catch (err) {
				toast.error(getError(err));
				dispatch({ type: 'DELETE_FAIL' });
			}
		}
	}



	async function detailsEventHandler(user) {
		navigate(`/AdminScreen/detailEvent/${user._id}`);
	}


	const handleChangeSubject = (subjectQuery) => {
		setFields({ ...fields, subjectQuery });
	};

	//Calendar range picker set dates
	const handler = (item) => {
		if (item.selection.startDate && item.selection.endDate) {
			setDateRange([item.selection]);
			const parsedRanges = {
				eventStartDateTime: DateTime
					.fromJSDate(item.selection.startDate)
					.toISODate().toString(),
				eventEndDateTime: DateTime.
					fromJSDate(item.selection.endDate)
					.toISODate().toString()
			};
			setFields({ ...fields, ...parsedRanges });
		}
	};

	const [selectedEvent, setSelectedEvent] = useState(null);
	const [showEditor, setShowEditor] = useState(false);
	const [showAssignWod, setShowAssignWod] = useState(false);
	
	function openEditor(event = null) {
		setSelectedEvent(event);
		setShowEditor(true);
	}
	function onCloseEditor() {
		setSelectedEvent(null);
		setShowEditor(false);
	}
	function onSuccessEditor() {
		dispatch({ type: 'SUCCESS_EDIT' });
	}
	function onHideAssignWod() {
		setShowAssignWod(false);
	}
	function openAssignWod(ev) {
		setSelectedEvent(ev);
		setShowAssignWod(true);
		
	}
	/**
	 * 
	 * @param {Map} formState 
	 */
	async function assignWod(formState) {
		try {
			await eventService.assignWodToEvent(selectedEvent._id, formState.get('wods'));
			toast.success('WOD asignado correctamente');
		} catch(ex) {
			toast.error(getError(ex));
			console.error(ex);
		}
	}

	return (
		<>
			<Helmet>
				<title>Clases</title>
			</Helmet>
			<EventEditor onSuccess={onSuccessEditor} show={showEditor} eventParam={selectedEvent} onClose={onCloseEditor} />
			<AssignWod event = {selectedEvent} show={showAssignWod} onHide={onHideAssignWod} onSuccess = {() => fetchData()}
				onSubmit={assignWod} initialState = {{wods: selectedEvent?.wods}} />
			<div className="container admin-con">
				<div className="tableResponsive">
					<Row className="align-items-center mb-1" >
						<Col>
							<h1 className="section-title text-right">
								<BsFillCalendarEventFill></BsFillCalendarEventFill>Lista de clases
							</h1>
						</Col>
						<InputGroup className="mb-3" controlid="fechaInicio">
							<br></br>
						</InputGroup>
						<Col
							style={{
								display: 'flex',
								justifyContent: 'flex-start'
							}}
						>
							<DropdownButton
								id="optionsLists"
								drop="bottom"
								title="Filtrar por Fechas"
								className="m-1 fixed-left"
								autoClose={false}
								show={showDropdown}
								onToggle={handleDropdownToggle}
							>
								<Dropdown.Item className="calendarDropdownItem" eventKey="0">
									<Row>
										<DateRange
											editableDateInputs={true}
											onChange={(item) => handler(item)}
											showSelectionPreview={true}
											ranges={dateRange}
											className="my-datetime-picker"
										/>
									</Row>
									<Row>
										<Button onClick={handleSelect}>
											<HiOutlineFunnel></HiOutlineFunnel>Filtrar
										</Button>
									</Row>
								</Dropdown.Item>
							</DropdownButton>
							<Button
								className="btn btn-dark m-1 fixed-left filterBtn"
								value="Crear evento"
								onClick={() => (dateSort === 1 ? setDateSort(-1) : setDateSort(1))}
							>
								{dateSort === -1 ? <HiBarsArrowUp /> : <HiBarsArrowDown />}
									Ordenar
							</Button>
						</Col>
						<Col
							className="col-10 col-md-6 col-lg-4"
							style={{ height: '52px', padding: '2px' }}
						>
							<SearchBox value={fields.subjectQuery} onChange={handleChangeSubject}
								submitHandler={submitFilters} />
							<Link to="/AdminScreen/eventList" style={{ fontSize: '10px' }}>
								<HiOutlineXMark></HiOutlineXMark>borrar busqueda
							</Link>
						</Col>
						<Col
							style={{
								display: 'flex',
								justifyContent: 'flex-end'
							}}
						>
							<Button
								className="btn btn-dark m-1 fixed-right"
								value="Crear evento"
								onClick={openEditor}
							>
								<span>
									<BsPlusCircle /> Agregar clase
								</span>
							</Button>
						</Col>
					</Row>
					<Card>
						{
							!(error || loadingFetch) ?
								<Table bordered hover responsive size="sm">
									<thead>
										<tr>
											<th className="col-md-2">Tema</th>
											<th className="col-md-2">Locacion</th>
											<th className="col-md-1">Capacidad</th>
											<th className="col-md-1">Fecha y hora de inicio</th>
											<th className="col-md-1">Fecha y hora de fin</th>
											<th className="col-1">Opciones</th>
										</tr>
									</thead>
									{events.map((event) => (
										<tbody key={event._id}>
											<tr
												key={event._id}
												className={`align-items-center table-order ${event.isActive ? 'table-success' : 'table-secondary'
												}`}
												id="data"
											>
												<td className="col-md-2">{event.subject}</td>
												<td className="col-md-2">{event.location}</td>
												<td className="col-md-1">{event.capacity}</td>
												<td className="col-md-1">
													{DateTime.fromISO(event.eventStartDateTime).toUTC().toFormat(
														'dd/MM/yyyy HH:mm a'
													)}
												</td>
												<td className="col-md-1">
													{DateTime.fromISO(event.eventEndDateTime).toUTC().toFormat(
														'dd/MM/yyyy HH:mm a'
													)}
												</td>
												<td>
													<DropdownButton id="optionsLists" drop="start" title="">
														<Dropdown.Item
															eventKey="0"
															onClick={() => detailsEventHandler(event)}
														>
															<BsFileEarmarkRichtext />
																Ver Detalles
														</Dropdown.Item>
														<Dropdown.Item
															eventKey="1"
															onClick={() => openEditor(event)}
														>
															<BsPencilSquare />
																Editar
														</Dropdown.Item>
														<Dropdown.Item
															eventKey="2"
															onClick={() => toggleEventHandler(event)}
														>
															<BsTrash />
																Eliminar
														</Dropdown.Item>
														<Dropdown.Item
															eventKey="2"
															onClick={() => openAssignWod(event)}
														>
															<BsTrash />
																Asignar WOD
														</Dropdown.Item>
													</DropdownButton>
												</td>
											</tr>
										</tbody>

									))}
								</Table> : loadingFetch ?
									<LoadingBox className={'mx-auto'} /> :
									<MessageBox variant="danger">{error}</MessageBox>
						}
					</Card>
					<ParamPagination
						className="pagination-bar"
						totalCount={itemQuantity}
						onPageChange={setPage}
						currentPage={fields.page}                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
						pageSize={fields.pageSize}
						setPageSize={setPageSize}
					></ParamPagination>
				</div>
			</div>
		</>
	);
}

export default AdminEventList;
