import React, { Suspense, useContext, useEffect } from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
// import '@ionic/react/css/core.css';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import { setupAxiosInterceptors } from './restClient.js';
import { logoutHandler } from './utils.js';

//services
import ThemedComponent from './components/GlobalStyles.js';

//components
import Navbar from './components/Navbar/Navbar';
import ScrollToTop from './components/ScrollToTop';
import AdminNavbar from './components/AdminNavbar/AdminNavbar.jsx';
import LoadingBox from './components/LoadingBox';
import { ToastContainer } from 'react-toastify';

//from package
import CartRoutes from './packages/cart-package/CartRoutes.js';

//Screens
import SignInScreen from './screens/Auth/SignInScreen/SignInScreen.jsx';
import ResetPassword from './screens/Auth/ResetPassword.jsx';
import UpdatePassword from './screens/Auth/ChangePasswordLogged.jsx';
import ProfileScreen from './screens/ProfileScreen.jsx';
import AdminStatsScreen from './screens/Admin/Stats/StatsScreen/AdminStatsScreen.jsx';
import ChangePassword from './screens/Auth/ChangePassword.jsx';
import VerifyUser from './screens/Auth/VerifyUser.jsx';
import AdminConfigScreen from './screens/AdminConfigScreen/AdminConfigScreen.jsx';
import AddUser from './screens/Admin/Users/NewUser/NewUser.jsx';
import EditUser from './screens/Admin/Users/EditUser/EditUser.jsx';
import UsersList from './screens/Admin/Users/UsersList/UsersList.jsx';
import UserAttendees from './screens/Admin/Users/UserAttendees/UserAttendees.jsx';
import MembershipTemplatesList from './screens/MembershipTemplates/List/MembershipTemplatesList.jsx';
import AddBookingTemplate from './screens/MembershipTemplates/newBookingTemplate.jsx';
import EditWOD from './screens/WODs/EditWOD.jsx';
import WODList from './screens/WODs/WODList.jsx';
import EditBookingTemplate from './screens/MembershipTemplates/EditBookingTemplate.jsx';
import UserBookingDetails from './screens/UserMemberships/UserBookingDetails.jsx';
import AdminEventList from './screens/AdminEvent/AdminEventList/AdminEventList.jsx';
import DetailEvent from './screens/AdminEvent/EventDetails/DetailEvent.jsx';
import BookingScreen from './screens/Bookings/BookingScreen/BookingScreen.jsx';
import UserEventDetails from './screens/Bookings/UserEventDetails.jsx';
import UserDataConfig from './screens/UserSettings/UserDataConfig.jsx';
import DebtList from './screens/AdminDebts/DebtsList.jsx';
import UserDebtsList from './screens/AdminDebts/UserDebtsList.jsx';
import NewPayment from './screens/AdminPayments/NewPayment.jsx';
import PaymentsList from './screens/AdminPayments/PaymentsList.jsx';
import UserPaymentsList from './screens/AdminPayments/UserPaymentsList.jsx';

import { Store } from './Store';
import ExerciseList from './screens/AdminExercises/ExerciseList.jsx';
import WeeklyTemplatesList from './screens/AdminWeeklyTemplates/WeeklyTemplatesList.jsx';
import AddUserResults from './screens/UserResults/AddUserResults.jsx';
import AddUserResultsByEvent from './screens/UserResults/AddUserResultsByEvent.jsx';
import SignupScreen from './screens/Auth/SignUpScreen/SignUpScreen.jsx';
import SideNav from './components/SideNav/SideNav.jsx';
import LandingPageScreen from './screens/LandingPageScreen/LandingPageScreen.jsx';
import LoadingOverlay from './components/LoadingOverlay/LoadingOverlay.jsx';
import UserDetails from './screens/Admin/Users/UserDetails/UserDetails.jsx';
import UserMembershipsList from './screens/UserMemberships/UserMembershipList/UserMembershipList.jsx';
import NewRM from './screens/RMs/NewRM';
import EditRM from './screens/RMs/EditRM';
import EditUserBooking from './screens/UserMemberships/EditUserBooking.jsx';
import ProfileEventsList from './screens/ClientProfile/ProfileEventsList.jsx';
import ProfileMembershipList from './screens/ClientProfile/ProfileMembershipList.jsx';
import UserResultsList from './screens/ClientProfile/UserResultsList.jsx';
import  _settings from './services/settings.service';

function App() {

	const isEmpty = (obj) => {
		return !obj || Object.keys(obj).length === 0;
	};

	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo, loadingOverlay } = state;

	const fetchPublicData = async () => {
		try {
			const { data } = await _settings.getPublicData();
			ctxDispatch({type: 'LOAD_PUBLIC_DATA', payload: data});
		} catch(ex) {
			console.error(ex);
			console.error('No se pudo obtener la informacion de la empresa');
		}
	};
	useEffect(() => {
		fetchPublicData();
	},[]);

	useEffect(() => {
		if (isEmpty(userInfo) || !userInfo) {
			logoutHandler(ctxDispatch);
			ctxDispatch({ type: 'USER_SIGNOUT' });
		}

		setupAxiosInterceptors(ctxDispatch);
	}, [ctxDispatch]);

	const LOCATIONS_WITHOUT_NAVBAR = [
		'/signin',
		'/signup',
		'/reset-password',
		'/verify-user',
		'/change-password',
	];
	return (
		<Suspense fallback={<LoadingBox />}>
			<ThemedComponent />
			<BrowserRouter>
				<SideNav />
				<ScrollToTop></ScrollToTop>
				{
					loadingOverlay && <LoadingOverlay />
				}
				<div className="d-flex flex-column site-container">
					{
						!LOCATIONS_WITHOUT_NAVBAR.includes(location.pathname) ?
							(userInfo?.isAdmin ? <AdminNavbar /> : <Navbar />)
							:
							<ToastContainer position='bottom-center' />
					}
					<main>
						<Routes>
							<Route
								path="*"
								element={<Navigate to="/" replace />}
							/>
							<Route path="/" element={!userInfo ? <LandingPageScreen /> : 
								<Navigate to={userInfo.isAdmin ? '/AdminScreen/stats' : 'profile'}/> }>
							</Route>
							{/* <Route path="/contact" element={<ContactUs />}></Route>
							<Route path="/shipping" element={<Shipping />}></Route>
							<Route path="/faq" element={<FaqPage />}/> */}
							{
								//NON-LOGGED USER ROUTES
							}
							<Route path="/signin" element={<SignInScreen />}></Route>
							<Route path="/reset-password/" element={<ResetPassword />}></Route>
							<Route path="/signup" element={<SignupScreen />}></Route>
							<Route
								path="/change-password/:token"
								element={<ChangePassword />}
							></Route>
							<Route path="/verify-user/:token" element={<VerifyUser />}></Route>

							{
								//END NON-LOGGED USER ROUTES
							}
							{
								//LOGGED USER ROUTES
							}
							<Route path="/profile" element={userInfo ? <ProfileScreen /> : <Navigate to="/"/>}/>
							<Route path="/profile/my-classes" element={userInfo ? <ProfileEventsList /> : <Navigate to="/"/>}/>
							<Route path="/profile/my-cuponeras" element={userInfo ? <ProfileMembershipList /> : <Navigate to="/"/>}/>
							<Route path="/profile/my-results" element={userInfo ? <UserResultsList /> : <Navigate to="/"/>}/>
							<Route
								path="/events"
								element={userInfo ? <BookingScreen /> : <Navigate to="/" />} />
							<Route
								path="/bookings"
								element={userInfo ? <>clases</> : <Navigate to="/" />} />

							
							<Route
								path="/updatepassword"
								element={userInfo ? <UpdatePassword /> : <Navigate to="/" />}
							></Route>
							<Route path="/results" element={userInfo ? <AddUserResults /> : <Navigate to="/" />}/>
							<Route path="/results/:id" element={userInfo ? <AddUserResultsByEvent /> : <Navigate to="/" />}/>

							<Route
								path="/userDataConfig"
								element={userInfo ? <UserDataConfig /> : <Navigate to="/" />}
							></Route>
							{
								//END LOGGED USER ROUTES
							}

							{
							//ADMIN ROUTES
							}
							<Route
								path="/AdminScreen/stats"
								element={userInfo ? <AdminStatsScreen /> : <Navigate to="/" />}
							></Route>
							<Route
								path="/AdminScreen/newUser"
								element={userInfo ? <AddUser /> : <Navigate to="/" />}
							></Route>
							<Route
								path="/AdminScreen/user/:id"
								element={userInfo ? <EditUser /> : <Navigate to="/" />}
							></Route>
							<Route
								path="/AdminScreen/editUser/:id"
								element={userInfo ? <EditUser /> : <Navigate to="/" />}
							></Route>
							<Route
								path="/AdminScreen/editBookingTemplate/:id"
								element={userInfo ? <EditBookingTemplate /> : <Navigate to="/" />}
							></Route>
							<Route
								path="/AdminScreen/users/details/:id"
								element={<UserDetails/>}
							/>
							<Route
								path="/AdminScreen/users"
								element={userInfo ? <UsersList /> : <Navigate to="/" />}
							></Route>
							<Route
								path="/AdminScreen/membership-templates"
								element={userInfo ? <MembershipTemplatesList /> : <Navigate to="/" />}
							></Route>
							<Route
								path="/AdminScreen/userbookinglist/:id"
								element={userInfo ? <UserMembershipsList /> : <Navigate to="/" />}
							></Route>
							<Route
								path="/AdminScreen/userbookingdetails/:id"
								element={userInfo ? <UserBookingDetails /> : <Navigate to="/" />}
							></Route>
							<Route
								path="/AdminScreen/userattendees/:id"
								element={userInfo ? <UserAttendees /> : <Navigate to="/" />}
							></Route>
							<Route
								path="/AdminScreen/newBookingTemplate"
								element={userInfo ? <AddBookingTemplate /> : <Navigate to="/" />}
							></Route>
							<Route
								path="/AdminScreen/Config"
								element={userInfo ? <AdminConfigScreen /> : <Navigate to="/" />}
							></Route>
							<Route
								path="/AdminScreen/WODs"
								element={userInfo ? <WODList /> : <Navigate to="/" />}
							></Route>
							<Route
								path="/AdminScreen/exercises"
								element={userInfo ? <ExerciseList /> : <Navigate to="/" />}
							></Route>
							<Route
								path="/AdminScreen/edituserbooking/:id"
								element={userInfo ? <EditUserBooking /> : <Navigate to="/" />}
							></Route>
							<Route
								path="/AdminScreen/WODs"
								element={userInfo ? <WODList /> : <Navigate to="/" />}
							></Route>
							<Route
								path="/AdminScreen/weeklyTemplates"
								element={userInfo ? <WeeklyTemplatesList /> : <Navigate to="/" />}
							></Route>
							<Route
								path="/AdminScreen/editWOD/:id"
								element={userInfo ? <EditWOD /> : <Navigate to="/" />}
							></Route>
							<Route
								path="/AdminScreen/eventList"
								element={userInfo ? <AdminEventList /> : <Navigate to="/" />}
							></Route>
							<Route
								path="/AdminScreen/detailEvent/:id"
								element={userInfo ? <DetailEvent /> : <Navigate to="/" />}
							></Route>
							<Route
								path="/userEventDetails/:id"
								element={userInfo ? <UserEventDetails /> : <Navigate to="/" />}
							></Route>
							<Route
								path="/AdminScreen/debts"
								element={userInfo ? <DebtList /> : <Navigate to="/" />}
							></Route>
							<Route
								path="/AdminScreen/debts/userDebtsList/:id"
								element={userInfo ? <UserDebtsList /> : <Navigate to="/" />}
							></Route>
							<Route
								path="/AdminScreen/debts/newpayment/:id"
								element={userInfo ? <NewPayment /> : <Navigate to="/" />}
							></Route>
							<Route
								path="/AdminScreen/debts/payments"
								element={userInfo ? <PaymentsList /> : <Navigate to="/" />}
							></Route>
							<Route
								path="/AdminScreen/debts/payments/:userId"
								element={userInfo ? <UserPaymentsList /> : <Navigate to="/" />}
							></Route>
							<Route
								path="/AdminScreen/newRm/:id"
								element={userInfo ? <NewRM /> : <Navigate to="/" />}
							></Route>
							<Route
								path="/AdminScreen/editRm/:id"
								element={userInfo ? <EditRM /> : <Navigate to="/" />}
							></Route>

							{
								//END ADMIN ROUTES
							}
							<Route path="/cart/*" element={<CartRoutes productType="membershipTemplate" />} />
						</Routes>
					</main>
				</div>
			</BrowserRouter>
		</Suspense>
	);
}

export default App;

