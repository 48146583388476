import restClient from '../restClient';
import authHeader from './auth-header';
import { handleErrors } from './common.service';

const API_URL = '/colors';

class ColorService {
	async getColors() {
		try {
			const response = await restClient.get(API_URL, {
				headers: authHeader()
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}

	async updateColors(colors) {
		try {
			const response = await restClient.put(API_URL, colors, {
				headers: authHeader()
			});
			return response.data;
		} catch (error) {
			handleErrors(error);
		}
	}
}

export default new ColorService();
