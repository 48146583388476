import React, { useState, useLayoutEffect } from 'react';
import wodService from '../../../services/wod.service';
import colorService from '../../../services/color.service';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { getError } from '../../../utils';
import moment from 'moment';
import useExercises from '../../../hooks/useExercises';
import useQueryFilters from '../../../hooks/useQueryFilters';
import ExerciseEditor from '../../AdminExercises/ExerciseEditor/ExerciseEditor';
import './WodEditor.css';
import WodDataForm from './WodDataForm/WodDataForm';
import SlidingTabs from '../../../components/SlidingTabs/SlidingTabs';
import Exercise from '../../../classes/Exercise';
import WodImage from './WodImage/WodImage';
import { WOD } from '../../../classes/WOD';
import { generateImage } from './wodEditorHelpers';
import { wodsCategories, wodsTypes } from '../../../utils/lists';


function WodEditor({ show, onHide }) {
	const { searchParams } = useQueryFilters({ baseUrl: '/AdminScreen/WODs' });
	const { exercises, reloadExercises } = useExercises(searchParams);

	const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);

	const openEditor = () => setConfirmModalIsOpen(true);
	const closeEditor = () => {
		setConfirmModalIsOpen(false);
		setSelectedExercise(null);
	};
	const [loadingNextStep, setLoading] = useState(false);

	const [wodState, setWODState] = useState(WOD.createEmptyWOD());
	const [imageData, setImageData] = useState(null);
	const [fileUrl, setFileUrl] = useState(wodState.image?.fileLink ?? '');
	const [imageFileBlob, setImageFileBlob] = useState(null);
	const [exercisesList, setExercisesList] = useState([{ ...Exercise.createEmpty(), validationErrors: {} }]);
	const [colorsList, setColorsList] = useState([]);
	const [selectedExercise, setSelectedExercise] = useState([]);
	const [activeIndex, setActiveIndex] = useState(0);
	const [basicDataIsValidated, setBasicDataIsValidated] = useState(false);
	const [imageOption, setImageOption] = useState('generate');


	const fetchColors = async () => {
		try {
			const colors = await colorService.getColors();
			setColorsList(colors);
		} catch (error) {
			console.error('No se pudo obtener los colores', error);
		}
	};

	//reset all states and close
	const hide = () => {
		setConfirmModalIsOpen(false);
		setLoading(false);
		setWODState(WOD.createEmptyWOD());
		setImageData(null);
		setFileUrl(null);
		setExercisesList([{ ...Exercise.createEmpty(), validationErrors: {} }]);
		setColorsList([]);
		setSelectedExercise([]);
		setActiveIndex(0);
		setBasicDataIsValidated(false);
		onHide();
	};


	useLayoutEffect(() => {
		fetchColors();
	}, []);

	const getExercisesFormErrors = () => {
		const updatedExercises = [...exercisesList];
		setBasicDataIsValidated(true);
		const exercises = updatedExercises.map(cls => {
			const { exercise, reps, weight } = cls;
			const validationErrors = {};
			if (!exercise) {
				validationErrors.exercise = 'El ejercicio es obligatorio';
			}
			if (typeof exercise != 'object') {
				validationErrors.exercise = 'Ingrese un ejercicio de la lista.';
			}
			if (!reps || isNaN(reps) || reps <= 0) {
				validationErrors.reps = 'Se requiere un número no negativo';
			}
			if (weight && (isNaN(weight) || weight < 0)) {
				validationErrors.weight = 'Se requiere un número no negativo';
			}
			return { ...cls, validationErrors };
		});

		return exercises;
	};

	const processWODImage = (file) => {
		setFileUrl(URL.createObjectURL(file)); // Save the image src in a state
		setImageFileBlob(file);
	};

	const uploadWODImage = async () => {
		const file = imageOption === 'generate' ? getFileFromGeneratedImage() : imageFileBlob;
		try {
			const formData = new FormData();
			formData.append('file', file, `WOD${moment().format('YYYYMMDDhhmmss')}`);
			const response = await wodService.uploadWodImage(formData);
			return response._id;
		} catch (ex) {
			toast.error(getError(ex));
		}
	};

	const nextStepHandler = async () => {
		if (activeIndex === 0) {
			submitWodDetails();
		} else {
			addWod();
		}
	};

	const submitWodDetails = async () => {
		setLoading(true);
		const validatedExercises = getExercisesFormErrors();
		if (wodState.validateBasicData() && wodsCategories.includes(wodState.category[0]) && wodsTypes.includes(wodState.type[0])) {
			if (exercisesList.length > 0) {
				const exercisesHaveErrors = validatedExercises.some(cls => Object.keys(cls.validationErrors).length);
				setExercisesList(validatedExercises);
				if (!exercisesHaveErrors) {
					const wodWithSameName = await wodService.getWodByName(wodState.name);
					if (!wodWithSameName) { 
						const img = await generateImage(wodState.name, wodState.type[0], wodState.category[0], wodState.rounds,
							exercisesList, colorsList, wodState);
						setImageData(img);
						setActiveIndex(1);
					} else {
						toast.error('Ya existe un WOD con ese nombre');
					}
				} else {
					toast.error('Hay errores en los ejercicios');
				}
			} else {
				toast.error('Debes especificar al menos 1 ejercicio');
			}
		} else {
			toast.error('Debes completar la información básica');
		}
		setLoading(false);
	};

	const addWod = async () => {
		setLoading(true);
		try {
			const img = await uploadWODImage();
			await wodService.createWod(wodState.name, wodState.description, img, wodState.timeCap, wodState.rounds,
				wodState.category[0], wodState.type[0],
				exercisesList.map(e => ({ exercise: e.exercise[0]._id, reps: e.reps, weight: e.weight })), false, wodState.restBetweenRounds);
			toast.success('WOD creado');
			hide();
		} catch (ex) {
			toast.error(getError(ex));
		}
		setLoading(false);
	};

	//add exercise to the list in the wod
	const handleAddExercise = () => {
		setExercisesList([
			...exercisesList,
			{ ...Exercise.createEmpty(), validationErrors: {} }
		]);
	};
	//remove exercise to the list in the wod
	const handleRemoveExercise = (index) => {
		const updatedExercisesList = exercisesList.filter((_, i) => i !== index);
		setExercisesList(updatedExercisesList);
	};

	const handleExerciseDetailChange = (index, field, value) => {
		const updatedExercisesList = [...exercisesList];
		updatedExercisesList[index][field] = value;
		setExercisesList(updatedExercisesList);
	};

	const previousStepHandler = () => {
		if (activeIndex === 1) {
			setFileUrl('');
			setActiveIndex(0);
		} else {
			onHide();
		}
	};

	const getFileFromGeneratedImage = () => {
		const byteString = atob(imageData.split(',')[1]);
		const mimeString = imageData.split(',')[0].split(':')[1].split(';')[0];

		const ab = new ArrayBuffer(byteString.length);
		const ia = new Uint8Array(ab);
		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}
		const blob = new Blob([ab], { type: mimeString });
		return new File([blob], `WOD${moment().format('YYYYMMDDhhmmss')}.png`, { type: mimeString });
	};
	const setWodValue = (field, value) => {
		setWODState(WOD.create({ ...wodState, [field]: value }));

	};
	const onSuccessCreate = () => {
		reloadExercises();
	};
	const options = [
		{
			option: 'basic-data', cmp: WodDataForm, props: {
				wod: wodState, setWodValue,
				exercisesList, handleExerciseDetailChange, handleRemoveExercise, exercises,
				selectedExercise, handleAddExercise, openEditor,
				validated: basicDataIsValidated
			}
		},
		{
			option: 'image', cmp: WodImage,
			props: { imageData, fileUrl, processWODImage, imageOption, setImageOption }
		}
	];

	return (
		<>
			<Modal size="xl" show={show} onHide={hide} animation={false} dialogClassName='wod-editor'>
				<Modal.Header closeButton>
					<Modal.Title>{activeIndex === 0 ? 'Crear WOD' : 'Imagen'}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<SlidingTabs loading={loadingNextStep} activeIndex={activeIndex} showTabTitles={false} options={options} />
				</Modal.Body>
				<Modal.Footer className='d-flex justify-content-around'>
					<button disabled={loadingNextStep} className='admin-button dark' onClick={previousStepHandler}>
						Atrás
					</button>
					<button disabled={loadingNextStep} className='admin-button' onClick={nextStepHandler}>
						{activeIndex === 0 ? 'Siguiente' : 'Guardar'}
					</button>
				</Modal.Footer>
			</Modal>
			<ExerciseEditor exerciseParam={selectedExercise}
				show={confirmModalIsOpen} close={closeEditor} success={onSuccessCreate} />;


		</>
	);
}
export default WodEditor;
