import React, { useContext } from 'react';
import { Store } from '../Store';
import { Navigate } from 'react-router-dom';
import { hasPermission } from '../utils';

export default function PermissionWrapper({
	children,
	fn,
	scopes,
	field = '',
	screen = false,
}) {
	const { state } = useContext(Store);
	const { userInfo } = state;
	let permissionGranted = false;
	let content = <>{children}</>;
	if (
		userInfo &&
  userInfo.role &&
  fn.length &&
  Array.isArray(scopes) &&
  scopes.length
	) {
		permissionGranted = hasPermission(userInfo.role, fn, scopes, field);
	}
	if (!permissionGranted) {
		if (!screen) {
			content = <></>;
		} else {
			content = <Navigate to={'/'} />;
		}
	}

	return content;
}
