/* eslint-disable no-case-declarations */
import React, { createContext, useReducer, useEffect } from 'react';
import { safelyParseJSON } from './utils';

export const Store = createContext();
// Initial state without USE_STOCK
const initialState = {
	userInfo: safelyParseJSON(localStorage.getItem('userInfo')),
	cart: {
		cartIsOpen: false,
		shippingAddress: safelyParseJSON(localStorage.getItem('shippingAddress'), {}),
		cartItems: safelyParseJSON(localStorage.getItem('cartItems'), []),
		paymentMethod: safelyParseJSON(localStorage.getItem('paymentMethod'), ''),
		seller: safelyParseJSON(localStorage.getItem('seller'), ''),
		discountCoupon: safelyParseJSON(localStorage.getItem('discountCoupon'), 0),
		paymentProof: [] 
	},
	companyData: safelyParseJSON(localStorage.getItem('companyData')),
	usesShipping: safelyParseJSON(localStorage.getItem('usesShipping')),
	useStock: false, 
	loadingOverlay: false,
	navIsOpen: false
};

function reducer(state, action) {	
	switch (action.type) {
	case 'OPEN_CART':
		return { ...state, cart: { ...state.cart, cartIsOpen: true } };
	case 'CLOSE_CART':
		return { ...state, cart: { ...state.cart, cartIsOpen: false } };
	case 'SHOW_LOADING_OVERLAY':
		return { ...state, loadingOverlay: true };
	case 'HIDE_LOADING_OVERLAY':
		return { ...state, loadingOverlay: false };
	case 'CART_ADD_ITEM':
		const newItem = action.payload;
		const existingItemIndex = state.cart.cartItems.findIndex((item) => item._id === newItem._id);
		let cartItems;
		if (existingItemIndex >= 0) {
			state.cart.cartItems[existingItemIndex] = newItem;
			cartItems = state.cart.cartItems;
		} else {
			cartItems = [...state.cart.cartItems, newItem];
		}
		localStorage.setItem('cartItems', JSON.stringify(cartItems));
		return { ...state, cart: { ...state.cart, cartItems } };

	case 'CART_REMOVE_ITEM':
		const removeItem = action.payload;
		const updatedCartItems = state.cart.cartItems.filter(item => item._id !== removeItem._id);
		localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
		return { ...state, cart: { ...state.cart, cartItems: updatedCartItems } };

	case 'CART_CLEAR':
		return { ...state, cart: { ...state.cart, cartItems: [] } };
	case 'OPEN_NAV': 
		return { ...state, navIsOpen: true };
	case 'CLOSE_NAV':
		return { ...state, navIsOpen: false };
	case 'USER_SIGNIN':
		return { ...state, userInfo: action.payload };

	case 'USER_SIGNOUT':
		localStorage.removeItem('userInfo');
		localStorage.removeItem('refreshToken');
		localStorage.clear();
		return {
			...state,
			userInfo: null,
			cart: {
				cartItems: [],
				shippingAddress: {},
				paymentMethod: '',
			},
		};
	case 'LOAD_PUBLIC_DATA':
		return {...state, companyData: action.payload};
	case 'SAVE_SHIPPING_ADDRESS':
		return {
			...state,
			cart: {
				...state.cart,
				shippingAddress: action.payload,
			},
		};

	case 'CLEAR_SHIPPING_ADDRESS':
		return {
			...state,
			cart: {
				...state.cart,
				shippingAddress: null,
			},
		};

	case 'SAVE_PAYMENT_METHOD':
		localStorage.setItem('paymentMethod', JSON.stringify(action.payload));
		return {
			...state,
			cart: {
				...state.cart,
				paymentMethod: action.payload,
			},
		};

	case 'SAVE_PAYMENT_PROOF':
		return {
			...state,
			cart: {
				...state.cart,
				paymentProof: action.payload,
			},
		};

	case 'SAVE_ORDER_DELIVERY_DETAILS':
		return {
			...state,
			cart: {
				...state.cart,
				orderDeliveryDetails: action.payload,
			},
		};

	case 'CLEAR_ORDER_DELIVERY_DETAILS':
		return {
			...state,
			cart: {
				...state.cart,
				orderDeliveryDetails: null,
			},
		};

	case 'SAVE_ORDER_SELLER':
		return {
			...state,
			cart: {
				...state.cart,
				seller: action.payload,
			},
		};

	case 'CLEAR_ORDER_SELLER':
		return {
			...state,
			cart: {
				...state.cart,
				seller: null,
			},
		};

	case 'SAVE_ORDER_COUPON':
		return {
			...state,
			cart: {
				...state.cart,
				discountCoupon: action.payload,
			},
		};

	case 'CLEAR_ORDER_COUPON':
		return {
			...state,
			cart: {
				...state.cart,
				discountCoupon: null,
			},

		};
		
	case 'SET_USE_STOCK':
		return {
			...state,
			useStock: action.payload,
		};
	default:
		return state;
	}
}

export function StoreProvider(props) {
	const [state, dispatch] = useReducer(reducer, initialState);

	useEffect(() => {
		const useStockValue = process.env.USE_STOCK === 'true';
		dispatch({ type: 'SET_USE_STOCK', payload: useStockValue });
	}, []);

	const value = { state, dispatch };
	return <Store.Provider value={value}>{props.children}</Store.Provider>;
}
