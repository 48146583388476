import React, { useReducer, useState ,useEffect,useContext} from 'react';
import wodService from '../../services/wod.service';

import { Helmet } from 'react-helmet-async';
import {
	Button,
	Modal,
	Form,
	Container,
	Row,
	Col,
	Card,
	Stack
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { getError } from '../../utils';
import LoadingBox from '../../components/LoadingBox';
import DateTimePicker from 'react-datetime-picker';
import { FaPlusCircle,FaRegTrashAlt } from 'react-icons/fa';
import moment from 'moment';
import { Typeahead } from 'react-bootstrap-typeahead';
import resultsService from '../../services/results.service';
import { toast } from 'react-toastify';
import { Store } from '../../Store';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, WOD: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'CREATE_REQUEST':
		return { ...state, loadingCreate: true };
	case 'CREATE_SUCCESS':
		return {
			...state,
			loadingCreate: false
		};
	case 'CREATE_FAIL':
		return { ...state, loadingCreate: false };

	default:
		return state;
	}
};

function AddUserResults() {
	const navigate = useNavigate();
	const { state: contextState } = useContext(Store);
	const { userInfo } = contextState;
	
	const [reducerState, dispatch] = useReducer(reducer, {
		loadingFetch: true,
		loadingCreate: false,
		error: '',
		WOD: {},
	});
	

	const { loadingFetch, loadingCreate} = reducerState;
	const [selectedExercise, setSelectedExercise] = useState([]);
	const [exercisesList, setExercisesList] = useState([]);
	const [exercises, setExercises] = useState([]);
	const [wod, setWod] = useState();
	const [date, setDate] = useState(new Date());
	const [reps, setReps] = useState('');
	const [weight, setWeight] = useState(0);
	const [times, setTimes] = useState('');
	const [rounds, setRounds] = useState('');
	
	const [showModal, setShowModal] = useState(false);
	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);


	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await wodService.getWodByDate(date);
				setExercises(
					result.wodExercises.map((item) => ({ name: item.exercise.name, _id: item.exercise._id})));
				setWod(result);
				dispatch({ type: 'FETCH_SUCCESS', payload: result });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
		};
		fetchData();
	}, [date]);
	




	const handleDateChange = (newDate) => {
		const momentDate = moment(newDate);
		momentDate.startOf('day');
		const updatedDate = moment(momentDate).format('DD/MM/YYYY');
		setDate(updatedDate);
	};
	


	//add exercise to the list in the wod
	const handleAddExercise = () => {
		if (selectedExercise.length > 0 && reps && weight) {
			setExercisesList([
				...exercisesList,
				{ exercise: selectedExercise[0], reps, weight, rounds, times}
			]);
			setSelectedExercise([]); 
			setReps(''); 
			setWeight(0);
			setRounds('');
			setTimes('');
		}
	};
	//remove exercise to the list in the wod
	const handleRemoveExercise = (index) => {
		const updatedExercisesList = exercisesList.filter((_, i) => i !== index);
		setExercisesList(updatedExercisesList);
	};

	const handleExerciseDetailChange = (index, field, value) => {
		const updatedExercisesList = [...exercisesList];
		updatedExercisesList[index][field] = value;
		setExercisesList(updatedExercisesList);
	};

	const addResultsHandler = async () => {
		if (!date || !exercisesList || !wod || !userInfo) {
			toast.error('Para agregar resultados debe completar la informacion solicitada');
			return;
		}
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			await resultsService.createResults(wod, exercisesList,userInfo);		
			toast.success('Resultados ingresados');
			navigate('/');
		} catch (ex) {
			toast.error(getError(ex));
		}
		handleClose();
	};
	

	
	
	return (
		<div>
			<div>
				{loadingCreate && loadingFetch  && <LoadingBox></LoadingBox>}
				<Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
					<Modal.Header closeButton>
						<Modal.Title>Crear WOD</Modal.Title>
					</Modal.Header>
					<Modal.Body>Seguro desea crear WOD?</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							Cancelar
						</Button>
						<Button onClick={addResultsHandler}>Confirmar</Button>
					</Modal.Footer>
				</Modal>
			</div>
			<Container className="large-container">
				<Helmet>
					<title>Agregar resultados WOD</title>
				</Helmet>
				<Container fluid>
					<Row className="vh-100 d-flex justify-content-center align-items-center">
						<Col md={8} lg={6} xs={12}>
							<Card className="shadow p-1">
								<Card.Body>
									<div className="mb-3 mt-md-4">
										<h2 className="fw-bold mb-4 text-uppercase text-align-center ">
										Agregar resultados Ejercicios
										</h2>
										<div className="mb-3">
											<Form onSubmit={(e) => e.preventDefault()} className='col'>
												<Row>
													<Form.Group className="mb-3 col-lg-4" controlId="fecha">
														<Form.Label>Fecha</Form.Label>
														<br></br>
														<DateTimePicker
															className="timePicker"
															onChange={handleDateChange}
															value={date}
															clearIcon={null}
															required={true}
															disableClock={true}
															format="dd-MM-y"
															minDate={new Date()}
														/>
													</Form.Group>
												</Row>
												<Card className='m-3 p-4'>
													<h2 className='mb-3'>Lista de ejercicios</h2>
													<Row>
														<Form.Group className="col-lg-2">
															<Form.Label>Ejercicio</Form.Label>
															<Typeahead
																id="exercise-search"
																options={[
																	...exercises,
																]}
																placeholder="Seleccione un ejercicio"
																labelKey="name"
																selected={selectedExercise}
																onChange={(selected) => {
																	if (selected[0] && selected[0]._id === 'add-new') {
																		navigate('/AdminScreen/exercises');
																	} else {
																		setSelectedExercise(selected);
																	}
																}}
															/>
														</Form.Group>
														<Form.Group className="col-lg-2">
															<Form.Label>Reps</Form.Label>
															<Form.Control
																type="number"
																value={reps}
																onChange={(e) => setReps(e.target.value)}
															/>
														</Form.Group>
														<Form.Group className="col-lg-2">
															<Form.Label>Peso</Form.Label>
															<Form.Control
																type="number"
																value={weight}
																onChange={(e) => setWeight(e.target.value)}
															/>
														</Form.Group>
														<Form.Group className="col-lg-2">
															<Form.Label>Times</Form.Label>
															<Form.Control
																type="number"
																value={times}
																onChange={(e) => setTimes(e.target.value)}
															/>
														</Form.Group>
														<Form.Group className="col-lg-2">
															<Form.Label>Rounds</Form.Label>
															<Form.Control
																type="number"
																value={rounds}
																onChange={(e) => setRounds(e.target.value)}
															/>
														</Form.Group>
														<Col lg={2} className="d-flex align-items-end">
															<Button
																variant="primary"
																onClick={handleAddExercise}
																disabled={!selectedExercise.length || !reps || !weight}
															>
																<FaPlusCircle/>
															</Button>
														</Col>
													</Row>
													{exercisesList.map((item, index) => (
														<Row key={index} className="mb-3">
															<Form.Group className="col-lg-2">
																<Form.Label>Ejercicio</Form.Label>
																<Form.Control
																	type="text"
																	value={item.exercise.name}
																	readOnly
																/>
															</Form.Group>
															<Form.Group className="col-lg-2">
																<Form.Label>Reps</Form.Label>
																<Form.Control
																	type="number"
																	value={item.reps}
																	onChange={(e) =>
																		handleExerciseDetailChange(index, 'reps', e.target.value)
																	}
																/>
															</Form.Group>
															<Form.Group className="col-lg-2">
																<Form.Label>Peso</Form.Label>
																<Form.Control
																	type="number"
																	value={item.weight}
																	onChange={(e) =>
																		handleExerciseDetailChange(index, 'weight', e.target.value)
																	}
																/>
															</Form.Group>
															<Form.Group className="col-lg-2">
																<Form.Label>Times</Form.Label>
																<Form.Control
																	type="number"
																	value={item.times}
																	onChange={(e) =>
																		handleExerciseDetailChange(index, 'times', e.target.value)
																	}
																/>
															</Form.Group>
															<Form.Group className="col-lg-2">
																<Form.Label>Rounds</Form.Label>
																<Form.Control
																	type="number"
																	value={item.rounds}
																	onChange={(e) =>
																		handleExerciseDetailChange(index, 'rounds', e.target.value)
																	}
																/>
															</Form.Group>
															<Col lg={2} className="d-flex align-items-end">
																<Button
																	variant="primary"
																	onClick={() => handleRemoveExercise(index)}
																>
																	<FaRegTrashAlt/>
																</Button>
															</Col>
														</Row>
													))}
												</Card>
												<Stack direction="horizontal" gap={10}>
													<Button id="goBack" onClick={() => navigate(-1)}>
														<BsArrowLeftCircle /> Atras
													</Button>

													<Button type="submit" className="mx-auto" onClick={handleShow}>
														Agregar Resultados
													</Button>
												</Stack>
											</Form>
										</div>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</Container>
		</div>
	);
}
export default AddUserResults;