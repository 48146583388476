import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getFilterUrl } from '../utils';

export default function useQueryFilters({ baseUrl, resizeWidth, paginate = true, initFields = {}}) {
	const { search } = useLocation();
	const navigate = useNavigate();
	const searchParams = new URLSearchParams(search);
	const [siblingCount, setSiblingCount] = useState(1);

	
	const getFields = (fields = {}) => {
		const initFields = {...fields, ...Object.fromEntries(searchParams)};
		if (paginate) {
			initFields.page = parseInt(searchParams.get('page')) || 1;
			initFields.pageSize = parseInt(searchParams.get('pageSize')) || 16;
		}
		return initFields;
	};
	
	const [fields, setFields] = useState(getFields(initFields));

	/**
     * Sends data loaded into filters to the server
     */
	const submitFilters = (fieldsToSubmit = {}) => {
		const params = { ...fields, ...fieldsToSubmit};
		if(paginate) {
			params.page = 1;
		}
		const query = getFilterUrl(params);
		navigate(query ? `${baseUrl}?${query}` : baseUrl);
	};
	/**
     * Sets the fields and sends the data loaded into filters directly to the server
     */

	const setPageSize = (size) => {
		const query = getFilterUrl({ ...fields, pageSize: size });
		navigate(`${baseUrl}?${query}`);
	};
	const setPage = (page) => {
		const query = getFilterUrl({ ...fields, page });
		navigate(`${baseUrl}?${query}`);
	};

	const handleResize = () => {
		setSiblingCount(window.innerWidth >= resizeWidth ? 1 : 0);
	};

	useEffect(() => {
		setFields(getFields());
	}, [search]);

	useEffect(() => {
		if (!searchParams.size && Object.keys(fields).length) {
			submitFilters();
		}
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return { fields, siblingCount, search,  searchParams, submitFilters, setPageSize, setPage, setFields };
}
