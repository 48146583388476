import Card from 'react-bootstrap/Card';
import React from 'react';
import './PaymentMethodData.css';
import DOMPurify from 'dompurify';

export default function PaymentMethodData({ details }) {
	const createMarkup = (html) => {
		return {
			__html: DOMPurify.sanitize(html),
		};
	};

	return (
		<Card className="mb-3 payment-method-data">
			<Card.Body>
				<Card.Text>
					<div dangerouslySetInnerHTML={createMarkup(details)}></div>
				</Card.Text>
			</Card.Body>
		</Card>
	);
}
