import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import _utils from '../../../services/utils.service';
import _settings from '../../../services/settings.service';
import PaymentMethod from '../../../classes/PaymentMethod';

export default function NewPaymentMethodModal({
	show,
	handleClose,
	edit,
	editMethod,
}) {
	const [method, setMethod] = useState('');
	const [active, setActive] = useState(false);
	const [uploadAttachment, setUploadAttachment] = useState(false);
	const [details, setDetails] = useState('');
	const [lockActive, setLockActive] = useState(false);
	const [usesPaymentGateway, setUsesPaymentGateway] = useState(false);
	const [chargePercent, setChargePercent] = useState(0);


	useEffect(() => {
		if (edit) {
			if(editMethod.method.toLowerCase() === 'mercadopago') {
				checkMercadopago();
			}
			setMethod(editMethod.method);
			setActive(editMethod.active);
			setUploadAttachment(editMethod.uploadAttachment);
			setDetails(editMethod.details);
			setUsesPaymentGateway(editMethod.details);
			setChargePercent(editMethod.chargePercent || 0);
		}
	}, [edit]);

	const handleNewMethod = () => {
		if (method) {
			saveNewMethod();
		} else {
			toast.error('Nombre del método es requerido.');
		}
	};
	const checkMercadopago = async () => {
		try {
			const res = await _settings.checkShippingUsage();
      
			if (typeof res.data === 'string' && res.data.trim().length === 0) {
				setLockActive(true);
			} else if (typeof res.data === 'object' && res.data && res.data.usesShipping) {
				setLockActive(true);
			} else {
				console.warn('Unexpected response data type:', typeof res.data);
			}
		} catch (ex) {
			console.error('Error checking MercadoPago usage:', ex);
			toast.error('Error al verificar el uso de MercadoPago.');
		}
	};

	const saveNewMethod = async () => {
		if (method.toLowerCase() !== 'mercadopago' && PaymentMethod.validate(method)) {
			try {
				await _utils.createMethod(method, details, active, uploadAttachment, chargePercent, usesPaymentGateway); 
				toast.success('Metodo creado');
				handleClose();
			} catch (ex) {
				toast.error('Error. Intentelo de nuevo.');
				console.error(ex);
			}
		} else if (method.toLowerCase() !== 'mercadopago') {
			toast.error('Error. MercadoPago esta integrado por defecto.');
		} else {
			toast.error('Error. Verifique los datos.');
		}
	};
  
	const handleEditMethod = async () => {
		if (PaymentMethod.validate(method)) {
			try {
				await _utils.editMethod(editMethod._id, method, details, active, uploadAttachment, chargePercent, usesPaymentGateway); 
				toast.success('Cambios guardados.');
				handleClose();
			} catch (ex) {
				toast.error('Error');
				console.error(ex);
			}
		} else {
			toast.error('Error. Revse los datos');
		}
	};
  
	return (
		<Modal show={show} onHide={handleClose} dialogClassName="modal-pay">
			<Modal.Header closeButton>
				<Modal.Title>{edit ? 'Editar metodo' : 'Nuevo metodo'}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form className="row justify-content-start align-items-end" onSubmit={(e) => e.preventDefault()}>
					<Form.Group className="col-6 mb-2" controlId="newMethod">
						<Form.Control
							onChange={(e) => setMethod(e.target.value)}
							required
							readOnly={edit && editMethod.method == 'MercadoPago'}
							placeholder="Método"
							defaultValue={edit ? editMethod.method : ''}
						></Form.Control>
					</Form.Group>
					<Form.Group className="col-6 mb-2" controlId="newActive">
						<Form.Check
							type="checkbox"
							label="Activo"
							onChange={(e) => setActive(e.target.checked)}
							disabled={edit && editMethod.method == 'MercadoPago' && lockActive}
							defaultChecked={edit ? editMethod.active : false}
						/>
					</Form.Group><br />
					<div className="row mb-2 mt-2">
						<Form.Group className="col-5" controlId="newComprobante">
							<Form.Check
								type="checkbox"
								label="Comprobante"
								onChange={(e) => setUploadAttachment(e.target.checked)}
								disabled={edit && editMethod.method === 'MercadoPago'}
								defaultChecked={edit ? editMethod.uploadAttachment : false}
							/>
						</Form.Group>
						<Form.Group className="col-6 d-flex align-items-center" controlId="newChargePercent">
							<Form.Label className="col-6 mb-0">% Comisión: </Form.Label>
							<Form.Control
								className="col-5"
								type="number"
								min="0"
								max="100"
								step="0.01"
								value={chargePercent}
								onChange={(e) => setChargePercent(e.target.value)}
								style={{ width: '60%' }}
							/>
						</Form.Group>
					</div>


					<Form.Group className="col-11" controlId="newDetails">
						<Form.Control
							onChange={(e) => setDetails(e.target.value)}
							defaultValue={edit ? editMethod.details : ''}
							readOnly={edit && editMethod.method == 'MercadoPago'}
							className="payment-details"
							as="textarea"
							placeholder="Detalles"
						></Form.Control>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer className="justify-content-end">
				<Button
					className="m-0"
					variant="primary"
					onClick={editMethod ? handleEditMethod : handleNewMethod}
				>
          Guardar
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
