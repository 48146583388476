import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import './CardTab.css';

function CardTabs({ options }) {

	const navigate = useNavigate();
	
	const [activeTab, setActiveTab] = useState(0);

	const [isMobile, setIsMobile] = useState(false);


	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 576); 
		};

		handleResize();
		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const handleCardClick = (option) => {
	
		setActiveTab(option);
		navigate(`./${option.navTag?.toLowerCase()}`);
	};

	return (
		<div className="card-tabs container my-4">
			<div className="row cards-cont">
				{options.map((option, index) => (
					<div className="col-lg-4 col-md-6 col-sm-6 card-cont" key={index}>
						<div
							className={classnames('cardTab', { active: activeTab.option === option.option })}
							onClick={() => handleCardClick(option)}
							style={{ cursor: 'pointer' }}
						>
							<img
								src={option.image}
								className="card-img-top"
								alt={option.option}
							/>
							<div className="card-img-overlay d-flex justify-content-center align-items-center">
								<h5 className="text-white text-card-tab">{option.option}</h5>
							</div>
						</div>
					</div>
				))}
			</div>
			{!isMobile && (
				<div className="tab-content mt-4">
					{options.map((option) => (
						<div
							className={classnames('content', { active: activeTab.option === option.option })}
							key={option.option}
						>
							{activeTab.option === option.option && <option.cmp />}
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default CardTabs;

