import React, { useContext, useEffect, useRef } from 'react';
import classnames from 'classnames';
import './SideNav.css';
import { Store } from '../../Store';
import { RxCross2 } from 'react-icons/rx';
import NavbarOptions from '../NavbarOptions/LoggedOptions/NavbarOptions.jsx';


export default function SideNav() {
	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { navIsOpen } = state;

	const closeNav = () => ctxDispatch({ type: 'CLOSE_NAV' });
	const bodyRef = useRef(null);

	const handleKeyPress = (e) => {
		if (e.key === 'Escape') {
			closeNav();
		}
	};

	useEffect(() => {
		if (navIsOpen) {
			window.addEventListener('keyup', handleKeyPress);
		} else {
			window.removeEventListener('keyup', handleKeyPress);
		}
		return () => {
			window.removeEventListener('keyup', handleKeyPress);
		};
	}, [navIsOpen, bodyRef, handleKeyPress]);

	//#region NAVBAR COLLAPSE METHODS
	const collapse = () => {
		ctxDispatch({ type: 'CLOSE_NAV' });
	};

	return (
		<>
			<div id='side-nav-overlay' onClick={closeNav}
				className={classnames('', { 'd-block': navIsOpen })}>
			</div>
			<div id="side-nav" className={classnames('', { show: navIsOpen })}>
				<div className='header'>
					<button className='transparent' onClick={closeNav}>
						<RxCross2/>
					</button>
				</div>
				<div className='body' ref={bodyRef}>
					<NavbarOptions handleCollapse={collapse}/>
					{/* {
						cart.cartItems.toReversed().map((item, i) => (
							<CartItem canChangeQuantity canDeleteItem key={item._id + i} item={item} removeItemHandler={removeItemHandler} />

						))
					} */}
				</div>
			</div>
		</>
	);
}
