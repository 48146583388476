  
import React, { useContext, useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import { Helmet } from 'react-helmet-async';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Store } from '../../../../Store.js';
import { useNavigate } from 'react-router-dom';
import PaymentMethodData from '../../components/PaymentMethodData/PaymentMethodData.jsx';
import LoadingBox from '../../components/LoadingBox/LoadingBox.jsx';
import Badge from 'react-bootstrap/Badge';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';

import { toast } from 'react-toastify';
import _utils from '../../services/utils.service.js';
import _discountCodes from '../../services/discountcodes.service.js';
import CartSummary from '../../screens/Cart/CartSummary/CartSummary.jsx';
import CheckoutNavigator from '../../components/CheckoutNavigator/CheckoutNavigator.jsx';
import './PaymentMethodScreen.css';
import { getSubtotal, getTaxAmount, getPaymentMethodCharge } from '../../pricesUtils.js';

function PaymentMethodScreen() {
	const navigate = useNavigate();
	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { cart, usesShipping } = state;
	cart.itemsPrice = getSubtotal(cart.cartItems);
	cart.taxPrice = getTaxAmount(cart.cartItems, 22);
	cart.totalPrice = cart.itemsPrice;
	const [paymentMethodName, setPaymentMethodName] = useState(cart.paymentMethod);
	const [objPaymentMethod, setObjPaymentMethod] = useState(null);
	const [paymentMethods, setPaymentMethods] = useState([]);
	const [needComprobante, setNeedComprobante] = useState(false);
	const [comprobante, setComprobante] = useState('');
	const [seller, setSeller] = useState('');
	const [couponCode, setCouponCode] = useState('');
	const [couponError, setCouponError] = useState('');
	const [discountPercent, setDiscountPercent] = useState(0);
	const [isCouponLoading, setIsCouponLoading] = useState(false);
	const [couponSuccess, setCouponSuccess] = useState('');




	useEffect(() => {
		if (!cart?.cartItems.length) {
			navigate('/');
		}
		const fetchMethods = async () => {
			try {
				if ((usesShipping && cart.shippingAddress) || !usesShipping) {
					const methods = await _utils.listPaymentMethods();
					setPaymentMethods(methods.data);
				} else {
					navigate('/shipping');
				}
			} catch (ex) {
				console.error(ex);
			}
		};
		fetchMethods();
	}, [discountPercent]);
	
	useEffect(() => {
		if (paymentMethods.length) {
			const pm = paymentMethods.find(
				({ method }) => method === paymentMethodName
			);
			setObjPaymentMethod(pm);
		}
		const getDiscountData = async () =>{
			setCouponCode(cart.discountCoupon);
			await applyCoupon();
		};
		if(cart.discountCoupon){
			getDiscountData();
		}
	}, [paymentMethods]);

	const submitHandler = (e) => {
		e.preventDefault();
		if (objPaymentMethod) {
			ctxDispatch({ type: 'SAVE_PAYMENT_METHOD', payload: objPaymentMethod });
			localStorage.setItem('paymentMethod', JSON.stringify(objPaymentMethod));
			if(seller){
				ctxDispatch({ type: 'SAVE_ORDER_SELLER', payload: seller });
				localStorage.setItem('seller', seller);
			}
			if(discountPercent){
				ctxDispatch({ type: 'SAVE_ORDER_COUPON', payload: couponCode });
				localStorage.setItem('discountCoupon', couponCode);
			}
			if (needComprobante && !comprobante) {
				toast.error('Error. Debe adjuntar el comprobante de pago.');
			} else {
				navigate('/cart/checkout/placeorder');
			}
		} else {
			toast.error('Error. No seleccionó un metodo de pago.');
		}
	};

	const uploadComprobante = async (file) => {
		setNeedComprobante(true);
		setComprobante(file);
		ctxDispatch({ type: 'SAVE_PAYMENT_PROOF', payload: file });
		localStorage.setItem('paymentProof', JSON.stringify(file));
	};

 
	const applyCoupon = async () => {
		try {
			setIsCouponLoading(true); 
			const result = await _discountCodes.useCoupon(couponCode);
			
			if (result?.discountPercent) {
				setDiscountPercent(result.discountPercent);
				setCouponSuccess('Cupón aplicado con éxito.'); 
				setCouponError(''); 
			} else {
				setDiscountPercent(0);
				setCouponSuccess(''); 
				setCouponError('El cupón que ingresó no es válido.');
			}
		} catch (error) {
			console.error('Error applying coupon:', error);
			setDiscountPercent(0);
			setCouponSuccess('');
			setCouponError('El cupón que ingresó no es válido.');
		} finally {
			setIsCouponLoading(false);
		}
	};
	


	return (
		<div className='package-screen'>
			<Helmet>
				<title>Forma de pago</title>
			</Helmet>
			<div id="payment-method-container">

				<div className="row-between">
					<div className="col-12 col-md-6 halfCol">
						<CartSummary  
							discountPercent={discountPercent}
						/>
					</div>
					<div className="col-12 col-md-6 halfCol">
						<Card className="mb-3">
							<Card.Header>Vendedor</Card.Header>
							<Card.Body>
								<input
									type="text"
									placeholder="Ingresa el Vendedor"
									className="seller-input"
									onChange={(e) => setSeller(e.target.value)}
								/>
							</Card.Body>
						</Card>
						<Card className="mb-3">
							<Card.Header>Forma de pago</Card.Header>
							<Card.Body>
								<Form onSubmit={submitHandler}>
									{paymentMethods.length > 0 ? (
										paymentMethods.map((pm, index) =>
											pm.active ? (
												<Form.Check
													className="mb-3"
													key={index}
													type="radio"
													id={pm.method}											
													label={
														(
															(pm.chargePercent > 0) ? (
																<span>{ pm.method } &nbsp;<Badge id="selected-badge">+${getPaymentMethodCharge(cart.cartItems, pm.chargePercent, discountPercent)}</Badge></span>) 
																: 
																pm.method
														)
													}
													value={pm.method}
													checked={paymentMethodName === pm.method}
													onChange={(e) => {
														setPaymentMethodName(e.target.value);
														setObjPaymentMethod(pm);
														setNeedComprobante(pm.uploadAttachment);
													}}
												></Form.Check>
											) : null
										)
									) : (
										<div className="row justify-content-center">
											<LoadingBox></LoadingBox>
										</div>
									)}

									{objPaymentMethod && objPaymentMethod.details && (
										<PaymentMethodData
											details={objPaymentMethod.details}
										></PaymentMethodData>
									)}

									{objPaymentMethod && objPaymentMethod.uploadAttachment && (
										<Card className="mb-3 payment-method-data">
											<Card.Body>
												<Form.Group as={Col} md={12} className="mb-3" controlId="comprobante">
													<Form.Label>Comprobante</Form.Label>
													<Form.Control
														type="file"
														required
														onChange={(e) => uploadComprobante(e.target.files)}
													></Form.Control>
													<Form.Control.Feedback type="invalid">
                          Debes subir un comprobante de pago
													</Form.Control.Feedback>
												</Form.Group>
											</Card.Body>
										</Card>
									)}
								</Form>
							</Card.Body>
						</Card>

						<Card className="mb-3">
							<Card.Header>Tengo un cupón</Card.Header>
							<Card.Body>
								<div className="coupon-container">
									<input
										type="text"
										placeholder="Ingresa el código"
										className="coupon-input"
										value={couponCode}
										onChange={(e) => setCouponCode(e.target.value)}
									/>
									<Button className="coupon-button-pm" onClick={applyCoupon} disabled={isCouponLoading}>
										{isCouponLoading ? (
											<Spinner
												as="span"
												animation="border"
												size="sm"
												role="status"
												aria-hidden="true"
											/>
										) : (
											<span style={{ visibility: isCouponLoading ? 'hidden' : 'visible' }}>
      Aplicar cupón
											</span>
										)}
									</Button>


								</div>


								{couponError && (
									<Form.Text className="text-danger">{couponError}</Form.Text>
								)}
								{couponSuccess && (
									<Form.Text className="text-success">{couponSuccess}</Form.Text>
								)}
							</Card.Body>
						</Card>

					</div>
				</div>
			</div>
			<CheckoutNavigator
				activeStep={usesShipping ? 3 : 2}
				// previousStepHandler={usesShipping ? () => navigate('/checkout/shipping') : null}
				previousStepHandler={null}
				nextStepHandler={paymentMethods.length > 0 ? submitHandler : null}
			/>
		</div>
	);
}

export default PaymentMethodScreen;