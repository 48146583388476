//import { toast } from 'react-toastify';
// import { useTranslation } from "react-i18next";

// const { t } = useTranslation();

export const handleErrors = (err) => {
	const getError = (error) => {
		let e = error;
		if (error.response) {
			e = error.response.data; // data, status, headers
			if (error.response.data && error.response.data.error) {
				e = error.response.data.error; // my app specific keys override
			}
		} else if (error.message) {
			e = error.message;
		} else {
			e = 'GenericError';
		}
		return e;
	};
	throw getError(err);
};
