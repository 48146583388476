import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
	const { pathname } = useLocation();

	useLayoutEffect(() => {
		window.scrollTo({ left: 0, top: 0, behavior: 'instant' });
	}, [pathname]);

	return null;
}
