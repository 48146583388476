import React from 'react';
import userService from '../../../services/user.service';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { Store } from '../../../Store';
import { toast } from 'react-toastify';
import { getError } from '../../../utils';
import { Col } from 'react-bootstrap';
import AuthLandingPage from '../AuthLandingPage/AuthLandingPage';
import LoginPrompt from '../../../components/LoginPrompt/LoginPrompt';
import './SignUpScreen.css';

export default function SignupScreen() {
	const navigate = useNavigate();
	const { search } = useLocation();
	const redirectInUrl = new URLSearchParams(search).get('redirect');
	const redirect = redirectInUrl ? redirectInUrl : '/';
	const { state } = useContext(Store);
	const { userInfo } = state;

	async function submitHandler(email, password, confirmPassword, name) {
		if (password === confirmPassword) {
			try {
				const data = await userService.signUp(name, email, password);
				toast.success(data.message);
				navigate(redirect || '/');
			} catch (ex) {
				toast.error(getError(ex));
				console.error(ex);
			}
		} else {
			toast.error('Las contraseñas no coinciden');
		}
	}

	useEffect(() => {
		if (userInfo) {
			navigate(redirect);
		}
	}, [navigate, redirect, userInfo]);

	return (
		<>
			<Helmet>
				<title>Registro</title>
			</Helmet>
			<AuthLandingPage className='sign-up-screen'>
				<Col xs={10} md={6}>
					<LoginPrompt className="bg-transparent w-100"
						showPassword showRepeatPassword submitHandler={submitHandler}
						showAlreadyHasUser
						buttonCaption="Crear" showNameInput
						caption="Crear usuario" />
				</Col>
			</AuthLandingPage>
		</>
	);
}
